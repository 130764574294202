import React, { Component } from 'react'

export default class VerticalMobileTablet extends Component {
  componentDidMount () {
    // testing
  }

  render () {
    return (
      <div className='inarticle-tablet-container'>
        <div className='inarticle-tablet-inner'>
          <div className='wireframe fake-companion-content' key={Math.random()} style={{ height: '20px', width: '40%', margin: '20px auto' }} />
          <div
            className='wireframe fake-companion-content' key={Math.random()}
            style={{ height: 150, width: '100%', marginTop: 10, marginBottom: 10 }}
          >
            <h1 className='text-center' style={{ color: '#15003B' }}>SCROLL DOWN TO VIEW UNIT</h1>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              {new Array(20).fill('0').map((el, index) => {
                return (
                  <div key={`${Date.now()}__${index}`} className={`native-ad${index}`}>
                    <div key={`${Date.now()}_${index}`} className='wireframe' style={{ minHeight: '20px', width: '100%', marginTop: 9 }} />
                    <p key={`${Date.now()}${index}`} />
                  </div>
                )
              }
              )}
              <div className='native-ad25' id={this.props.id} ref={this.props.container} key={`${Date.now()}__25`} />
              {new Array(20).fill('0').map((el, index) => {
                const adjustedIndex = index + 21
                return (
                  <div key={`${Date.now()}__${adjustedIndex}`} className={`native-ad${adjustedIndex}`}>
                    <div key={`${Date.now()}_${adjustedIndex}`} className='wireframe' style={{ minHeight: '20px', width: '100%', marginTop: 9 }} />
                  </div>
                )
              }
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

import BrightCove from './brightCove'
import AniviewPlayerOutstream from './aniviewPlayerOutstream'
import AniviewPlayer from './aniviewPlayer'

export default class PlayerFactory {
  createPlayer (type, attributes) {
    const player = { AniviewPlayer, BrightCove, AniviewPlayerOutstream }
    const PlayerType = player[type]

    return new PlayerType(attributes)
  }
}

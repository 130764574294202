import React, { Component } from 'react'
import GenericPlayer from './GenericPlayer'

export default class SamsungPlayer extends Component {
  constructor (props) {
    super(props)

    this.header = React.createRef()
    this.endcard = React.createRef()
    this.replayButton = React.createRef()

    this.callbacks = {
      onVideoEnded: this.onVideoEnded.bind(this),
      onVideoLoaded: this.onVideoLoaded.bind(this),
      onVideoPlaying: this.onVideoPlaying.bind(this)
    }
  }

  onVideoEnded () {
    this.header.current.style = 'visibility: hidden;'
    this.endcard.current.style = 'visibility: visible;'
  }

  onVideoLoaded () {
    this.header.current.style = 'visibility: visible;'
    this.endcard.current.style = 'visibility: hidden;'
  }

  onVideoPlaying () {
    this.header.current.style = 'visibility: visible;'
    this.endcard.current.style = 'visibility: hidden;'
  }

  componentDidMount () {
    this.replayButton.current.addEventListener('click', this.onReplayClicked.bind(this))
  }

  onReplayClicked () {
    this.child.restart()
  }

  render () {
    return (
      <div className='samsung-player'>
        <div className='samsung-overlay-header' ref={this.header}>
          <div className='launcherTile'><img src={this.props.launcher} /></div>
          <div className='text-container-header'>
            <div className='advertTitle-header'>{this.props.title}</div>
            <div className='advertSubtitle-header'>{this.props.description}</div>
          </div>
        </div>
        <div className='samsung-overlay-endcard' ref={this.endcard}>
          <div className='background-endcard' />
          <div className='launcherTile-endcard'><img src={this.props.launcher} /></div>
          <div className='text-container-endcard'>
            <div className='advertTitle-endcard'>{this.props.title}</div>
            <div className='advertSubtitle-endcard'>{this.props.description}</div>
            <div className='replayButton' ref={this.replayButton}>Replay</div>
          </div>
        </div>
        <GenericPlayer
          ref={instance => { this.child = instance }}
          video={this.props.video}
          callbacks={this.callbacks}
        />
      </div>
    )
  }
}

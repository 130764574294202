import React, { Component } from 'react'
import merge from 'lodash/merge'
import { updateBodyClass, onResize, consoleLogInfo, checkUrlStatusResult } from '../util/utils'
import { Resources } from '../util/resources'

export default class DisplayAdView extends Component {
  constructor (props) {
    super(props)
    this.iframeLoaded = false
    this.immediateUse = { showIframeError: false }
    this.state = {
      showIframeError: false
    }
  }

  setIframeErrorState (isError) {
    this.immediateUse.showIframeError = isError
    this.setState(() => {
      return { showIframeError: isError }
    })
  }

  setup (nextProps) {
    const props = merge({}, nextProps || this.props)
    const displayAdInfo = props.config
    const displayAdLogTitle = 'Display Ad Log'
    const isValidParams = this.validateParamData(displayAdInfo)
    if (!isValidParams) {
      // this.setIframeErrorState(!isValidParams)
      this.immediateUse.showIframeError = !isValidParams
    } else {
      checkUrlStatusResult(`${displayAdLogTitle} URL Request`, displayAdInfo.url, this.setIframeErrorState.bind(this))
    }
  }

  componentDidMount () {
    // setup before render - load data from a remote endpoint - createScript(...
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    const { props } = this
    const willUpdate = !(props.urlParams === nextProps.urlParams && props.displayAdInfo === nextProps.displayAdInfo && this.immediateUse.showIframeError === this.state.showIframeError)
    return willUpdate
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.immediateUse.showIframeError !== this.state.showIframeError) {
      this.setIframeErrorState(this.immediateUse.showIframeError)
    }
  }

  componentWillUnmount () {
    // Perform any necessary cleanup
    onResize(this.setFooterToBottom, true)
    updateBodyClass.remove('adTypeDisplay')
  }

  validateParamData (displayAdInfo) {
    let errorMessage = ''
    let isValid = true
    const errNotAllParamsPresent = !(!!displayAdInfo.url && !!displayAdInfo.width && !!displayAdInfo.height)
    const errWidthNotNumber = isNaN(parseInt(displayAdInfo.width, 10))
    const errHeightNotNumber = isNaN(parseInt(displayAdInfo.height, 10))
    const errInvalidUrl = !(/^https:\/\//.test(displayAdInfo.url))
    if (errNotAllParamsPresent) {
      isValid = false
      errorMessage = Resources.error.message.displayAd.errNotAllParamsPresent
    } else if (errWidthNotNumber || errHeightNotNumber) {
      isValid = false
      errorMessage = Resources.error.message.displayAd.errWidthHeightNotNumber
    } else if (errInvalidUrl) {
      isValid = false
      errorMessage = Resources.error.message.displayAd.errInvalidUrl
    }

    if (!isValid) {
      consoleLogInfo(errorMessage, 'error')
    }

    return isValid
  }

  setFooterToBottom (adData) {
    let resizeTimout = null

    const updatePos = (adData) => {
      let footerMarginTop = 0
      const browserHeight = window.innerHeight
      const adErrorHeight = 50
      const adHeight = !this.immediateUse.showIframeError ? (parseInt(adData.height, 10) || 0) : adErrorHeight
      const adMarginTop = 131
      const footer = document.querySelector('.footer')
      const footerMinHeight = 140
      let footerHeight = footerMinHeight
      if (footer) footerHeight = footer.offsetHeight < footerMinHeight ? footerMinHeight : footer.offsetHeight
      const contentHeight = adMarginTop + adHeight + footerHeight
      if (contentHeight < browserHeight) {
        footerMarginTop = browserHeight - contentHeight
      }
      if (footer) footer.style.marginTop = footerMarginTop + 'px'
    }

    updatePos(adData)
    return () => {
      clearTimeout(resizeTimout)
      resizeTimout = setTimeout(() => updatePos(adData), 200)
    }
  }

  iframeOnload () {
    this.iframeLoaded = true
    console.log('Display Ad - iframe loaded')
  }

  render () {
    const { props } = this
    const displayAdInfo = props.config
    this.iframeLoaded = false

    consoleLogInfo('Display Ad Log Data - ', 'info', displayAdInfo)
    updateBodyClass.add('adTypeDisplay')
    this.setFooterToBottom(displayAdInfo)()
    onResize(this.setFooterToBottom(displayAdInfo))

    this.setup()

    return (
      <div className='displayAdMain'>
        <div className='displayAdContainer'>
          {this.immediateUse.showIframeError
            ? (
              <div className='displayAdError'>
                {Resources.error.message.displayAd.genericErrorMessage}
              </div>
              )
            : <iframe id='iframeDisplayAd' onLoad={this.iframeOnload.bind(this)} className='displayIframe' src={displayAdInfo.url} width={displayAdInfo.width} height={displayAdInfo.height} title='Display Ad' scrolling='no' hspace='0' vspace='0' marginWidth='0' marginHeight='0' />}
        </div>
      </div>
    )
  }
}

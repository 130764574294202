import React, { PureComponent } from 'react'
import pkg from '../../package.json'
import { Resources } from '../util/resources'
import { References } from '../util/references'

export default class Footer extends PureComponent {
  render () {
    const { props } = this
    const branding = props.branding
    const resource = Resources.branding[branding].footer

    const buildFooterItem = (key, index) => {
      const replaceTemplates = (itemText) => {
        const currentYear = new Date().getUTCFullYear()
        return itemText.replace(References.template.year, currentYear)
      }

      let beforeLink = ''
      let link = ''
      let afterLink = ''
      let itemText = resource.item[key].text
      const itemLink = resource.item[key].link
      const itemLinkText = resource.item[key].linkText
      const linkTemplate = References.template.link
      itemText = replaceTemplates(itemText)

      const textLink = (linkText) => { return <span className={itemLink && 'footer-link'} onClick={itemLink && (() => window.open(itemLink, '_blank'))}>{linkText}</span> }
      const linkWithinText = () => {
        const indexOfTemplate = itemText.indexOf(linkTemplate)
        const charsInTemplate = linkTemplate.length
        beforeLink = itemText.substr(0, indexOfTemplate)
        link = !itemLinkText ? '' : textLink(itemLinkText)
        afterLink = itemText.substr(indexOfTemplate + charsInTemplate, itemText.length)
      }

      if (itemText.includes(linkTemplate)) {
        linkWithinText()
      } else {
        link = textLink(itemText)
      }
      return <span key={key}><span>{index !== 0 && ' | '}</span>{beforeLink}{link}{afterLink}</span>
    }

    return (
      <footer className='footer'>
        <div className='container-fluid'>
          <div className='text-left footer-text'>
            {props.match.params.workspace !== 'client' &&
              <div>{resource.name} {pkg.shortName} v{pkg.version}</div>}
            <div>
              {Object.keys(resource.item).map((key, index) => {
                return buildFooterItem(key, index)
              })}
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

import React, { Component } from 'react'
import { mediaFileExt } from '../util/tag-util'
import {
  receivePostMessage,
  resetPlayerInfo
} from '../util/player-events'
import { createScript, onResize } from '../util/utils'
import InArticleDesktop from './outstreamPages/InArticleDesktop'
import InArticleMobilePhone from './outstreamPages/InArticleMobilePhone'
import InArticleMobileTablet from './outstreamPages/InArticleMobileTablet'
import UnMissableDesktop from './outstreamPages/UnMissableDesktop'
import UnMissablePhone from './outstreamPages/UnMissablePhone'
import UnMissableMobileTablet from './outstreamPages/UnMissableMobileTablet'
import VerticalMobilePhone from './outstreamPages/VerticalMobilePhone'
import VerticalMobileTablet from './outstreamPages/VerticalMobileTablet'
import VidaaU6hero from './outstreamPages/VidaaU6hero'

export default class AniviewPlayerOutstream extends Component {
  constructor (props) {
    super(props)
    if (sessionStorage.getItem('rmlMode') !== 'debug') {
      sessionStorage.setItem('rmlMode', 'debug')
    }
    this.firstTimeUseAniview = true
    this.state = { restartPlayer: false, playerStopped: false }
    this.currentPlayer = null
    this.container = React.createRef()
    this.adConfig = this.getConfig(props)
    this.receivePostMessage = receivePostMessage.bind(this)
    this.setVideoSize = this.setVideoSize.bind(this)
  }

  componentDidMount () {
    this.setState({ restartPlayer: true })
  }

  componentWillUnmount () {
    this.destroyPlayer()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.state.restartPlayer) {
      this.setState({ restartPlayer: false })
      this.startPlayer()
    } else {
      this.setState({ restartPlayer: true })
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    const { props } = this

    const isAdTagUriChanged = props.adTagUri !== nextProps.adTagUri
    const isDeviceIdChanged = props.deviceId !== nextProps.deviceId
    const isResetPlayer = nextState.restartPlayer === true

    const shouldUpdate = isDeviceIdChanged || isAdTagUriChanged || isResetPlayer

    return shouldUpdate
  }

  getOutStreamPage (deviceId) {
    const position = this.adConfig.position
    this.pagesByName = {
      inarticledesktop: { comp: <InArticleDesktop container={this.container} id={position} />, siteId: 'inarticle-vast-inspector' },
      inarticlemobilephone: { comp: <InArticleMobilePhone container={this.container} id={position} />, siteId: 'inarticle-vast-inspector' },
      inarticlemobiletablet: { comp: <InArticleMobileTablet container={this.container} id={position} />, siteId: 'inarticle-vast-inspector' },
      unmissabledesktop: { comp: <UnMissableDesktop container={this.container} id={position} />, siteId: 'unmissable-vast-inspector' },
      unmissablephone: { comp: <UnMissablePhone container={this.container} id={position} />, siteId: 'unmissable-vast-inspector' },
      unmissablemobiletablet: { comp: <UnMissableMobileTablet container={this.container} id={position} />, siteId: 'unmissable-vast-inspector' },
      verticalmobilephone: { comp: <VerticalMobilePhone container={this.container} id={position} />, siteId: 'vertical-vast-inspector' },
      verticalmobiletablet: { comp: <VerticalMobileTablet container={this.container} id={position} />, siteId: 'vertical-vast-inspector' },
      u6hero: { comp: <VidaaU6hero container={this.container} id={position} />, siteId: 'unmissable-vast-inspector' }
    }
    if (!Object.prototype.hasOwnProperty.call(this.pagesByName, deviceId)) {
      return this.pagesByName.inarticledesktop // check if this should be default (maybe load insteam desktop if error here??)
    }
    return this.pagesByName[deviceId]
  }

  getConfig (props) {
    return {
      position: props.playerId
    }
  }

  startPlayer () {
    this.destroyPlayer()
    window.addEventListener('message', this.receivePostMessage, false)
    this.loadUnrulyScripts()
  }

  loadUnrulyScripts () {
    const position = this.adConfig.position
    window.unruly = {}
    window.unruly.native = {}
    window.unruly.native.supplyMode = 'prebid'
    window.unruly.native.prebid = {}
    window.unruly.native.prebid.uq = [[{}, { adUnitCode: position, vastUrl: this.getVastUrl() }]]

    const scriptSrc = 'https://player.aniview.com/script/custom/unruly/outstream/skin.js?AV_CDIM10=1111192'
    const scriptId = 'unruly_skin_script'
    const dataProperty = { property: 'data-player-api', value: 'myPlayerApi' }
    const scriptElement = createScript(document.body, scriptSrc, scriptId, dataProperty)
    window.myPlayerApi = (config, player) => {
      console.log('Success loading player API:')
      this.currentPlayer = player
      config.customcss = '#timeline { pointer-events: none;} #av-container #buttons {pointer-events: none;} #av-container #buttons #right #sound {pointer-events: auto;}'
      if (this.props.deviceId === 'desktop' || this.props.deviceId === 'inarticledesktop' || this.props.deviceId === 'u6hero') {
        if (this.props.deviceId === 'u6hero') {
          config.aniBoxCSS = 'overflow:hidden; -webkit-transition:none; transition:none;opacity:0;'
        }
        if (this.firstTimeUseAniview && this.currentPlayer) {
          this.setVideoSize()
          this.firstTimeUseAniview = false
        }
      }
      player.on('AdLoaded', () => {
        console.log('AdLoaded - Set Player Resize Event Listener')
        this.setResizeEvent(this.props)
      })
      player.on('AdStopped', () => {
        console.log('AdStopped - Destroy Player')
        this.destroyPlayer()
        this.props.notifyParentState({ childCompPlayerStopped: true, showVideo: false })
      })
    }

    scriptElement
      .then(() => {
        console.log('Success loading player scripts:')
      })
      .catch((err) => {
        console.log('ERROR loading player scripts:', err)
      })
  }

  setResizeEvent (props) {
    if (props.deviceId === 'desktop' || props.deviceId === 'inarticledesktop' || props.deviceId === 'u6hero') {
      onResize(this.setVideoSize)
      if (this.firstTimeUseAniview && this.currentPlayer) {
        this.setVideoSize()
        this.firstTimeUseAniview = false
      }
    }
  }

  setVideoSize () {
    const extraWidth = this.props.deviceId === 'u6hero' ? 3 : 0
    const videoWidth = this.container.current.clientWidth + extraWidth

    if (videoWidth > 36) {
      if (!this.deviceHeightRatio) this.deviceHeightRatio = this.calcHeightRatio(this.props.playerOptions.aspectRatio, true)
      const height = this.deviceHeightRatio * videoWidth
      this.currentPlayer.resize(videoWidth, height)
      this.workAroundAniviewBug(videoWidth, height)
    }

    return true
  }

  workAroundAniviewBug (videoWidth, height) {
    // Resize element "aniBox" that is not being changed by .resize(videoWidth, height)
    // Aniview is wokring on it (known bug) - REMOVE FUNC when fixed by them
    // (Check if In-Stream needs this as well)
    const aniBox = document.querySelector('#aniBox')
    const unexplainedHeightShortage = 20
    if (aniBox) {
      aniBox.style.width = `${videoWidth}px`
      aniBox.style.height = `${height + unexplainedHeightShortage}px`
      // setTimeout(() => { this.makeElementAndDescendants100PercentWidth(aniBox) }, 1000)
    }
  }

  // makeElementAndDescendants100PercentWidth (element) {
  //   element.style.width = '100%'
  //   const descendantDivs = element.querySelectorAll('div') // Select all descendants
  //   descendantDivs.forEach(div => {
  //     div.style.width = '100%'
  //   })
  // }

  calcHeightRatio (aspectRatio, heightFirst = false) {
    const widthAndHeight = aspectRatio.split(':')
    if (heightFirst) widthAndHeight.reverse()
    return parseInt(widthAndHeight[0], 10) / parseInt(widthAndHeight[1], 10)
  }

  destroyPlayer () {
    if (this.currentPlayer) {
      this.currentPlayer.close()
      this.currentPlayer = null
    }
    onResize(this.setVideoSize, true)
    window.removeEventListener('message', this.receivePostMessage)
    resetPlayerInfo()
    this.props.removeLogs()
  }

  getVastUrl () {
    const vastProperties = this.props.vastProperties
    const mediaFileType = mediaFileExt(this.props.adTagUri)
    let url = ''
    if (mediaFileType) {
      url = this.props.config.api.getVastTemplate.call(this.props.config, mediaFileType, this.props.adTagUri, this.props.url)
    } else {
      url = this.props.adTagUri
    }
    const encodedUrl = encodeURIComponent(url)
    const vastWrapperUrl = this.props.config.api.vastWrapper.call(this.props.config, encodedUrl, vastProperties)
    return vastWrapperUrl
  }

  render () {
    return (
      <div className='outstream-container'>
        {this.state.restartPlayer && this.getOutStreamPage(this.props.deviceId).comp}
      </div>
    )
  }
}

import React, { Component } from 'react'

export default class AdvancedPanelDropdownOptions extends Component {
  render () {
    const { props } = this
    return (
      <>
        {props.options.map((option) => {
          return <option key={option.id} value={option.id}>{option.name}</option>
        })}
      </>
    )
  }
}

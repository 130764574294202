export const References = {
  branding: {
    Unruly: 'Unruly',
    Tremor: 'Tremor',
    Nexxen: 'Nexxen',
    Local: 'Local',
    brands: ['Unruly', 'Tremor', 'Nexxen', 'Local']
  },
  env: {
    'preview.unruly.co': 'prod',
    'preview.tremorvideodsp.com': 'prod',
    'preview.nexxen.com': 'prod',
    localhost: 'prod',
    'html5.unruly.co': 'release',
    'html5.tremorvideodsp.com': 'release',
    'html5-test.unruly.co': 'test',
    'html5-test.tremorvideodsp.com': 'test',
    'preview-test.tremorvideodsp.com': 'test'
  },
  host: {
    env: {
      prod: 'prod',
      release: 'release',
      test: 'test',
      dev: 'dev'
    },
    devUrl: ['localhost', '127.0.0.1'],
    nodeDevUrl: window.location.hostname,
    protocol: `${window.location.protocol}//`,
    branding: {
      Unruly: {
        prodUrl: 'preview.unruly.co',
        releaseUrl: 'html5.unruly.co',
        testUrl: 'html5-test.unruly.co',
        nodeProdUrl: 'preview.tremorvideodsp.com',
        nodeTestUrl: 'preview-test.tremorvideodsp.com'
      },
      Tremor: {
        prodUrl: 'preview.tremorvideodsp.com',
        releaseUrl: 'html5.tremorvideodsp.com',
        testUrl: 'html5-test.tremorvideodsp.com',
        nodeProdUrl: 'preview.tremorvideodsp.com',
        nodeTestUrl: 'preview-test.tremorvideodsp.com'
      },
      Nexxen: {
        prodUrl: 'preview.nexxen.com',
        releaseUrl: 'html5.nexxen.com',
        testUrl: 'html5-test.nexxen.com',
        nodeProdUrl: 'preview.nexxen.com',
        nodeTestUrl: 'preview-test.nexxen.com'
      },
      Local: {
        prodUrl: 'localhost',
        releaseUrl: 'localhost',
        testUrl: 'localhost',
        nodeProdUrl: 'localhost',
        nodeTestUrl: 'localhost'
      }
    }
  },
  url: {
    adserverPreview: 'adserver.videohub.tv/preview',
    tremorvideodsp: 'tremorvideodsp',
    tremorvideodspFull: 'https://s.tremorvideodsp.com'
  },
  endpoint: {
    branding: {
      Unruly: {
        addVastAdParameters: 'https://raptor.unruly.co/ws/customizer',
        rmlReplacer: 'https://raptor.unruly.co/ws/rml/replacer'
      },
      Tremor: {
        addVastAdParameters: 'https://raptor.tremorvideodsp.com/ws/customizer',
        rmlReplacer: 'https://raptor.tremorvideodsp.com/ws/rml/replacer'
      },
      Nexxen: {
        addVastAdParameters: 'https://raptor.tremorvideodsp.com/ws/customizer',
        rmlReplacer: 'https://raptor.tremorvideodsp.com/ws/rml/replacer'
      },
      Local: {
        addVastAdParameters: 'https://raptor.tremorvideodsp.com/ws/customizer',
        rmlReplacer: 'https://raptor.tremorvideodsp.com/ws/rml/replacer'
      }
    }
  },
  player: {
    aniview: {
      inStream: {
        id: 'aniviewplayer',
        name: 'AniviewPlayer'
      },
      outStream: {
        id: 'aniviewplayeroutstream',
        name: 'AniviewPlayerOutstream'
      }
    }
  },
  paramName: {
    mediaType: 'mediaT',
    displayAd: {
      url: 'adUrl',
      width: 'adWidth',
      height: 'adHeight'
    },
    vidaaAd: {
      clickAction: {
        openApp: 'openApp',
        deepLink: 'deepLink',
        displayVideo: 'displayVideo'
      },
      videoUrl: 'videoUrl'
    },
    tclAd: {
      clickAction: {
        openApp: 'openApp',
        deepLink: 'deepLink',
        store: 'store',
        displayVideo: 'displayVideo'
      },
      videoUrl: 'videoUrl'
    },
    samsungAd: {
      clickAction: {
        deepLink: 'deepLink',
        displayVideo: 'displayVideo'
      },
      videoUrl: 'videoUrl'
    }
  },
  template: {
    link: '__Link__',
    year: '__Year__'
  },
  map: {
    screenType: {
      outStream: {
        inarticledesktop: 'desktop',
        unmissabledesktop: 'desktop',
        inarticlemobilephone: 'mobilephone',
        unmissablephone: 'mobilephone',
        verticalmobilephone: 'mobilephone',
        inarticlemobiletablet: 'mobiletablet',
        unmissablemobiletablet: 'mobiletablet',
        verticalmobiletablet: 'mobiletablet'
      }
    }
  }
}

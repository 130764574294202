import React, { PureComponent } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import { mediaFileExt } from '../util/tag-util'

export default class DebugPanel extends PureComponent {
  triggerResizeEvent () {
    window.dispatchEvent(new CustomEvent('resize'))
  }

  render () {
    const { logs, workspace, adTagUri, isClientLink } = this.props
    const isShow = workspace === 'debug' && !mediaFileExt(adTagUri) && !isClientLink
    console.log('## DEBUG PANEL - isShow', isShow)
    return (
      <Collapse
        timeout={200} // 0.2s
        in={isShow}
        dimension={() => window.innerWidth > 768 ? 'width' : 'height'}
        onEntered={this.triggerResizeEvent} // eslint-disable-line react/jsx-handler-names
        onExited={this.triggerResizeEvent} // eslint-disable-line react/jsx-handler-names
        unmountOnExit
      >
        <div id='panel-debug-wrapper'>
          <div id='panel-debug'>
            <section id='log-events'>
              <ul className='list-group list-group-flush'>
                {[...logs].reverse().map((log) => {
                  return (
                    <li key={`ev-${log.data.timestamp}`}>
                      <div className='d-flex flex-column w-100 justify-content-between eventTxt'>
                        <div>{log.evName}</div>
                        {log.data && log.data.message && <div>{log.data.message}</div>}
                      </div>
                      <div className='mb-0 h-100 d-flex flex-column justify-content-center'>
                        <div className={`badge badge-pill badge-primary badge-${log.type}`}>{log.type}</div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            </section>
          </div>
        </div>
      </Collapse>
    )
  }
}

import React, { Component } from 'react'

export default class VidaaU6hero extends Component {
  componentDidMount () {
    // testing
  }

  render () {
    return (
      <div className='vidda-insert-position' id={this.props.id} ref={this.props.container} key={`${Date.now()}__30`} />
    )
  }
}

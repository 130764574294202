import vpaidEvents from '../ad/vpaidEvents'

export const playerInfo = {
  eventsArray: [],
  adDuration: '',
  addLog: null,
  vidEl: null,
  reference: {
    PLAYER_CONTAINER_ID: 'AVplayer0'
  }
}

export function resetPlayerInfo () {
  playerInfo.eventsArray.splice(0)
  playerInfo.adDuration = ''
  playerInfo.addLog = null
  playerInfo.vidEl = null
}

function getVideoElement () {
  const adContainer = document.getElementById(playerInfo.reference.PLAYER_CONTAINER_ID)

  if (adContainer) {
    let vidEl = adContainer.querySelector('video')

    if (!vidEl) {
      const iframe = adContainer.querySelector('iframe')

      if (iframe) {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document
        vidEl = iframeDocument.querySelector('video')
      }
    }

    return vidEl
  }

  return null
}

function setVideoElement (propsAddLog) {
  playerInfo.addLog = propsAddLog
  playerInfo.vidEl = getVideoElement()
  setDurationEvent()
}

function setDurationEvent () {
  if (playerInfo.vidEl && !isNaN(playerInfo.vidEl.duration)) logVideoDuration()
  else playerInfo.vidEl && playerInfo.vidEl.addEventListener('loadedmetadata', logVideoDuration, { once: true })
}

function logVideoDuration () {
  const message = `Video Duration: ${playerInfo.vidEl.duration.toFixed(1)} s`
  playerInfo.addLog('misc', 'VideoDuration', { message })
}

function handleAdDuration (duration) {
  if (!playerInfo.adDuration && duration) {
    playerInfo.adDuration = duration
    const message = `Ad Duration: ${duration} s`
    playerInfo.addLog('misc', 'AdDuration', { message })
  }
}

export function receivePostMessage (ev) {
  if (ev && ev.data && !ev.data.source) {
    if (!playerInfo.vidEl) setVideoElement(this.props.addLog)
    let message
    if (ev.data.event === 'rmlLoaded') {
      console.log('rmlLoaded')
    } else if (ev.data.info === 'trackingPixel') {
      if (playerInfo.vidEl && !isNaN(playerInfo.vidEl.currentTime)) message = `Fired at ${playerInfo.vidEl.currentTime.toFixed(1)} s`
      playerInfo.addLog('adv', ev.data.type, { message })
    } else if (ev.data.info === 'vpaidEvents' || ev.data.info === 'adEvent') {
      if (Object.values(vpaidEvents).includes(ev.data.type)) {
        if (playerInfo.vidEl && !isNaN(playerInfo.vidEl.currentTime)) message = `Fired at ${playerInfo.vidEl.currentTime.toFixed(1)} s`
        playerInfo.addLog('vpaid', ev.data.type, { message })
        return
      }
      if (!playerInfo.eventsArray.includes(ev.data.type)) {
        playerInfo.eventsArray.push(ev.data.type)
        if (playerInfo.vidEl && !isNaN(playerInfo.vidEl.currentTime)) {
          message = `Fired at ${playerInfo.vidEl.currentTime.toFixed(1)} s`
          playerInfo.addLog('vpaid', ev.data.type, { message })
        }
      } else {
        if (ev.data.type === 'AdVolumeChange') {
          this.currentPlayer.unmute() // Must be called as a result of user click action in the page
        }
      }
    } else if (ev.data.info === 'adDuration') {
      handleAdDuration(ev.data.type)
    }
  }
}

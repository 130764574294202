/**
 * Pluck key and values for the object
 * @param  {[type]} obj    [description]
 * @param  {Array}  filter [description]
 * @return {[type]}        [description]
 */
export default function filterObject (obj = {}, filter = []) {
  const output = {}
  Object.keys(obj).forEach(k => {
    if (filter.includes(k)) {
      output[k] = obj[k]
    }
  })
  return output
}

import React, { Component } from 'react'
import merge from 'lodash/merge'
import classnames from 'classnames'
import { siuBanerSizes, validScreenTypeRegex } from '../../DeviceOptions'
import Dropdown from './../Dropdown'
import { ReactComponent as SlidersSVG } from '../../images/sliders-h-solid.svg'

export default class SiuNav extends Component {
  handleGo () {
    const { props, refs } = this
    const params = merge({}, props.match.params)
    params.bannerSize = refs.bannerSize.refs.dropdown.value
    // const pathname = compilePath(props.match.path, Object.assign(params, normalizedDevices))
    let pathname = `/tv/${params.siuBrand}/${params.bannerSize}`
    pathname = params.workspace ? `${pathname}/${params.workspace}` : pathname
    console.log(props.match.path)
    props.history.push({
      pathname,
      search: props.location.search
    })
  }

  render () {
    const { props } = this
    let advertiserName
    if (props.location.query) {
      advertiserName = props.location.query.advertiserName
    }
    let filterScreenTypes = []
    if (props.location.query && (props.location.query.ft || props.location.query.filterScreenTypes)) {
      filterScreenTypes = (props.location.query.ft || props.location.query.filterScreenTypes)
        .split(/\s*,\s*/g)
        .filter(type => validScreenTypeRegex.test(type))
    }

    return (
      <div className='tmr-nav-background-fill'>
        <div className='container-fluid tmr-nav tmr-nav-lg'>
          <nav className='navbar navbar-toggleable-md navbar-expand-md navbar-dark'>
            <div>
              <a className={classnames('tmr-brand text-hide')} href={`${process.env.PUBLIC_URL}/index.html`}>
                <img src='../../images/tremor.png' alt='Tremor International' />
              </a>
            </div>
            <div className='collapse navbar-collapse tmr-navbar-collapse' id='navbar-main-collapse'>
              <div className='brand-info-wrapper'>
                {advertiserName &&
                  <div className='brand-info'>
                    Advertiser: {advertiserName}
                  </div>}
              </div>
              <ul className='navbar-nav'>
                {props.match.params.workspace !== 'client' &&
                  <li className='nav-item tmr-nav-item'>
                    <button
                      onClick={props.toggleAdvancedPanel} // eslint-disable-line react/jsx-handler-names
                      className={classnames('btn btn-info tmr-nav-btn roundedBorder', { selected: props.showingAdvancedPanel })}
                      type='button'
                    >
                      <SlidersSVG className='svg-icon' width='25px' height='25px' />
                      <div className='tmr-nav-btn-text'>
                        Settings
                      </div>
                    </button>
                  </li>}
                <li className='nav-item'>
                  {(props.showSizeSelection && filterScreenTypes.length !== 1) &&
                    <div className='form-group'>
                      <Dropdown
                        label='Screen Type'
                        value={props.match.params.bannerSize}
                        onChange={this.handleGo.bind(this)}
                        ref='bannerSize' // eslint-disable-line react/no-string-refs
                        height='40px'
                      >
                        {
                        siuBanerSizes[props.match.params.siuBrand].map(item => <option key={item.id} value={item.id}>{item.name}</option>)
                      }
                      </Dropdown>
                    </div>}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

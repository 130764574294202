
export const Resources = {
  branding: {
    Unruly: {
      footer: {
        name: 'Unruly',
        item: {
          privacy: { text: 'Privacy Policy', link: 'https://unruly.co/legal/privacy/' },
          legal: { text: 'Legal Hub', link: 'https://unruly.co/legal/' },
          covid: { text: 'COVID-19: Update', link: 'https://unruly.co/news/article/2020/03/17/an-update-on-unrulys-covid-19-policy/' },
          personalInfo: { text: 'Do Not Sell My Personal Information', link: 'https://www.rhythmone.com/opt-out/' },
          copyright: { text: '© Copyright __Year__ Unruly Group Ltd' }
        }
      }
    },
    Tremor: {
      footer: {
        name: 'Nexxen',
        item: {
          privacy: { text: 'Privacy Policy', link: 'https://www.nexxen.com/privacy-policy' },
          ccpa: { text: 'CCPA', link: 'https://www.nexxen.com/privacy-policy/#privacy-choices' },
          cookiePolicy: { text: 'Cookie Policy', link: 'https://www.nexxen.com/privacy-policy/#cookies' },
          copyright: {
            text: 'Copyright © __Year__ __Link__. All rights reserved.',
            link: 'https://www.nexxen.com/',
            linkText: 'Nexxen International Ltd'
          }
        }
      }
    },
    Nexxen: {
      footer: {
        name: 'Nexxen',
        item: {
          privacy: { text: 'Privacy Policy', link: 'https://www.nexxen.com/privacy-policy' },
          ccpa: { text: 'CCPA', link: 'https://www.nexxen.com/privacy-policy/#privacy-choices' },
          cookiePolicy: { text: 'Cookie Policy', link: 'https://www.nexxen.com/privacy-policy/#cookies' },
          copyright: {
            text: 'Copyright © __Year__ __Link__. All rights reserved.',
            link: 'https://www.nexxen.com/',
            linkText: 'Nexxen International Ltd'
          }
        }
      }
    },
    Local: {
      footer: {
        name: 'Nexxen',
        item: {
          privacy: { text: 'Privacy Policy', link: 'https://www.nexxen.com/privacy-policy' },
          ccpa: { text: 'CCPA', link: 'https://www.nexxen.com/privacy-policy/#privacy-choices' },
          cookiePolicy: { text: 'Cookie Policy', link: 'https://www.nexxen.com/privacy-policy/#cookies' },
          copyright: {
            text: 'Copyright © __Year__ __Link__. All rights reserved.',
            link: 'https://www.nexxen.com/',
            linkText: 'Nexxen International Ltd'
          }
        }
      }
    }
  },
  components: {
    clientLink: {
      header: 'Client Link Generator',
      headerInfo: 'Please select the screens you wish to share for approval. Once done, copy link to share with client.'
    }
  },
  error: {
    message: {
      displayAd: {
        genericErrorMessage: 'There was an issue loading the ad. Please check the ad URL and try again.',
        errNotAllParamsPresent: 'You are attempting to view a display ad without a valid ad URL and Width/Height. Please obtain a new link with correct parameters.',
        errWidthHeightNotNumber: 'You are attempting to view a display ad without a valid ad Width and Height specified. Please obtain a new link with correct parameters.',
        errInvalidUrl: 'You are attempting to view a display ad without a secure ad URL. Please obtain a new link with a secure (https) url.'
      },
      vidaaAd: {
        genericErrorMessage: 'There was an issue loading the ad banner. Please check the Ad Tag Uri and VIDAA Manifest it points to and try again.',
        incorrectNumBanners: 'There must be 3 or 6 banners sent in the manifest',
        invalidDisplayOrders: 'The display orders set in the VIDAA manifest are invalid. They must start with 1, be consecutive and unique.',
        invalidClickAction: 'The click action for this ad banner in the VIDAA manifest is not a valid value.'
      },
      tclAd: {
        genericErrorMessage: 'There was an issue loading the ad banner. Please check the Ad Tag Uri and TCL Manifest it points to and try again.',
        incorrectBanner: 'Either banner image or click action is not provided',
        invalidClickAction: 'The click action for this ad banner in the TCL manifest is not a valid value.'
      },
      samsungAd: {
        genericErrorMessage: 'There was an issue loading the ad banner. Please check the Ad Tag Uri and Samsung Manifest it points to and try again.',
        incorrectBanner: 'Either banner image or click action is not provided',
        invalidClickAction: 'The click action for this ad banner in the Samsung manifest is not a valid value.'
      }
    }
  }
}

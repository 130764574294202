import { VastAdManager } from '@unruly/vast-parser'
const vastAdManager = new VastAdManager()

export function onResize (callback, isRemove) {
  window.removeEventListener('resize', callback)
  if (!isRemove) window.addEventListener('resize', callback)
}

export const updateBodyClass = {
  clear () {
    document.body.className = ''
  },
  add (className) {
    document.body.classList.add(className)
  },
  remove (className) {
    document.body.classList.remove(className)
  }
}

export function consoleLogInfo (message, type, obj) {
  let color = 'Black'
  switch (type) {
    case 'success':
      color = 'Green'
      break
    case 'info':
      color = 'Blue'
      break
    case 'error':
      color = 'Red'
      break
    case 'warning':
      color = 'Orange'
      break
    default:
      color = 'Black'
  }
  console.log(`%c${message}`, `color:${color};font-weight:bold;`, (obj || ''))
}

export async function checkUrlStatusResult (infoText, url, alwaysDoCallback) {
  const myRequest = new Request(url)
  let message = `${infoText} - `
  let messageType = 'info'
  let isError = false
  let responseBody = null

  try {
    const response = await fetch(myRequest)
    const statusCode = response.status
    const contentType = response.headers.get('Content-Type')

    if (statusCode >= 200 && statusCode < 300) {
      message += `Successful Response ${statusCode}.`
      messageType = 'success'
      if (contentType && contentType.includes('application/json')) {
        responseBody = await response.json()
      }
    } else if (statusCode >= 300 && statusCode < 400) {
      message += `Successful (Redirect) Response ${statusCode}.`
      messageType = 'warning'
    } else if (statusCode >= 400 && statusCode < 500) {
      message += `Error Response ${statusCode}, Client Request Error.`
      messageType = 'error'
      isError = true
    } else if (statusCode >= 500) {
      message += `Error Response ${statusCode}, Server Error.`
      messageType = 'error'
      isError = true
    }
  } catch (error) {
    message += `The browser was unable to process the URL request, error - ${error}.`
    messageType = 'error'
    isError = true
  } finally {
    consoleLogInfo(message, messageType)
    if (alwaysDoCallback && typeof alwaysDoCallback === 'function') {
      alwaysDoCallback(isError, responseBody, message)
    }
  }
}

export function isObjItemExistsInArray (arrItems, objItem, key) {
  return arrItems.indexOf(objItem[key]) !== -1
}

export function mapArrObjItemsByKey (key, arrItems) {
  return arrItems.map(objItem => objItem[key])
}

export function flagArrItems (key, flag, arrItemsToFlag, arrAllItems) {
  return arrAllItems.map((objItem) => {
    objItem[flag] = !isObjItemExistsInArray(arrItemsToFlag, objItem, key)
    return objItem
  })
}

export function isNotEmpty (data) {
  return data && data.length
}

export function isEmpty (data) {
  return !(data && data.length)
}

export function flagArrayItemsByKey (key, flag, arrFilteredObjItems, arrAllObjItems) {
  if (!isEmpty(arrAllObjItems)) {
    if (isEmpty(arrFilteredObjItems)) arrFilteredObjItems = []
    const mappedArrItems = mapArrObjItemsByKey(key, arrFilteredObjItems)
    const flaggedArrItems = flagArrItems(key, flag, mappedArrItems, arrAllObjItems)
    return flaggedArrItems
  }
  return arrAllObjItems
}

export function VastDataGetter () {
  return {
    getCreatives (vastResponse) {
      return vastResponse.inline.VAST.Ad.InLine.Creatives.Creative || []
    },
    getLinearCreative (arrCreatives) {
      let creative = null
      for (let i = 0; i < arrCreatives.length; i++) {
        if (arrCreatives[i] && arrCreatives[i].Linear) {
          creative = arrCreatives[i]
          break
        }
      }
      return creative
    },
    getMediaFiles (creative) {
      return creative.Linear.MediaFiles.MediaFile || []
    },
    getMediaFile (vastResponse) {
      const arrCreatives = this.getCreatives(vastResponse)
      const linearCreative = arrCreatives.length ? this.getLinearCreative(arrCreatives) : null
      const arrMediaFiles = linearCreative ? this.getMediaFiles(linearCreative) : []
      const firstMediaFile = (arrMediaFiles.length && arrMediaFiles[0].nodeValue) ? arrMediaFiles[0].nodeValue : null
      return firstMediaFile
    }
  }
}

export function parseVast (url, onSuccess, onError) {
  vastAdManager.requestVastChain({ url: url })
    .then(
      vastResponse => {
        console.log('parseVast success, vastResponse - ', vastResponse)
        if (onSuccess) onSuccess(vastResponse)
      },
      vastError => {
        console.log('parseVast failure, vastError - ', vastError)
        if (onError) onError()
      }
    )
    .catch(err => {
      console.log('parseVast catch error - ', err)
      if (onError) onError()
    })
}

export function createScript (parentEl, uri, id, dataProperty) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = uri
    script.type = 'text/javascript'
    id && (script.id = id)
    if (dataProperty && dataProperty.property && dataProperty.value) {
      script.setAttribute(dataProperty.property, dataProperty.value)
    }
    script.onload = () => {
      resolve(script)
    }
    script.onerror = (err) => {
      reject(new Error(`Script "${uri}" is not accessible: ${err.message}`))
    }

    parentEl.appendChild(script)
  })
}

export function createImage (src, id) {
  return new Promise((resolve, reject) => {
    const img = document.createElement('img')
    img.id = id
    img.onload = () => {
      resolve(img)
    }
    img.onerror = (err) => {
      console.log(err)
      const message = `Image "${src}" is not accessible`
      const messageType = 'error'
      reject(new Error(message))
      consoleLogInfo(message, messageType)
    }
    img.src = src

    return img
    // parentEl.appendChild(script)
  })
}

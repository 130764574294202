import { streamsTypes } from '../../DeviceOptions.js'

export default class BrightCove {
  constructor ({ mediaType, streamType }) {
    this.id = 'brightcove'
    this.name = 'Brightcove'
    this.mediaType = mediaType || 'mp4'
    this.streamType = streamType || streamsTypes.Outstream
  }
}

import React, { Component } from 'react'
import createScript from 'promise-create-script'
import { mediaFileExt } from '../util/tag-util'
import { onResize } from '../util/utils'
import {
  receivePostMessage,
  resetPlayerInfo
} from '../util/player-events'

const waterfall = {
  data: {
    tags: [
      {
        asid: 'demo',
        type: 'vast',
        url: ''
      }
    ]
  }
}

export default class AniviewPlayer extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor (props) {
    super(props)
    if (sessionStorage.getItem('rmlMode') !== 'debug') {
      sessionStorage.setItem('rmlMode', 'debug')
    }
    this.state = {
      hasVast: false,
      isAniviewLoaded: false,
      isControlButtonsEnabled: true
    }
    this.firstTimeUseAniview = true
    this.timesGotDestroyed = 0
    this.oldSize = null
    this.currentPlayer = null
    this.container = React.createRef()
    this.config = this.getConfig(props)
    this.restartPlayer = false
    this.receivePostMessage = receivePostMessage.bind(this)
    this.setVideoSize = this.setVideoSize.bind(this)
  }

  getConfig (props) {
    return {
      adConfig: {
        position: props.playerId,
        baseJsUrl: 'https://player.aniview.com/script/6.1/',
        waterfall: this.waterfallFunc,
        publisherId: '55b78633181f4603178b4568',
        channelId: '5fa966c79d6892022412f2aa', // empty channel
        ref1: '',
        width: 100,
        height: 0,
        autoPlay: false,
        soundButton: false,
        pauseButton: false,
        closebutton: false,
        errorlimit: 2,
        vastRetry: 2,
        loop: true,
        skip: false,
        showReplay: true,
        timelineMode: 'None',
        customcss: '#av-container #av-inner #gui:before {background: transparent !important;} #av-container #buttons {display: none !important;}',
        logo: false,
        completeonadstopped: true
      },
      playerVersion: 8,
      oldPosition: props.playerId,
      position: props.playerId,
      width: 100,
      height: 0,
      showBigPlay: true,
      showBigPlayOnPause: true,
      showBigPrevNextOnPause: true,
      showPauseButton: true,
      showPrevButton: false,
      showNextButton: false,
      showBigPrevNext: true,
      showSoundButton: true,
      showVolumeControl: true,
      volumeControlVertical: true,
      showErrorScreen: true,
      durationPosition: 'right',
      playlistAutoSkipOnError: true,
      showUiOnPause: false,
      showTimelineReplay: false,
      viewPercentageRequired: 1,
      showFullScreen: false,
      showDuration: true,
      hideGuiOnMouseout: false,
      hideGuiTimeout: 1,
      timelineMode: 'default',
      spinner: 'default',
      theme: 'solid',
      themeColor: '#ff0000',
      playOnView: false,
      startVolume: 1,
      syncVolume: true,
      autoPlay: true,
      passback: {
        actionTimeout: 999999,
        mode: 'OnPlaylistEnd'
      },
      autoloop: false,
      autoContinue: true,
      showReplay: true,
      primaryReplayButton: {
        enable: true
      },
      contentAutoLoad: true,
      content: {
        showSlotPlayButton: true,
        order: 0,
        breakingAds: 999999999,
        breakingAdsMode: 'op',
        contents: [
          {
            id: '1',
            url: 'https://s.tremorvideodsp.com/v/2024/09/S1Lv4eyTC/1080p.mp4',
            type: 'video'
          }
        ]
      }
    }
  }

  componentDidMount () {
    if (this.props.adTagUri) this.disableControlButtons()
    this.startPlayer()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.restartPlayer) {
      this.startPlayer()
    }
  }

  enableControlButtonsOnRunning () {
    this.currentPlayer.mergeConfig({ timeline: { enable: true } })
    this.currentPlayer.mergeConfig({ autoPlay: true })
    this.currentPlayer.mergeConfig({ showDuration: true })
    this.currentPlayer.mergeConfig({ showPauseButton: true })
    this.currentPlayer.mergeConfig({ volumeControlVertical: true })
    this.currentPlayer.mergeConfig({ showVolumeControl: true })
    this.currentPlayer.mergeConfig({ showSoundButton: true })
    this.currentPlayer.mergeConfig({ playbackRates: { enable: true } })

    this.enableControlButtons(true)
  }

  changeControlButtonsState (state) {
    this.config.timeline = { enable: state }
    this.config.autoPlay = state
    this.config.showDuration = state
    this.config.showPauseButton = state
    this.config.volumeControlVertical = state
    this.config.showVolumeControl = state
    this.config.playbackRates = { enable: state }
    this.config.showSoundButton = state
    this.setState({ isControlButtonsEnabled: state })
  }

  disableControlButtons () {
    this.changeControlButtonsState(false)
  }

  enableControlButtons () {
    this.changeControlButtonsState(true)
  }

  shouldComponentUpdate (nextProps, nextState) {
    const { props, state } = this

    const isAdTagUriChanged = props.adTagUri !== nextProps.adTagUri
    const isDeviceIdChanged = props.deviceId !== nextProps.deviceId
    const isHadVastChanged = state.hasVast !== nextState.hasVast
    const isAspectRatioChanged = props.playerOptions.aspectRatio !== nextProps.playerOptions.aspectRatio

    const shouldUpdate = isHadVastChanged || isDeviceIdChanged || isAdTagUriChanged || isAspectRatioChanged
    this.restartPlayer = shouldUpdate

    return shouldUpdate
  }

  startPlayer () {
    if (this.state.isAniviewLoaded) {
      this.setup()
    } else {
      this.loadAniviewLoader()
    }
  }

  loadAniviewLoader () {
    createScript(document.head, 'https://player.avplayer.com/script/8.3/v/avcplayer.js')
      .then(() => {
        this.setState({ isAniviewLoaded: true })
        this.setup()
      })
      .catch((err) => {
        this.setState({ isAniviewLoaded: false })
        console.log('ERROR:', err)
      })
  }

  waterfallFunc (callback) {
    console.log('water' + waterfall)
    callback(waterfall, 'cdn.aniview.com')
  }

  destroyPlayer () {
    if (this.currentPlayer) {
      this.config.heightRatio = null
      this.currentPlayer.closePlayer()
      this.currentPlayer = undefined
      const newPosition = this.config.oldPosition + this.timesGotDestroyed++
      this.container.current.id = newPosition
      this.config.position = newPosition
      this.config.adConfig.position = newPosition
      onResize(this.setVideoSize, true)
    }
    window.removeEventListener('message', this.receivePostMessage)
    resetPlayerInfo()
    this.props.removeLogs()
  }

  createPlayer () {
    return new Promise((resolve) => {
      if (window.avContentPlayer.createPlayer && !this.currentPlayer) {
        window.avContentPlayer.createPlayer().then((playerAPI) => {
          // eslint-disable-next-line new-cap
          this.currentPlayer = new playerAPI(this.config)
          this.currentPlayer.nextContent().then(() => {
            this.currentPlayer.pause()
            resolve()
          })
        })
      }
    })
  }

  setVideoSize () {
    const { paddingWidth, additionalPagePadding } = this.getDevicePadding()
    const mainWidth = document.querySelector('.main-body.row.no-gutters').clientWidth
    const debug = document.getElementById('panel-debug-wrapper')

    let videoWidth = mainWidth - paddingWidth - additionalPagePadding

    if ((videoWidth < 480) && (additionalPagePadding !== 0)) {
      videoWidth = mainWidth - paddingWidth
    }

    if (this.props.isDebug && debug) {
      const debugWidth = debug.offsetWidth || 0
      if (debugWidth !== mainWidth) {
        videoWidth -= debug.offsetWidth
      }
    }

    if (videoWidth > 36) {
      if (!this.deviceHeightRatio) this.deviceHeightRatio = this.calcHeightRatio(this.props.playerOptions.aspectRatio, true)
      const height = this.deviceHeightRatio * videoWidth
      this.currentPlayer.setSize(videoWidth, height)
    }

    return true
  }

  calcHeightRatio (aspectRatio, heightFirst = false) {
    const widthAndHeight = aspectRatio.split(':')
    if (heightFirst) widthAndHeight.reverse()
    return parseInt(widthAndHeight[0], 10) / parseInt(widthAndHeight[1], 10)
  }

  setup () {
    if (this.props.adTagUri !== '') {
      this.destroyPlayer()
      this.config.heightRatio = this.calcHeightRatio(this.props.playerOptions.aspectRatio)
      this.createPlayer().then(() => {
        this.setResizeEvent(this.props)
        const mediaFileType = mediaFileExt(this.props.adTagUri)
        let url = ''
        if (mediaFileType) {
          url = this.props.config.api.getVastTemplate.call(this.props.config, mediaFileType, this.props.adTagUri, this.props.url)
        } else {
          url = this.props.adTagUri
        }
        const encodedUrl = encodeURIComponent(url)
        const vastWrapperUrl = this.props.config.api.vastWrapper.call(this.props.config, encodedUrl, {})
        waterfall.data.tags[0].url = vastWrapperUrl
        this.currentPlayer.removeContentAt(0)
        this.currentPlayer.addContentAt(
          {
            slots: [
              {
                type: 'ad',
                firstAdTimeOut: 2,
                startAdTimeout: 2,
                preloadAdTime: 0,
                maxAds: 1,
                time: 0,
                nextAdTimeout: 8
              }
            ],
            id: '1',
            url: 'https://s.tremorvideodsp.com/v/2024/09/S1Lv4eyTC/1080p.mp4',
            type: 'video'
          },
          0
        )
        window.addEventListener('message', this.receivePostMessage, false)
        this.currentPlayer.on('Inventory', () => {
          if (!this.state.isControlButtonsEnabled) { this.enableControlButtonsOnRunning() }
        })
        this.currentPlayer.nextContent()
      })
    } else if (this.currentPlayer) {
      this.currentPlayer.setContentCurrentTime(0)
      this.currentPlayer.play(true)
    }
  }

  getDevicePadding () {
    let paddingWidth = 0; let additionalPagePadding = 0
    if (this.props.deviceId === 'desktop') {
      paddingWidth = 133
      additionalPagePadding = 370
    }
    return { paddingWidth, additionalPagePadding }
  }

  setResizeEvent (props) {
    if (props.deviceId === 'desktop') {
      onResize(this.setVideoSize)
      if (this.firstTimeUseAniview && this.currentPlayer) {
        this.setVideoSize()
        this.firstTimeUseAniview = false
      }
    }
  }

  componentWillUnmount () {
    this.destroyPlayer()
  }

  render () {
    const { config } = this
    return (
      <div style={{ height: '100%', width: '100%', margin: 'auto' }}>
        <div id={config.position} ref={this.container} />
      </div>
    )
  }
}

import React, { Component } from 'react'
import merge from 'lodash/merge'
import classnames from 'classnames'
import { devicesByStreams, filterScreenTypesByQuery } from '../DeviceOptions'
import DebugButton from './DebugButton'
import Dropdown from './Dropdown'
import { ReactComponent as SlidersControlSVG } from '../images/controls.svg'
import { ReactComponent as ClientLinkSVG } from '../images/cllientlink.svg'
import { mediaFileExt, getAdTagUri } from '../util/tag-util'

import { compilePath, normalizeDeviceCombinations } from '../util/pathmaker'

export default class Nav extends Component {
  handleGo () {
    const { props, refs } = this
    const params = merge({}, props.match.params, props.location.query)
    // params.deviceId = refs.device.value
    params.deviceId = refs.device.refs.dropdown.value
    params.deviceChildId = null
    const normalizedDevices = normalizeDeviceCombinations(params)
    const pathname = compilePath(props.match.path, Object.assign(params, normalizedDevices))
    props.history.push({
      pathname,
      search: props.location.search
    })
  }

  render () {
    const { props } = this
    let advertiserName
    if (props.location.query) {
      advertiserName = props.location.query.advertiserName
    }

    const filterScreenTypes = filterScreenTypesByQuery(props.location.query)

    const isClientLink = props.location.query && props.location.query.ft
    const optGroupByStreamType = props.optGroupByStreamType
    const isSingleScreenType = optGroupByStreamType && optGroupByStreamType.length === 1 && optGroupByStreamType[0].props.children.length === 1

    return (
      <div className='tmr-nav-background-fill'>
        <div className='container-fluid tmr-nav tmr-nav-lg'>
          <nav className='navbar navbar-toggleable-md navbar-expand-md navbar-dark'>
            {(props.match.params.workspace !== 'client' || !isSingleScreenType) &&
              <button
                className='navbar-toggler tmr-nav-toggler' type='button' data-toggle='collapse'
                data-target='#navbar-main-collapse' aria-controls='navbar-main-collapse' aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='navbar-toggler-icon' />
              </button>}
            <div className='collapse navbar-collapse tmr-navbar-collapse' id='navbar-main-collapse'>
              <div className='brand-info-wrapper'>
                {advertiserName &&
                  <div className='brand-info'>
                    Advertiser: {advertiserName}
                  </div>}
              </div>
              <ul className='navbar-nav'>
                {!isClientLink &&
                  <li className='nav-item tmr-nav-item'>
                    <button
                      onClick={props.toggleClientLinkModal} // eslint-disable-line react/jsx-handler-names
                      className={classnames('btn btn-info tmr-nav-btn roundedBorder', { selected: props.showingClientLinkModal })}
                      type='button'
                    >
                      <ClientLinkSVG className='svg-icon' width='25px' height='25px' />
                      <div className='tmr-nav-btn-text'>
                        Client Link
                      </div>
                    </button>
                  </li>}
                {(props.match.params.workspace !== 'client' && !isClientLink && !mediaFileExt(getAdTagUri(props))) &&
                  <li className='nav-item tmr-nav-item'>
                    <DebugButton
                      history={props.history}
                      location={props.location}
                      match={props.match}
                    />
                  </li>}
                {props.match.params.workspace !== 'client' && !isClientLink &&
                  <li className='nav-item tmr-nav-item'>
                    <button
                      onClick={props.toggleAdvancedPanel} // eslint-disable-line react/jsx-handler-names
                      className={classnames('btn btn-info tmr-nav-btn roundedBorder', { selected: props.showingAdvancedPanel })}
                      type='button'
                    >
                      <SlidersControlSVG className='svg-icon' width='25px' height='25px' />
                      <div className='tmr-nav-btn-text'>
                        Settings
                      </div>
                    </button>
                  </li>}

                <li className='nav-item' style={{ minWidth: 305 }}>
                  {!isSingleScreenType &&
                    <div className='form-group'>
                      <Dropdown
                        label='Screen Type'
                        value={props.match.params.deviceId}
                        onChange={this.handleGo.bind(this)}
                        ref='device' // eslint-disable-line react/no-string-refs
                        height='40px'
                        clientLinkFilter={filterScreenTypes}
                        devicesByStreams={devicesByStreams}
                        optGroupByStreamType={optGroupByStreamType}
                      />
                    </div>}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

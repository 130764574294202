import React, { Component } from 'react'
import './styles/App.scss'
import 'es6-shim' // Provides all ES6 shims for legacy browsers
import 'es7-shim' // Provides all ES7 shims for browsers
import 'custom-event-polyfill' // Shims CustomEvent constructor for IE & Safari?
import { Router, Route, Switch } from 'react-router-dom'
import { createHashHistory } from 'history'
import qhistory from 'qhistory'
import { compilePath, compileSearch, getAspectRatioShortcut, parse, stringify } from './util/pathmaker'
import {
  siuBanerSizes,
  findByDeviceId,
  streamsTypes
} from './DeviceOptions.js'
import cloneDeep from 'lodash/cloneDeep'
import JWPlayerDebug from './JWPlayerDebug'
import SiuPlayer from './SiuPlayer'
import Config from './util/config'
import { References } from './util/references'
import { updateBodyClass } from './util/utils'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom/cjs/react-router-dom'
// export const routePath = '/:deviceId/:deviceChildId/:player/:workspace'
export const routePath = '/:deviceId?/:deviceChildId?/:player?/:workspace?/:enableCompanion?' // All params optional
export const siuRoutePath = '/tv/:siuBrand/:bannerSize?/:workspace?' // All params optional
export const wedgeRoutePath = '/tv/wedge/:workspace?' // All params optional
export const homeLauncherRoutePath = '/tv/home-launcher/:workspace?' // All params optional
const hashHistory = createHashHistory({
  basename: '',
  hashType: 'slash'
})
const history = qhistory(
  hashHistory,
  stringify,
  parse
)

class App extends Component {
  render () {
    return (
      <Router basename='/' history={history}>
        <Switch>
          <Route path={wedgeRoutePath}><AppWrapper props={this.props} /></Route>
          <Route path={homeLauncherRoutePath}><AppWrapper props={this.props} /></Route>
          <Route path={siuRoutePath}><AppWrapper props={this.props} /></Route>
          <Route path={routePath}><AppWrapper props={this.props} /></Route>
        </Switch>
      </Router>
    )
  }
}

export default App

/**
 * Added to facilitate AFD-3886, task 2.
 * This implementation avoids mutating any object.
 * Instead, match and location are cloned before modifying.
 * history is the same between all requests (mutable), so history is kept as-is.
 * Use location in lieu of history.location in the code as they no longer reference the same object.
 */

function AppWrapper (app) {
  let { match, history, location, params } = app.props

  if (!Object.keys(app.props).length) { // Temp - Tests not passing with Hooks
    location = useLocation()
    history = useHistory()
    match = useRouteMatch()
  }
  params = match && match.params // Temp
  const paramsHook = useParams()
  console.log('paramsHook - ', paramsHook)

  return (
    <SetDefaultParams
      params={params}
      match={match}
      history={history}
      location={location}
    />
  )
}

class SetDefaultParams extends Component {
  constructor (props) {
    super(props)
    this.config = new Config()
    this.updateEnvironment()
  }

  updateEnvironment () {
    const hostname = window.location.hostname
    this.setBranding(hostname)
    this.setEnv(hostname)
  }

  setEnv (hostname) {
    const getEnv = this.config.env.get.bind(this.config)
    const setHost = this.config.host.set.bind(this.config)
    setHost(getEnv(hostname))
  }

  setBranding (hostname) {
    const defaultBrand = this.config.branding.get.call(this.config)
    const brand = References.branding.brands.filter(brand => hostname.toLowerCase().includes(brand.toLowerCase()))[0] || defaultBrand
    this.config.branding.set.call(this.config, brand)
  }

  getPlayer (player, streamType) {
    let currPlayer = References.player.aniview.inStream.id
    if (streamType !== streamsTypes.Outstream && (player === References.player.aniview.outStream.id || !player)) {
      currPlayer = References.player.aniview.inStream.id
    } else if (streamType === streamsTypes.Outstream) {
      currPlayer = References.player.aniview.outStream.id
    }

    return currPlayer
  }

  setAspectRatios (deviceId, deviceChildId, props) {
    const aspectRatioShortcuts = getAspectRatioShortcut(deviceId, deviceChildId, { mob: null, tab: null })

    const search = aspectRatioShortcuts.mob
      ? compileSearch(props.location.search, {
          mob: aspectRatioShortcuts.mob,
          tab: aspectRatioShortcuts.tab
        })
      : props.location.search

    console.log(window.location.href)
    const isPathNeedsToUpdate = search !== props.location.search
    if (isPathNeedsToUpdate) {
      props.history.push({
        pathname: compilePath(props.match.path, Object.assign({}, props.match.params, {})),
        search
      })
    }
  }

  render () {
    let { match, history, location } = this.props
    this.props.location.config = this.config
    const isWedge = match.url.endsWith('wedge')
    const isHomeLauncher = match.url.endsWith('home-launcher')
    match = cloneDeep(match)
    location = cloneDeep(location)

    const branding = this.config.branding.get.call(this.config)
    updateBodyClass.add(`${branding}Branding`)

    let { deviceId, deviceChildId, player, workspace, bannerSize, siuBrand, enableCompanion } = match.params

    deviceId = deviceId || 'desktop'
    const verticalMobile = deviceId === 'verticalmobilephone'
    const device = findByDeviceId(deviceId)
    deviceId = device.id

    const param = {
      mediaType: References.paramName.mediaType,
      displayAdUrl: References.paramName.displayAd.url,
      displayAdWidth: References.paramName.displayAd.width,
      displayAdHeight: References.paramName.displayAd.height
    }
    const mediaType = location.query[param.mediaType] || null
    const displayAdInfo = {
      url: location.query[param.displayAdUrl] || null,
      width: location.query[param.displayAdWidth] || null,
      height: location.query[param.displayAdHeight] || null
    }
    deviceChildId = device.getChildById(deviceChildId).id || device.children[0].id
    if (verticalMobile) deviceChildId = device.children[0].id
    if (!location.search.includes('&tab=')) this.setAspectRatios(deviceId, deviceChildId, this.props)
    const streamType = device.streamType
    player = this.getPlayer(player, device.streamType)
    workspace = workspace || 'basic'
    enableCompanion = enableCompanion || 'false'
    if (siuBrand || isWedge || isHomeLauncher) {
      if (!bannerSize) match.params.bannerSize = siuBanerSizes[match.params.siuBrand] ? siuBanerSizes[match.params.siuBrand][0].id : ''
      return <SiuPlayer match={match} history={history} location={location} isWedge={isWedge} isHomeLauncher={isHomeLauncher} />
    } else {
      match.params = { deviceId, deviceChildId, player, workspace, enableCompanion, streamType }
      match.url = `/${deviceId}/${deviceChildId}/${player}/${workspace}`
      match.path = routePath
      location.pathname = `/${deviceId}/${deviceChildId}/${player}/${workspace}`
      return <JWPlayerDebug match={match} history={history} location={location} streamType={device.streamType} mediaType={mediaType} displayAdInfo={displayAdInfo} branding={branding} />
    }
  }
}

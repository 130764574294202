import React, { Component } from 'react'
import merge from 'lodash/merge'
import qs from 'qs'
import {
  compilePath,
  compileSearch, getAspectRatioShortcut
} from '../util/pathmaker'
import { findByDeviceId, streamsTypes } from '../DeviceOptions'
import AdTagUri from './AdTagUri'
import { References } from '../util/references'
// import AdvancedPanelDropdown from './AdvancedPanelDropdown'
import Dropdown from './Dropdown'
import AdvancedPanelDropdownOptions from './AdvancedPanelDropdownOptions'
import DCOPanel from './DCOPanel'
import { dcoOptions } from '../DcoOptions.js'
import { isBaseCustomizerWrappable, mediaFileExt, rebuildTagWithDCO, saveAdTagUri } from '../util/tag-util'
import PlayerFactory from '../factory/player/playerFactory'

export default class JWPlayerAdvancedPanel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showDcoPanel: isBaseCustomizerWrappable(props.adTagUri)
    }
    this.playerFactory = new PlayerFactory()
    this.handleDcoPanelShow = this.handleDcoPanelShow.bind(this)
  }

  componentDidMount () {
    saveAdTagUri('')
  }

  handleGo (ev) {
    ev.preventDefault()
    const { refs, props, state } = this

    let newTag = refs.adTagUri.refs.inputUrl.value
    if (state.showDcoPanel) {
      const dcoRefs = refs.dco.refs
      const dcoParams = dcoOptions.reduce((params, opt) => {
        const dcoInput = dcoRefs[`input-${opt.id}`]
        if (dcoInput && dcoInput.value) {
          dcoInput.value = dcoInput.value.trim()
          if (dcoInput.value) {
            params[opt.dco] = dcoInput.value
          }
        }
        return params
      }, {})
      const dcoParamStr = qs.stringify(dcoParams)
      newTag = rebuildTagWithDCO(newTag, dcoParamStr)
    }

    const nextAdvertising = merge({}, props.playerOptions.advertising)
    // nextAdvertising.client = refs.client.refs.dropdown.value
    const playerOptions = merge({}, props.playerOptions, {
      advertising: nextAdvertising
    })

    const newAspectRatios = getAspectRatioShortcut(props.deviceId, refs.deviceChild.refs.dropdown.value, props.location.query)

    const search = compileSearch(props.location.search, {
      playerOptions,
      // adTagUri: refs.adTagUri.refs.inputUrl.value
      adTagUri: newTag,
      mob: newAspectRatios.mob,
      tab: newAspectRatios.tab
    })

    const pathNeedsUpdate =
      search !== props.location.search ||
      refs.deviceChild.refs.dropdown.value !== props.match.params.deviceChildId ||
      refs.player.refs.dropdown.value !== props.match.params.player
    if (pathNeedsUpdate) {
      props.toggleAdvancedPanel(null, true) // force panel closed on successful form submit
      props.history.push({
        pathname: compilePath(props.match.path, Object.assign({}, props.match.params, {
          deviceChildId: refs.deviceChild.refs.dropdown.value,
          player: refs.player ? refs.player.refs.dropdown.value : undefined,
          streamType: props.match.params.streamType
        })),
        search
      })
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    const { props, state } = this
    return props.location.search !== nextProps.location.search ||
      props.match.params.deviceId !== nextProps.match.params.deviceId ||
      props.match.params.deviceChildId !== nextProps.match.params.deviceChildId ||
      props.match.params.player !== nextProps.match.params.player ||
      props.match.params.workspace !== nextProps.match.params.workspace ||
      state.showDcoPanel !== nextState.showDcoPanel
  }

  handleDcoPanelShow (tag) {
    const canBeCustomizerWrapped = isBaseCustomizerWrappable(tag)
    if (canBeCustomizerWrapped !== this.state.showDcoPanel) {
      this.setState(() => {
        return { showDcoPanel: canBeCustomizerWrapped }
      })
    }
  }

  getPlayerOptions (selectedDevice) {
    const aniviewPlayer = this.playerFactory.createPlayer(References.player.aniview.inStream.name, {
      mediaType: 'mp4',
      streamType: selectedDevice.streamType
    })
    const aniviewPlayerOutstream = this.playerFactory.createPlayer(References.player.aniview.outStream.name, {
      mediaType: 'mp4',
      streamType: selectedDevice.streamType
    })

    if (selectedDevice.streamType === streamsTypes.Outstream) {
      return [aniviewPlayerOutstream]
    }

    return [aniviewPlayer]
  }

  render () {
    const { props, state } = this
    const selectedDevice = findByDeviceId(props.match.params.deviceId)
    return (
      <div className='JWAdvancePanel-collapse' id='collapseExample'>
        <div className=''>
          <div className='card JWAdvancePanel-card AdvancePanel'>
            <div className='container-fluid card-body'>
              <form onSubmit={this.handleGo.bind(this)}>
                <div className='row AdvancePanel-label'>
                  <div className='col-12'>Ad Tag Uri</div>
                </div>
                <AdTagUri
                  match={props.match}
                  value={props.adTagUri}
                  onHandleDcoPanelShow={this.handleDcoPanelShow}
                  ref='adTagUri' // eslint-disable-line react/no-string-refs
                  key={props.adTagUri}
                  branding={props.branding}
                />
                <div className='row'>
                  <div className='col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                    <Dropdown
                      label='Player Type'
                      defaultVal={props.match.params.player}
                      key={props.match.params.player}
                      ref='player' // eslint-disable-line react/no-string-refs
                      disabled={props.match.params.player === 'aniviewplayeroutstream' || !!mediaFileExt(props.adTagUri)}
                    >
                      <AdvancedPanelDropdownOptions
                        options={this.getPlayerOptions(selectedDevice)}
                      />
                    </Dropdown>
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                    <Dropdown
                      label='Screen Size'
                      defaultVal={props.match.params.deviceChildId}
                      key={props.match.params.deviceChildId}
                      ref='deviceChild' // eslint-disable-line react/no-string-refs
                      disabled={props.match.params.player === 'aniviewplayeroutstream'}
                    >
                      <AdvancedPanelDropdownOptions options={selectedDevice.children} />
                    </Dropdown>
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 text-center'>
                    <button type='submit' className='btn btn-info apply-btn roundedBorder'>Apply</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {state.showDcoPanel &&
            <DCOPanel
              ref='dco' // eslint-disable-line react/no-string-refs
              adTagUri={props.adTagUri}
              key={props.adTagUri}
            />}
        </div>
      </div>
    )
  }
}

JWPlayerAdvancedPanel.defaultProps = {
  playerOptions: {
    advertising: {}
  }
}

import React, { Component } from 'react'
import classNames from 'classnames'
import SiuNav from './components/siu/SiuNav'
import SiuAdvancedPanel from './components/siu/SiuAdvancedPanel'

import Collapse from 'react-bootstrap/Collapse'
import Footer from './components/Footer'
import tvSkinImg from './devices/siu/smart_tv_skin.png'
import CloseBtnSvg from './devices/siu/closeX.svg'

export default class SiuPlayer extends Component {
  constructor (props) {
    super(props)

    this.defaultClickThruURL = 'https://www.tremorvideo.com'
    this.setupSiuJson()

    this.state = {
      logs: [],
      didMount: false,
      showAdvancedPanel: false,
      siuImagesMap: {},
      clickThruURL: '',
      videoURL: '', // https://s.tremorvideodsp.com/v/2019/03/r17AieVw4/720p.mp4
      showIframe: false,
      showVideo: false,
      showDeepLink: false,
      deepLink: '',
      showWedgeMyContent: false
    }
    this.toggleAdvancedPanel = this.toggleAdvancedPanel.bind(this)
    this.handleBannerClick = this.handleBannerClick.bind(this)
    this.handleCloseBtnClick = this.handleCloseBtnClick.bind(this)
    this.handleWedgeHomeBtnClick = this.handleWedgeHomeBtnClick.bind(this)
    this.videoEnded = this.videoEnded.bind(this)
    this.SiuImages = require.context('./devices/siu', true)
    this.LgWedgeImages = require.context('./devices/lg-wedge', true)
    this.LgHomeLauncherImages = require.context('./devices/lg-home-launcher', true)
    this.prevBannerSize = props.match.params.bannerSize
  }

  videoEnded () {
    this.closeOverlay()
  }

  setupSiuJson () {
    this.jsonUri = this.props.location.query.jsonUri || this.props.location.query.siuJsonUri
    if (!this.jsonUri) {
      alert('No SIU tag. Please make sure you are using a valid URL.')
    } else {
      this.parseSiuJson(this.jsonUri)
    }
  }

  parseSiuJson (jsonUri) {
    fetch(jsonUri)
      .then(response => {
        response.json().then(data => {
          this.siuJson = data
          const regexBanenrSize = /([^/]+)\.png$/
          const siuImagesMap = {}

          if (this.props.isWedge) {
            siuImagesMap.wedge = this.siuJson.image
          } else if (this.props.isHomeLauncher) {
            siuImagesMap.homeLauncher = this.siuJson.image
          } else {
            this.siuJson.images.forEach((imgUrl) => {
              const regExRes = regexBanenrSize.exec(imgUrl)
              if (regExRes && regExRes[1]) {
                const size = regExRes[1]
                siuImagesMap[size] = imgUrl
              }
            })
          }

          let url = this.defaultClickThruURL
          if (this.siuJson.defaultClickThruURL) {
            url = this.siuJson.defaultClickThruURL
          }
          let videoURL
          if (this.siuJson.videoURL) {
            videoURL = this.siuJson.videoURL
          }
          let deepLink
          if (this.siuJson.deepLink) {
            deepLink = this.siuJson.deepLink
          }

          this.setState({
            siuImagesMap: siuImagesMap,
            clickThruURL: url,
            videoURL: videoURL,
            deepLink: deepLink
          })
        })
      })
      .catch(() => {
        alert('No SIU tag. Please make sure you are using a valid URL.')
      })
  }

  toggleAdvancedPanel (evt, forceClosed) {
    this.setState(prevState => {
      return {
        showAdvancedPanel: !forceClosed && !prevState.showAdvancedPanel
      }
    })
  }

  closeOverlay () {
    this.setState({
      showVideo: false,
      showIframe: false,
      showDeepLink: false
    })
  }

  handleCloseBtnClick () {
    this.closeOverlay()
  }

  closeWedgeMyContent () {
    this.setState({
      showWedgeMyContent: false
    })
  }

  handleWedgeHomeBtnClick (e) {
    e.stopPropagation()
    this.closeWedgeMyContent(e)
  }

  handleBannerClick () {
    if (this.props.isWedge && !this.state.showWedgeMyContent) {
      this.setState({
        showWedgeMyContent: true
      })
    } else {
      if (this.state.videoURL) {
        // play video
        this.setState({
          showVideo: true
        })
      } else if (this.state.deepLink) {
        this.setState({
          showDeepLink: true
        })
      } else {
        this.setState({
          showIframe: true
        })
      }
    }

    console.log('banner clicked.')
  }

  handleSetVideoUrl (videoUrl) {
    this.toggleAdvancedPanel()
    this.setState({
      videoURL: videoUrl
    })
  }

  componentDidUpdate () {
    if (this.props.match.params.bannerSize !== this.prevBannerSize) {
      this.prevBannerSize = this.props.match.params.bannerSize
      this.setState({ siuContImgLoaded: false })
    }
  }

  render () {
    const appClass = classNames('App')
    const { props, state } = this
    // const selectedDevice = findByDeviceId(props.match.params.deviceId)
    // const selectedDeviceChild = selectedDevice.getChildById(props.match.params.deviceChildId)
    // let playerOptions = merge({}, selectedDeviceChild.jwPlayerOptions, props.location.query.playerOptions || {})
    // Only show advanced panel on client mode when no ad tag; show when toggled for other workspaces

    if (this.jsonUri !== (this.props.location.query.jsonUri || this.props.location.query.siuJsonUri)) {
      this.setupSiuJson()
    }
    const shouldShowAdvancedPanel = state.showAdvancedPanel

    let bannerImageUrl
    if (props.isWedge) {
      bannerImageUrl = state.siuImagesMap.wedge
    } else if (this.props.isHomeLauncher) {
      bannerImageUrl = state.siuImagesMap.homeLauncher
    } else {
      bannerImageUrl = state.siuImagesMap[props.match.params.bannerSize]
    }

    let contentSubTypeName = props.match.params.bannerSize
    let tvAdType = 'siu'
    if (props.isWedge) {
      tvAdType = contentSubTypeName = 'wedge'
    } else if (props.isHomeLauncher) {
      tvAdType = contentSubTypeName = 'home-launcher'
    }

    let contentSkinPath
    if (props.isWedge) {
      contentSkinPath = this.LgWedgeImages('./wedge_lg_screen.png')
    } else if (props.isHomeLauncher) {
      contentSkinPath = this.LgHomeLauncherImages('./home_launcher_lg_screen.png')
    } else {
      contentSkinPath = this.SiuImages(`./${contentSubTypeName}_content_skin.png`)
    }

    let contentSkinClass
    if (props.isWedge || props.isHomeLauncher) {
      contentSkinClass = `lg-${contentSubTypeName}`
    } else {
      contentSkinClass = `${props.match.params.siuBrand}-${contentSubTypeName}`
    }

    return (
      <div className={appClass}>
        <div className='row no-gutters'>
          <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <SiuNav
              history={props.history}
              location={props.location}
              match={props.match}
              toggleAdvancedPanel={this.toggleAdvancedPanel}
              showingAdvancedPanel={state.showAdvancedPanel}
              ref='nav' // eslint-disable-line react/no-string-refs
              showSizeSelection={!(props.isWedge || props.isHomeLauncher)}
            />
          </div>
        </div>
        <div className='row no-gutters'>
          <div className='jw-advanced-panel col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <Collapse in={shouldShowAdvancedPanel}>
              <div>
                <SiuAdvancedPanel
                  history={props.history}
                  location={props.location}
                  match={props.match}
                  adTagUri={this.adTagUri}
                  handleSetVideoUrl={this.handleSetVideoUrl.bind(this)}
                />
              </div>
            </Collapse>
          </div>
        </div>

        <div className='siu-player-wrapper'>
          <div className={classNames(`siu-content ${contentSkinClass}`)}>
            <img className='siu-skin-image' src={tvSkinImg} alt='siu skin' />
            <img className={classNames(`${tvAdType}-content-image`)} alt='siu skin content' onLoad={() => this.setState({ siuContImgLoaded: true })} src={contentSkinPath} />
            <span className={classNames(`siu-content-ad banner-size-${contentSubTypeName}`, { 'wedge-my-content-open': state.showWedgeMyContent })} onClick={this.handleBannerClick}>
              {this.state.siuContImgLoaded && <img className={classNames('siu-banner-image')} alt='banner' src={bannerImageUrl} />}
              {(this.state.siuContImgLoaded && props.isWedge) && <img className={classNames('wedge-my-content', { 'wedge-my-content-open': state.showWedgeMyContent })} alt='banner' src={this.LgWedgeImages('./wedge_lg_MyContent.png')} />}
              {(this.state.siuContImgLoaded && props.isHomeLauncher) && <img className={classNames('home-launcher-reverse-banner-image')} alt='banner' src={bannerImageUrl} />}
              {(this.state.siuContImgLoaded && props.isWedge) && <img className={classNames('wedge-open-button', { 'wedge-my-content-open': state.showWedgeMyContent })} alt='banner' src={this.LgWedgeImages('./wedge_lg_screen_button.png')} />}
              {(this.state.siuContImgLoaded && props.isWedge) && <img className={classNames('wedge-go-button', { 'wedge-my-content-open': state.showWedgeMyContent })} alt='banner' src={this.LgWedgeImages('./wedge_lg_MyContent_Button.png')} />}

              {(this.state.siuContImgLoaded && props.isWedge) && <div className={classNames('wedge-home-button', { 'wedge-my-content-open': state.showWedgeMyContent })} alt='banner' onClick={this.handleWedgeHomeBtnClick} />}

            </span>
            {(state.showIframe || state.showVideo || state.showDeepLink) && (
              /* eslint-disable react/jsx-handler-names */
              <div className='banner-action-area'>
                {state.showIframe && <div className='siu-click-thru-cover-eventDuration'><div className='eventDuration'>Landing page will load here</div></div> /* <iframe className='click-thru-iframe' title='click thru website' src={state.clickThruURL} /> */}
                {state.showVideo && <video className='siu-video-player' src={state.videoURL} onEnded={this.videoEnded} playsInline autoPlay />}
                {state.showDeepLink && <div className='deep-link-box'><span>App landing page will load here.</span></div>}

                <img className='close-btn' alt='close button' src={CloseBtnSvg} onClick={this.handleCloseBtnClick} />
              </div>
              /* eslint-disable react/jsx-handler-names */
            )}
          </div>

        </div>
        <Footer
          match={props.match}
        />
      </div>
    )
  }
}

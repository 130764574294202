import React, { Component } from 'react'
import { consoleLogInfo, checkUrlStatusResult, createImage } from '../util/utils'
import { References } from '../util/references'
import launcherBlank from '../images/launcher_blank.png'
import extraWideBlank from '../images/extrawide_blank.png'
import ErrorBg from '../images/empty-banner-1707x440.png'
import clickToBrowseImg from '../images/screen-deepLink.png'
import { Resources } from '../util/resources'
import SamsungPlayer from './player/SamsungPlayer'

const screenTypes = ['regular', 'extraWide']
const clickActions = ['deepLink', 'displayVideo']

export default class SamsungView extends Component {
  constructor (props) {
    super(props)

    this.setupEvents()
    this.state = {
      showError: false,
      errorMessage: '',
      launcherSrc: launcherBlank,
      screenSrc: (this.props.deviceId === 'samsungExtraWide' ? extraWideBlank : launcherBlank),
      clickScreen: null,
      showVideo: false,
      isShowingPromptImg: false
    }
    this.immediateUse = { showError: false }
    this.launcherImg = React.createRef()
    this.screenImg = React.createRef()
    this.loaderLauncher = React.createRef()
    this.loaderScreen = React.createRef()
    this.isBannersValid = false
    this.keyPressed = false
    this.remote = React.createRef()
    this.json = null
    this.launcherPanelURL = null
    this.screenPanelURL = null
    this.updateBanners = false
    this.videoURI = null
    this.bannerType = 'regular'
  }

  componentDidMount () {
    const waitForRender = callback => setTimeout(() => { callback() }, 0)
    waitForRender(() => { this.addBtnEvents(true) })
    this.setup()
  }

  shouldComponentUpdate (nextProps, nextState) {
    const { props } = this
    const willUpdate = !(props.adTagUri === nextProps.adTagUri &&
      this.immediateUse.showError === this.state.showError &&
      this.state.launcherSrc === nextState.launcherSrc &&
      this.state.errorMessage === nextState.errorMessage &&
      this.state.clickScreen === nextState.clickScreen &&
      this.state.showVideo === nextState.showVideo &&
      this.state.isShowingPromptImg === nextState.isShowingPromptImg
    )
    this.updateBanners = !this.json || props.adTagUri !== nextProps.adTagUri || this.immediateUse.showError !== this.state.showError
    return willUpdate
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.addBtnEvents(true)
    this.setup()
    if (this.props.adTagUri !== prevProps.adTagUri) {
      this.setState(() => {
        return {
          clickScreen: null,
          showVideo: false
        }
      })
    }
  }

  componentWillUnmount () {
    this.addBtnEvents(false)
  }

  setStateNotification (objState) {
    this.setState(objState)
  }

  setErrorState (isError, message) {
    this.immediateUse.showError = isError
    this.setState(() => {
      return {
        showError: isError,
        errorMessage: message,
        clickScreen: null,
        showVideo: false
      }
    })
  }

  setupEvents () {
    this.elRef = {
      remoteOk: React.createRef(),
      remoteBack: React.createRef()
    }
    this.btnEvents = {
      remoteOk: { ref: this.elRef.remoteOk, event: 'click', action: this.onOkay.bind(this) },
      remoteOkHoverIn: { ref: this.elRef.remoteOk, event: 'mouseenter', action: this.handleMouseEnter.bind(this, 'remoteOkHover') },
      remoteOkHoverOut: { ref: this.elRef.remoteOk, event: 'mouseleave', action: this.handleMouseLeave.bind(this, 'remoteOkHover') },
      remoteOkPressed: { ref: this.elRef.remoteOk, event: 'mousedown', action: this.handleKeyPressed.bind(this, 'remoteOkPressed') },
      remoteBack: { ref: this.elRef.remoteBack, event: 'click', action: this.onBack.bind(this) },
      remoteBackHoverIn: { ref: this.elRef.remoteBack, event: 'mouseenter', action: this.handleMouseEnter.bind(this, 'remoteBackHover') },
      remoteBackHoverOut: { ref: this.elRef.remoteBack, event: 'mouseleave', action: this.handleMouseLeave.bind(this, 'remoteBackHover') },
      remoteBackPressed: { ref: this.elRef.remoteBack, event: 'mousedown', action: this.handleKeyPressed.bind(this, 'remoteBackPressed') }
    }
  }

  addBtnEvents (isAddEvent) {
    for (const prop in this.btnEvents) {
      const { ref, event, action } = this.btnEvents[prop]
      if (ref.current) {
        ref.current.removeEventListener(event, action)
        if (isAddEvent) ref.current.addEventListener(event, action)
      }
    }
  }

  handleMouseEnter (hoverClass) {
    this.remote.current.classList.add(hoverClass)
  }

  handleMouseLeave (hoverClass) {
    this.remote.current.classList.remove(hoverClass)
  }

  handleKeyPressed (clickClass) {
    if (this.keyPressed === false) {
      this.keyPressed = true
      this.remote.current.classList.add(clickClass)
      setTimeout(() => {
        this.remote.current.classList.remove(clickClass)
        this.keyPressed = false
      }, 200)
    }
  }

  onOkay () {
    if (!this.state.showError && this.props.adTagUri) {
      const data = this.json
      if (data && data.clickAction) {
        const clickAction = data.clickAction
        const actions = References.paramName.samsungAd.clickAction
        if (clickAction === actions.deepLink) {
          this.setState(() => {
            return { clickScreen: clickToBrowseImg, showVideo: false, isShowingPromptImg: true }
          })
        } else if (clickAction === actions.displayVideo) {
          this.setState(() => {
            return { showVideo: true, isShowingPromptImg: false }
          })
        }
      }
    }
  }

  onBack () {
    if (this.state.isShowingPromptImg || this.state.showVideo) {
      this.setState(() => { return { isShowingPromptImg: false, showVideo: false } })
    }
  }

  setup () {
    const { props } = this

    if (props.adTagUri) {
      if (!this.updateBanners && this.json && this.isBannersValid) {
        this.getBannerImg()
        return
      }
      this.isBannersValid = false
      checkUrlStatusResult('Samsung Ad Log URL Request', props.adTagUri, async (isError, json) => {
        let message = Resources.error.message.samsungAd.genericErrorMessage
        if (!isError) {
          await this.validateBannerData(json)
            .then(() => {
              this.isBannersValid = true
              this.videoURI = json.video.src || ''
              this.title = json.video.title || ''
              this.description = json.video.desc || ''
              this.bannerType = json.screenType || 'regular'
              this.ctaTitle = json.ctaTitle || ''
              message = ''
              this.getBannerImg()
            })
            .catch((msg) => {
              message = msg
              isError = true
              consoleLogInfo(message, 'error')
            })
        }
        this.setErrorState(isError, message)
      })
    } else {
      this.setErrorState(true, '')
    }
  }

  validateBannerData (json) {
    return new Promise((resolve, reject) => {
      if (json && json.screenType && screenTypes.includes(json.screenType) && json.clickAction && clickActions.includes(json.clickAction)) {
        this.json = json
        resolve()
      }
      reject(Resources.error.message.samsungAd.incorrectBanner)
    })
  }

  checkImgSize (img, isLauncher) {
    const expectedWidth = (isLauncher ? 480 : 1840)
    const expectedHeight = (isLauncher ? 270 : 320)
    const realWidth = img.naturalWidth
    const realHeight = img.naturalHeight
    if (realWidth !== expectedWidth || realHeight !== expectedHeight) {
      consoleLogInfo(`Banner is not expected size of  ${expectedWidth} x ${expectedHeight}`, 'warning')
    }
  }

  getBannerImg () {
    if (this.json) {
      if (!this.state.showVideo && !(this.launcherImg.current.classList.contains('loaded'))) this.showLoading(true)

      let launcherSrc = ''
      let screenSrc = ''
      let resultText = ''
      let messageType = 'error'

      createImage(this.json.launcherSrc, 'launcher')
        .then((img) => {
          launcherSrc = img.src
          this.checkImgSize(img, true)
        }).then(() => {
          return createImage(this.json.screenSrc, 'screen')
        }).then((img) => {
          screenSrc = img.src
          resultText = 'Successfully loaded image banner.'
          messageType = 'success'
          this.checkImgSize(img, this.props.deviceId === 'samsungExtraWide')
        }).catch(error => {
          launcherSrc = launcherBlank
          screenSrc = (this.props.deviceId === 'samsungExtraWide' ? extraWideBlank : launcherBlank)
          resultText = `Error loading image banner. ${error}`
        }).finally(() => {
          consoleLogInfo(resultText, messageType)
          this.showLoading(false)
          this.setState(() => {
            return { launcherSrc, screenSrc }
          })
        })
    }
  }

  showLoading (isShow) {
    if (this.loaderLauncher.current && this.loaderScreen.current && this.launcherImg.current && this.screenImg.current) {
      if (isShow) {
        this.launcherImg.current.classList.remove('loaded', 'samsung')
        this.launcherImg.current.classList.add('removing')

        this.screenImg.current.classList.remove('loaded', 'samsung')
        this.screenImg.current.classList.add('removing')

        this.loaderLauncher.current.classList.add('active')
        this.loaderScreen.current.classList.add('active')
      } else {
        this.launcherImg.current.classList.remove('removing')
        this.launcherImg.current.classList.add('loaded', 'samsung')

        this.screenImg.current.classList.remove('removing')
        this.screenImg.current.classList.add('loaded', 'samsung')

        this.loaderLauncher.current.classList.remove('active')
        this.loaderScreen.current.classList.remove('active')
      }
    }
  }

  render () {
    const htmlErrorMessage = (
      <div className='vidaaAdError samsung-error'>
        <span className='vidaaAdErrorBg'>
          <img className='vidaaAdErrorImg' src={ErrorBg} />
        </span>
        <span className='vidaaAdErrorTxt'>
          {this.state.errorMessage}
        </span>
      </div>
    )
    const samsungAdContainerClasses = this.immediateUse.showError ? 'vidaaAdContainer' : 'vidaaAdContainer ' + this.props.deviceId
    return (
      <>
        <div className='vidaaRemote show' ref={this.remote}>
          <div className='remoteRow samsung-ok'>
            <div className='remoteBtnOkay' ref={this.elRef.remoteOk} />
            <div className='remoteBtnBack' ref={this.elRef.remoteBack} />
          </div>
        </div>

        {
          this.state.clickScreen && this.state.isShowingPromptImg &&
            <div className='screenPopup deepLink'>
              <img src={this.state.clickScreen} />
            </div>
        }
        {
          this.videoURI && this.state.showVideo &&
            <>
              <div className='closeScreenInstruction'>Press the back button on the remote to close the video</div>
              <SamsungPlayer
                video={this.videoURI}
                title={this.title}
                description={this.description}
                launcher={this.state.launcherSrc}
              />
            </>
        }
        <div className='vidaaAdMain'>
          {
            !this.state.showVideo &&
              <>
                {
                  !this.immediateUse.showError &&
                    <div className={'samsungCTAText ' + this.bannerType}>{this.ctaTitle}</div>
                }
                <div className={samsungAdContainerClasses}>
                  {this.immediateUse.showError
                    ? htmlErrorMessage
                    : (
                      <div className='vidaaAd'>
                        <img className='vidaaAdBannerImg removing' ref={this.screenImg} src={this.state.screenSrc} />
                        <div className='loader large' ref={this.loaderScreen} />
                      </div>
                      )}
                </div>
                <div className={samsungAdContainerClasses + '-launcher'}>
                  {
                    !this.immediateUse.showError &&
                      <div className='vidaaAd'>
                        <img className='vidaaAdBannerImg removing' ref={this.launcherImg} src={this.state.launcherSrc} />
                        <div className='loader large' ref={this.loaderLauncher} />
                      </div>
                  }
                </div>
              </>
          }
        </div>
        <div className='remote-text'>
          <span>Use the remote to navigate and interact with the native CTV ad above.</span>
        </div>
      </>
    )
  }
}

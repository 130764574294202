import React, { Component } from 'react'
import { updateBodyClass, consoleLogInfo, checkUrlStatusResult, createImage } from '../util/utils'
// import { Resources } from '../util/resources'
import { References } from '../util/references'
import ErrorBg from '../images/empty-banner-1707x440.png'
import imgDeepLink from '../images/screen-deepLink.png'
import imgApp from '../images/screen-app.png'
import { Resources } from '../util/resources'
import AniviewPlayerOutstream from './AniviewPlayerOutstream'

// const BannerLinks = 'https://pterodactylus.tremorvideodsp.com/clickouts/manifest/2023/11/Vidaa-Banners'
// 3 banners Manifest - https://s.tremorvideodsp.com/clickouts/2023/11/example_vidaa-ad-manifest.json
// 6 banners Manifest - https://s.tremorvideodsp.com/clickouts/2023/11/test_vidaa-ad-manifest-6-banners.json
// Videos, 3 banners -- https://s.tremorvideodsp.com/clickouts/2023/11/videos_vidaa-ad-manifest-3-banners.json

export default class VidaaView extends Component {
  constructor (props) {
    super(props)
    this.setupEvents()
    this.state = {
      showError: false,
      errorMessage: '',
      bannerSrc: ErrorBg,
      currentBannerIndex: 0,
      clickScreen: null,
      showVideo: false,
      childCompPlayerStopped: false
    }
    this.immediateUse = { showError: true }
    this.isBannersValid = false
    this.keyPressed = false
    this.remote = React.createRef()
    this.loader = React.createRef()
    this.bannerImg = React.createRef()
    this.navRefs = Array.from({ length: 6 }, (_, i) => React.createRef())
    this.hoverClasses = ['remotePrevHover', 'remoteOkHover', 'remoteNextHover']
    this.bannerData = null
    this.updateBanners = false
    this.videoURI = ''
  }

  componentDidMount () {
    updateBodyClass.add('adTypeVidaa')
    const waitForRender = callback => setTimeout(() => { callback() }, 0)
    waitForRender(() => { this.addBtnEvents(true) })

    this.setup()
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    const { props } = this
    const willUpdate = !(props.adTagUri === nextProps.adTagUri &&
      this.immediateUse.showError === this.state.showError &&
      this.state.bannerSrc === nextState.bannerSrc &&
      this.state.currentBannerIndex === nextState.currentBannerIndex &&
      this.state.errorMessage === nextState.errorMessage &&
      this.state.clickScreen === nextState.clickScreen &&
      this.state.childCompPlayerStopped === nextState.childCompPlayerStopped &&
      this.state.showVideo === nextState.showVideo)
    this.updateBanners = !this.bannerData || props.adTagUri !== nextProps.adTagUri || this.immediateUse.showError !== this.state.showError
    return willUpdate
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.addBtnEvents(true)
    this.setup()
    this.changeFocus(this.state.currentBannerIndex)
    if (this.props.adTagUri !== prevProps.adTagUri) {
      this.setState(() => {
        return {
          currentBannerIndex: 0,
          clickScreen: null,
          showVideo: false
        }
      })
    }
  }

  componentWillUnmount () {
    updateBodyClass.remove('adTypeVidaa')
    this.addBtnEvents(false)
  }

  setStateNotification (objState) {
    console.log('State Values Being Updated:')
    for (const key in objState) {
      console.log(`${key}: ${objState[key]}`)
    }
    this.setState(objState)
  }

  setErrorState (isError, message) {
    this.immediateUse.showError = isError
    this.setState(() => {
      return {
        showError: isError,
        errorMessage: message,
        clickScreen: null,
        showVideo: false
      }
    })
  }

  setupEvents () {
    this.elRef = {
      showRemote: React.createRef(),
      remoteNext: React.createRef(),
      remoteOk: React.createRef(),
      remotePrev: React.createRef()
    }
    this.btnEvents = {
      showRemote: { ref: this.elRef.showRemote, event: 'click', action: this.showRemote.bind(this) },
      remotePrev: { ref: this.elRef.remotePrev, event: 'click', action: this.navigate.bind(this, -1) },
      remoteNext: { ref: this.elRef.remoteNext, event: 'click', action: this.navigate.bind(this, 1) },
      remoteOk: { ref: this.elRef.remoteOk, event: 'click', action: this.onOkay.bind(this) },
      remotePrevHoverIn: { ref: this.elRef.remotePrev, event: 'mouseenter', action: this.handleMouseEnter.bind(this, 'remotePrevHover') },
      remotePrevHoverOut: { ref: this.elRef.remotePrev, event: 'mouseleave', action: this.handleMouseLeave.bind(this) },
      remotePrevPressed: { ref: this.elRef.remotePrev, event: 'mousedown', action: this.handleKeyPressed.bind(this, 'remotePrevPressed') },
      remoteOkHoverIn: { ref: this.elRef.remoteOk, event: 'mouseenter', action: this.handleMouseEnter.bind(this, 'remoteOkHover') },
      remoteOkHoverOut: { ref: this.elRef.remoteOk, event: 'mouseleave', action: this.handleMouseLeave.bind(this) },
      remoteOkPressed: { ref: this.elRef.remoteOk, event: 'mousedown', action: this.handleKeyPressed.bind(this, 'remoteOkPressed') },
      remoteNextHoverIn: { ref: this.elRef.remoteNext, event: 'mouseenter', action: this.handleMouseEnter.bind(this, 'remoteNextHover') },
      remoteNextHoverOut: { ref: this.elRef.remoteNext, event: 'mouseleave', action: this.handleMouseLeave.bind(this) },
      remoteNextPressed: { ref: this.elRef.remoteNext, event: 'mousedown', action: this.handleKeyPressed.bind(this, 'remoteNextPressed') }
    }
  }

  addBtnEvents (isAddEvent) {
    for (const prop in this.btnEvents) {
      const { ref, event, action } = this.btnEvents[prop]
      if (ref.current) {
        ref.current.removeEventListener(event, action)
        if (isAddEvent) ref.current.addEventListener(event, action)
      }
    }
  }

  handleKeyPressed (clickClass) {
    if (this.keyPressed === false) {
      this.keyPressed = true
      this.remote.current.classList.add(clickClass)
      setTimeout(() => {
        this.remote.current.classList.remove(clickClass)
        this.keyPressed = false
      }, 200)
    }
  }

  handleMouseEnter (hoverClass) {
    this.remote.current.classList.add(hoverClass)
  }

  handleMouseLeave () {
    this.hoverClasses.forEach((className) => {
      this.remote.current.classList.remove(className)
    })
  }

  showRemote () {
    const remoteText = this.elRef.showRemote.current
    const remote = this.remote.current
    const isShown = remote.classList.contains('show')
    if (isShown) {
      remote.classList.remove('show')
      remoteText.innerHTML = 'Show Remote'
    } else {
      remote.classList.add('show')
      remoteText.innerHTML = 'Hide Remote'
    }
  }

  onOkay () {
    if (!this.state.showError && this.props.adTagUri) {
      const index = this.state.currentBannerIndex
      const data = this.bannerData
      if (data && data.banners && data.banners[index]) {
        const currentBanner = data.banners[index]
        console.log('current banner - ', currentBanner)
        const clickAction = currentBanner.clickAction
        const actions = References.paramName.vidaaAd.clickAction
        if (clickAction === actions.openApp) {
          this.setState(() => {
            return { clickScreen: imgApp, showVideo: false }
          })
        } else if (clickAction === actions.deepLink) {
          this.setState(() => {
            return { clickScreen: imgDeepLink, showVideo: false }
          })
        } else if (clickAction === actions.displayVideo) {
          console.log('Display Video')
          // TODO - open video in screen area AX-249
          // Show large Loader
          if (this.state.showVideo === false) {
            this.setState(() => {
              return { showVideo: true }
            })
          } else {
            this.setState(() => {
              return { showVideo: false }
            })
          }
        } else {
          consoleLogInfo(Resources.error.message.vidaaAd.invalidClickAction, 'error')
        }
      }
    }
  }

  changeFocus (nextIndex) {
    this.navRefs.forEach((ref, index) => {
      if (ref.current) {
        ref.current.className = index === nextIndex ? 'active' : ''
      }
    })
  }

  navigate (next) {
    if (!this.state.showError && this.props.adTagUri) {
      const maxIndex = this.navRefs.length - 1
      let nextIndex = 0
      if (next === 1 && this.state.currentBannerIndex === maxIndex) nextIndex = 0
      else if (next === -1 && this.state.currentBannerIndex === 0) nextIndex = maxIndex
      else nextIndex = this.state.currentBannerIndex + next

      this.setState(() => {
        return {
          currentBannerIndex: nextIndex,
          bannerSrc: ErrorBg,
          clickScreen: null,
          showVideo: false
        }
      })
    }
  }

  showLoading (isShow) {
    if (this.loader.current && this.bannerImg.current) {
      if (isShow) {
        this.bannerImg.current.classList.remove('loaded')
        this.bannerImg.current.classList.add('removing')
        this.loader.current.classList.add('active')
      } else {
        this.bannerImg.current.classList.remove('removing')
        this.bannerImg.current.classList.add('loaded')
        this.loader.current.classList.remove('active')
      }
    }
  }

  setup () {
    const { props } = this

    if (props.adTagUri) {
      if (!this.updateBanners && this.bannerData && this.isBannersValid) {
        this.getBannerImg()
        return
      }
      this.isBannersValid = false
      const logTitle = 'VIDAA Ad Log'
      checkUrlStatusResult(`${logTitle} URL Request`, props.adTagUri, async (isError, json) => {
        let message = Resources.error.message.vidaaAd.genericErrorMessage
        if (!isError) {
          await this.validateBannerData(json)
            .then(() => {
              this.isBannersValid = true
              message = ''
              this.getBannerImg()
            })
            .catch((msg) => {
              message = msg
              isError = true
              this.videoURI = ''
              consoleLogInfo(message, 'error')
            })
        }
        if (isError) this.videoURI = ''
        this.setErrorState(isError, message)
      })
    } else {
      this.setErrorState(true, '')
    }
  }

  checkDisplayOrders (banners) {
    const displayOrders = banners.map(banner => banner.displayOrder)
    let message = ''
    let isValid = true

    // Check if all display orders are present and unique
    const uniqueDisplayOrders = new Set(displayOrders.filter(order => order !== '' && order !== null && order !== undefined))
    if (uniqueDisplayOrders.size !== banners.length) {
      message = !message ? 'Manifest Error: ' : ''
      message += 'Display orders are not unique or missing.'
      isValid = false
    }

    // Check display orders include 1 and are consecutive
    for (let i = 1; i <= banners.length; i++) {
      if (!uniqueDisplayOrders.has(i)) {
        message = !message ? 'Manifest Error: ' : `${message} `
        message += `Display order ${i} is missing.`
        isValid = false
      }
    }

    if (!isValid) {
      consoleLogInfo(message, 'info')
      message = Resources.error.message.vidaaAd.invalidDisplayOrders
    }

    return { isValid, message }
  }

  validateBannerData (json) {
    let message = Resources.error.message.vidaaAd.genericErrorMessage
    return new Promise((resolve, reject) => {
      if (json && json.banners) {
        this.bannerData = json

        // verify all banners have valid and unique displayOrders
        const displayOrders = this.checkDisplayOrders(this.bannerData.banners)
        if (!displayOrders.isValid) reject(displayOrders.message)

        const length = this.bannerData.banners.length
        if (!length || !(length === 3 || length === 6)) {
          message = Resources.error.message.vidaaAd.incorrectNumBanners
        } else {
          if (length === 3) {
            const duplicateBanners = [
              { ...this.bannerData.banners[0], displayOrder: 4 },
              { ...this.bannerData.banners[1], displayOrder: 5 },
              { ...this.bannerData.banners[2], displayOrder: 6 }
            ]
            this.bannerData.banners = this.bannerData.banners.concat(duplicateBanners)
          }
          resolve()
        }
      }

      reject(message)
    })

    //
    // const param = {
    //   clickAction: References.paramName.vidaaAd.clickAction,
    //   videoUrl: References.paramName.vidaaAd.videoUrl
    // }
    //
  }

  checkImgSize (img) {
    const expectedWidth = 1707
    const expectedHeight = 440
    const realWidth = img.naturalWidth
    const realHeight = img.naturalHeight
    if (realWidth !== expectedWidth || realHeight !== expectedHeight) {
      console.log(`Banner size: ${realWidth} x ${realHeight}`)
      consoleLogInfo(`Banner is not expected size of  ${expectedWidth} x ${expectedHeight}`, 'warning')
    }
  }

  getBannerImg () {
    if (this.bannerData) {
      if (this.bannerData.banners) {
        this.showLoading(true)

        let bannerSrc = ''
        let resultText = ''
        let messageType = 'error'
        const index = this.state.currentBannerIndex + 1
        const currentBanner = this.bannerData.banners.find(item => item.displayOrder === index)
        const src = currentBanner.banner
        const id = `banner-${currentBanner.displayOrder}`
        this.videoURI = currentBanner.clickAction === 'displayVideo' ? currentBanner.linkUrl : ''

        createImage(src, id)
          .then((img) => {
            bannerSrc = img.src
            resultText = 'Successfully loaded image banner.'
            messageType = 'success'
            this.checkImgSize(img)
          }).catch(error => {
            bannerSrc = ErrorBg
            resultText = `Error loading image banner. ${error}`
          }).finally(() => {
            consoleLogInfo(resultText, messageType)
            this.showLoading(false)
            this.setState(() => {
              return { bannerSrc: bannerSrc }
            })
          })
      }
    }
  }

  render () {
    const htmlErrorMessage = (
      <div className='vidaaAdError'>
        <span className='vidaaAdErrorBg'>
          <img className='vidaaAdErrorImg' src={ErrorBg} />
        </span>
        <span className='vidaaAdErrorTxt'>
          {this.state.errorMessage}
        </span>
      </div>
    )

    const htmlDotNav = (
      <div className='dotNav'>
        <div className='dotNavTable'>
          <div className='dotNavCell'>
            <ul className='dots'>
              {this.navRefs.map((navRef, index) => (
                <li key={index} ref={navRef}>
                  <span>&nbsp;</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )

    const remoteControl = (
      <>
        <div className='vidaaShowHideRemote' ref={this.elRef.showRemote}>Show Remote</div>
        <div className='vidaaRemote' ref={this.remote}>
          <div className='remoteRow two'>
            <div className='remoteBtnPrev' ref={this.elRef.remotePrev} />
            <div className='remoteBtnOkay' ref={this.elRef.remoteOk} />
            <div className='remoteBtnNext' ref={this.elRef.remoteNext} />
          </div>
        </div>
      </>
    )

    return (
      <>
        {remoteControl}
        {this.state.clickScreen &&
          <div className='screenPopup deepLink'>
            <img src={this.state.clickScreen} />
          </div>}
        {this.videoURI && this.state.showVideo &&
          <>
            <div className='closeScreenInstruction'>Press OK on the remote to close the ad</div>
            <AniviewPlayerOutstream
              adTagUri={this.videoURI}
              addLog={this.props.addLog}
              removeLogs={this.props.removeLogs}
              deviceId={this.props.deviceId}
              config={this.props.config}
              url={this.props.url}
              playerOptions={this.props.playerOptions}
              playerId={this.props.playerId}
              isDebug={this.props.isDebug}
              vastProperties={this.props.vastProperties}
              notifyParentState={this.setStateNotification.bind(this)}
            />
          </>}
        <div className='vidaaAdMain'>
          <div className='vidaaAdContainer'>
            {this.immediateUse.showError
              ? htmlErrorMessage
              : (
                <div className='vidaaAd'>
                  {htmlDotNav}
                  <img className='vidaaAdBannerImg removing' ref={this.bannerImg} src={this.state.bannerSrc} />
                  <div className='loader large' ref={this.loader} />
                </div>
                )}
          </div>
        </div>
      </>
    )
  }
}

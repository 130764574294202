import React, { Component } from 'react'
import classNames from 'classnames'
import merge from 'lodash/merge'
import {
  devicesByStreams,
  findByDeviceId,
  streamsTypes,
  deviceOptions,
  getOptGroupByStreamType,
  filterScreenTypesByQuery, validScreenTypeRegex
} from './DeviceOptions'
import Collapse from 'react-bootstrap/Collapse'
import Nav from './components/Nav'
import JWPlayerAdvancedPanel from './components/JWPlayerAdvancedPanel'
import AniviewPlayerOutstream from './components/AniviewPlayerOutstream'
import DisplayAdView from './components/DisplayAdView'
import AniviewPlayer from './components/AniviewPlayer'
import AudioAdView from './components/AudioAdView'
import Footer from './components/Footer'
import DebugPanel from './components/DebugPanel'
import CompanionPanel from './components/CompanionPanel'
import QRCode from './components/QRCode'
import './styles/Devices.scss'
import ClientLinkGenerator from './components/ClientLinkGenerator'
import Modal from 'react-modal'
import { References } from './util/references'
import { VastDataGetter, parseVast } from './util/utils'
import { mediaFileExt, searchClickThroughUrl } from './util/tag-util'
import { compilePath, normalizeDeviceCombinations } from './util/pathmaker'
import VidaaView from './components/VidaaView'
import TCLView from './components/TCL'
import SamsungView from './components/samsung'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root')
class JWPlayerDebug extends Component {
  constructor (props) {
    super(props)
    this.urlParams = props.location.search
    this.adTagUri = this.setTagPlaceholders(props.location.query.adTagUri)
    this.state = {
      logs: [],
      didMount: false,
      showAdvancedPanel: false,
      isModalOpen: false,
      audioAd: {
        isAudio: false,
        mediaFile: '',
        vastResponse: null,
        height: 0
      }
    }
    this.updateState = this.updateState.bind(this)
    this.addLog = this.addLog.bind(this)
    this.removeLogs = this.removeLogs.bind(this)
    this.toggleAdvancedPanel = this.toggleAdvancedPanel.bind(this)
    this.openCloseClientLinkModal = this.openCloseClientLinkModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.vastData = new VastDataGetter()
  }

  updateState (objValue) {
    this.setState(objValue)
  }

  overrideOutstreamScreenType (deviceId) {
    return References.map.screenType.outStream[deviceId] || deviceId
  }

  setTagPlaceholders (tag = '') {
    let deviceId = this.props.match.params.deviceId
    deviceId = this.overrideOutstreamScreenType(deviceId)

    tag = tag
      .replace(/__rmlEnabled__/, 'true')
      .replace(/__screenType__/, deviceId)
    if (/adserver.videohub.tv\/preview/.test(tag)) {
      if (!/(&|\?)rml=/.test(tag)) tag += '&rml=true'
      if (!/(&|\?)screenType=/.test(tag)) {
        tag += `&screenType=${deviceId}`
      } else {
        tag = this.updateTagScreentype(tag, deviceId)
      }
    }
    return tag
  }

  updateTagScreentype (tag = '', deviceId = 'desktop') {
    deviceId = this.overrideOutstreamScreenType(deviceId)
    return tag.replace(/^(.*screenType=)([^&]*)(.*)$/, `$1${deviceId}$3`)
  }

  componentDidUpdate () {
    this.parseAudioVast()
  }

  shouldComponentUpdate (nextProps, nextState) {
    this.urlParams = nextProps.location.search
    // Replaces screenType and rmlEnabled values in the ad tag
    this.adTagUri = this.setTagPlaceholders(nextProps.location.query.adTagUri)
    if (nextProps.match.params.deviceId !== this.props.match.params.deviceId) {
      this.adTagUri = this.updateTagScreentype(this.adTagUri, nextProps.match.params.deviceId)
    }

    if (nextState.audioAd.isAudio !== this.state.audioAd.isAudio || nextState.audioAd.mediaFile !== this.state.audioAd.mediaFile) {
      return true
    }

    // Prevents re-render on log additions when debug mode is off
    const { pathname, search } = nextProps.location
    if (nextState.didMount !== this.state.didMount) {
      return true
    }
    if (nextState.showAdvancedPanel !== this.state.showAdvancedPanel) {
      return true
    }
    if (nextState.isModalOpen !== this.state.isModalOpen) {
      return true
    }
    if (pathname !== this.props.location.pathname || search !== this.props.location.search) {
      return true
    }
    if (nextProps.match.params.workspace !== 'debug') {
      return false
    }
    return true
  }

  toggleAdvancedPanel (evt, forceClosed) {
    this.setState(prevState => {
      return {
        showAdvancedPanel: !forceClosed && !prevState.showAdvancedPanel
      }
    })
  }

  openCloseClientLinkModal (evt, forceClosed) {
    this.setState(prevState => {
      return {
        isModalOpen: !forceClosed && !prevState.isModalOpen
      }
    })
  }

  componentDidMount () {
    const { props } = this
    this.setState(prevState => ({
      didMount: true,
      showAdvancedPanel: !this.adTagUri,
      isModalOpen: false
    }))
    if (this.adTagUri) this.parseAudioVast()
    let filterScreenTypes
    if (props.location.query && (props.location.query.ft || props.location.query.filterScreenTypes)) {
      filterScreenTypes = (props.location.query.ft || props.location.query.filterScreenTypes)
        .split(/\s*,\s*/g)
        .filter(type => validScreenTypeRegex.test(type))
    }
    // change path if current deviceId not listed in filterScreenTypes
    if (filterScreenTypes && filterScreenTypes.length && !filterScreenTypes.includes(props.match.params.deviceId)) {
      const params = Object.assign({}, props.match.params, props.location.query)
      params.deviceId = filterScreenTypes[0]
      const normalizedDevices = normalizeDeviceCombinations(params)
      const pathname = compilePath(props.match.path, Object.assign(params, normalizedDevices))
      props.history.push({
        pathname,
        search: props.location.search
      })
    }
  }

  addLog (type, evName, data = {}) {
    data.timestamp = Date.now() + `_${Math.random().toString(36).slice(2, 6).padEnd(4, '0')}`
    this.setState(prevState => ({
      logs: prevState.logs.concat({ type, evName, data })
    }))
  }

  removeLogs () {
    this.setState({ logs: [] })
  }

  isDebug () {
    return this.props.match.params.workspace === 'debug'
  }

  shouldShowQR (isDisplayAd, isAudio, isOTT) {
    return !!this.adTagUri && (!isDisplayAd && !isAudio && !isOTT) &&
      this.props.match.params.workspace !== 'client' &&
      /mobilephone|mobiletablet/.test(this.props.match.params.deviceId)
  }

  getCurrStreamTypeByDeviceId (deviceId) {
    for (const currStream in devicesByStreams) {
      if (devicesByStreams[currStream].findIndex((item) => item.id === deviceId) !== -1) {
        return currStream
      }
    }
  }

  closeModal () {
    this.openCloseClientLinkModal(null, true)
  }

  resetPath () {
    const { props } = this
    props.history.push({
      pathname: '/',
      search: props.location.search
    })
  }

  resetAudioState () {
    if (this.state.audioAd.isAudio === true) {
      this.setState(() => ({
        audioAd: {
          isAudio: false,
          mediaFile: '',
          vastResponse: null,
          height: 0
        }
      }))
    }
  }

  initAudioPlayer (mediaFile, vastResponse) {
    this.resetPath()
    this.setState(() => ({
      audioAd: {
        isAudio: true,
        mediaFile: mediaFile,
        vastResponse: vastResponse,
        height: 54
      }
    }))
  }

  parseAudioVast () {
    // TODO - currently only gets Linear ads, need to check if should support others such as Non-Linear, CompanionAds, etc.
    if (!this.adTagUri || !!mediaFileExt(this.adTagUri)) {
      this.resetAudioState()
      return
    }
    const onSuccess = (vastResponse) => {
      const isAudio = vastResponse.inline.VAST.Ad['@adType'] === 'audio'
      if (isAudio) {
        const mediaFile = this.vastData.getMediaFile(vastResponse)
        if (mediaFile && (this.state.audioAd.isAudio !== isAudio || this.state.audioAd.mediaFile !== mediaFile)) {
          this.initAudioPlayer(mediaFile, vastResponse)
        }
      } else { this.resetAudioState() }
    }
    const onError = () => {
      this.resetAudioState()
    }
    parseVast(this.adTagUri, onSuccess, onError)
  }

  render () {
    const appClass = classNames('App')
    const { props, state } = this
    const selectedDevice = findByDeviceId(props.match.params.deviceId)
    const selectedDeviceChild = selectedDevice.getChildById(props.match.params.deviceChildId)
    const playerOptions = merge({}, selectedDeviceChild.jwPlayerOptions, props.location.query.playerOptions || {})
    const avPlayerOptions = selectedDeviceChild.avPlayerOptions
    const vastProperties = selectedDeviceChild.vastProperties
    // Only show advanced panel on client mode when no ad tag; show when toggled for other workspaces
    const shouldShowAdvancedPanel = (!this.adTagUri && props.match.params.workspace === 'client') ||
      (props.match.params.workspace !== 'client' && state.showAdvancedPanel)
    const currentStreamType = this.getCurrStreamTypeByDeviceId(props.match.params.deviceId)
    const filterScreenTypes = filterScreenTypesByQuery(props.location.query)
    const optGroupByStreamType = getOptGroupByStreamType(filterScreenTypes)
    const isClientLink = !!this.props.location && !!this.props.location.query && !!this.props.location.query.ft

    const displayAdInfo = props.displayAdInfo
    const isDisplayAd = props.mediaType === 'd'
    if (isDisplayAd && displayAdInfo.url) displayAdInfo.url = decodeURIComponent(displayAdInfo.url)

    const isVidaa = currentStreamType === streamsTypes.Vidaa
    const isTCL = currentStreamType === streamsTypes.tcl

    const isSamsungFirstScreen = currentStreamType === streamsTypes.samsungFirstScreen
    const isSamsungExtraWide = currentStreamType === streamsTypes.samsungExtraWide

    const isOTT = isVidaa || isTCL || isSamsungFirstScreen || isSamsungExtraWide

    const isAudio = state.audioAd.isAudio
    console.log('render @@@@@ state.audioAd.isAudio @@@@@ - ', isAudio)

    return (
      <div className={appClass}>

        {!isDisplayAd && !isAudio &&
          <div className='row no-gutters'>
            <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
              <Nav
                history={props.history}
                location={props.location}
                match={props.match}
                streamType={props.streamType}
                deviceId={props.match.params.deviceId}
                toggleAdvancedPanel={this.toggleAdvancedPanel}
                toggleClientLinkModal={this.openCloseClientLinkModal}
                showingAdvancedPanel={state.showAdvancedPanel}
                showingClientLinkModal={state.isModalOpen}
                optGroupByStreamType={optGroupByStreamType.optionsHtml}
                ref='nav' // eslint-disable-line react/no-string-refs
              />
            </div>
          </div>}

        {!isDisplayAd && !isAudio &&
          <div className='row no-gutters'>
            <div className='jw-advanced-panel col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
              <Collapse in={shouldShowAdvancedPanel}>
                <div>
                  <JWPlayerAdvancedPanel
                    history={props.history}
                    location={props.location}
                    match={props.match}
                    adTagUri={this.adTagUri}
                    deviceId={props.match.params.deviceId}
                    playerOptions={playerOptions}
                    toggleAdvancedPanel={this.toggleAdvancedPanel}
                    branding={props.branding}
                  />
                </div>
              </Collapse>
            </div>
          </div>}

        <div className='main-body row no-gutters'>
          <DebugPanel workspace={props.match.params.workspace} logs={state.logs} adTagUri={this.adTagUri} isClientLink={isClientLink} />
          <div className='no-gutters col-xs-12 col-md' id='panel-main'>
            <div
              className={classNames(`device-container wrapper-${props.match.params.deviceId}-${props.match.params.deviceChildId}`)}
            >
              <div
                className={classNames(`device ${props.match.params.deviceId}-${props.match.params.deviceChildId}`, { debug: this.isDebug() })}
              >
                {
                  state.didMount && !isDisplayAd && !isAudio && isVidaa &&
                    <VidaaView
                      adTagUri={this.adTagUri}
                      addLog={this.addLog}
                      removeLogs={this.removeLogs}
                      deviceId={props.match.params.deviceId}
                      config={props.location.config}
                      url={searchClickThroughUrl(props.location)}
                      playerOptions={avPlayerOptions}
                      playerId='tesgreenayted3'
                      isDebug={this.isDebug()}
                      vastProperties={vastProperties}
                    />
                }
                {
                  state.didMount && !isDisplayAd && !isAudio && isTCL &&
                    <TCLView
                      adTagUri={this.adTagUri}
                      addLog={this.addLog}
                      removeLogs={this.removeLogs}
                      deviceId={props.match.params.deviceId}
                      config={props.location.config}
                      url={searchClickThroughUrl(props.location)}
                      playerOptions={avPlayerOptions}
                      playerId='tesgreenayted3'
                      isDebug={this.isDebug()}
                      vastProperties={vastProperties}
                    />
                }
                {
                  state.didMount && !isDisplayAd && !isAudio && (isSamsungFirstScreen || isSamsungExtraWide) &&
                    <SamsungView
                      adTagUri={this.adTagUri}
                      addLog={this.addLog}
                      removeLogs={this.removeLogs}
                      deviceId={props.match.params.deviceId}
                      config={props.location.config}
                      url={searchClickThroughUrl(props.location)}
                      playerOptions={avPlayerOptions}
                      playerId='tesgreenayted3'
                      isDebug={this.isDebug()}
                      vastProperties={vastProperties}
                    />
                }
                {
                  state.didMount && isDisplayAd && !isAudio && !isOTT &&
                    <DisplayAdView
                      urlParams={this.urlParams}
                      config={displayAdInfo}
                    />
                }
                {
                  state.didMount && !isDisplayAd && isAudio && !isOTT &&
                    <AudioAdView
                      audioData={state.audioAd}
                      adTagUri={this.adTagUri}
                      setParentState={this.updateState}
                    />
                }
                {
                  state.didMount && currentStreamType === streamsTypes.Outstream && !isDisplayAd && !isAudio && !isOTT &&
                    <AniviewPlayerOutstream
                      adTagUri={this.adTagUri}
                      addLog={this.addLog}
                      removeLogs={this.removeLogs}
                      deviceId={props.match.params.deviceId}
                      config={props.location.config}
                      url={searchClickThroughUrl(props.location)}
                      playerOptions={avPlayerOptions}
                      playerId='tesgreenayted3'
                      isDebug={this.isDebug()}
                      vastProperties={vastProperties}
                    />
                }
                {// Wrap players in spans or React with throw error when trying to remove them to swith between players
                  state.didMount && props.match.params.player === References.player.aniview.inStream.id && currentStreamType !== streamsTypes.Outstream && !isDisplayAd && !isAudio && !isOTT &&
                    <AniviewPlayer
                      adTagUri={this.adTagUri}
                      addLog={this.addLog}
                      removeLogs={this.removeLogs}
                      deviceId={props.match.params.deviceId}
                      config={props.location.config}
                      url={searchClickThroughUrl(props.location)}
                      playerOptions={avPlayerOptions}
                      playerId='tesgreenayted3'
                      isDebug={this.isDebug()}
                    />
                }
              </div>
              {
                props.match.params.deviceId === 'desktop' && !isDisplayAd && !isAudio && !isOTT &&
                  <div className='wireframe-bottom'>
                    <div className='wireframe' style={{ height: 63, width: '100%', marginTop: 89 }} />
                    <div className='wireframe' style={{ height: 21.5, width: '40%', marginTop: 9 }} />
                    {new Array(13).fill('0').map((el, index) =>
                      <div
                        key={`${Date.now()}_${index}`}
                        className='wireframe'
                        style={{ height: 21.5, width: '100%', marginTop: 9 }}
                      />
                    )}
                  </div>
              }
            </div>
            {this.shouldShowQR(isDisplayAd, isAudio, isOTT) && <QRCode adTagUri={this.adTagUri} />}
          </div>
          {
            props.match.params.deviceId === 'desktop' && !isDisplayAd && !isAudio && !isOTT &&
              <div className='row no-gutters companion-panel-container'>
                <div className='col-md fake-paragraph-content'>
                  <div className='wireframe' style={{ height: 63, width: '100%', marginTop: 0 }} />
                  <div className='wireframe' style={{ height: 21.5, width: '40%', marginTop: 9 }} />
                  {new Array(13).fill('0').map((el, index) =>
                    <div
                      key={`${Date.now()}_${index}`}
                      className='wireframe'
                      style={{ height: 21.5, width: '100%', marginTop: 9 }}
                    />
                  )}
                </div>
                <CompanionPanel />
              </div>
          }
          {!isDisplayAd && !isAudio && (
            /* eslint-disable react/jsx-handler-names */
            <Modal
              isOpen={state.isModalOpen}
              onRequestClose={this.closeModal}
              className='Modal'
              contentLabel='Client Link'
              overlayClassName='Overlay'
            >
              <div className='modal-content'>
                <span className='close' onClick={this.closeModal}>&times;</span>
                <ClientLinkGenerator
                  deviceOptions={deviceOptions}
                  filteredOptions={optGroupByStreamType.flaggedDeviceOptions}
                />
              </div>
            </Modal>
            /* eslint-disable react/jsx-handler-names */
          )}
        </div>
        <Footer match={props.match} branding={props.branding} />
      </div> // Main Component Close
    )
  }
}

export default JWPlayerDebug

// const defaultPlayerOptions = {
//   autostart: true,
//   aspectratio: '16:9',
//   controls: false,
//   advertising: {
//     client: 'vast',
//     // client: 'googima',
//     admessage: 'Ad — xxs left',
//     companiondiv: {
//       id: 'companion-300-250',
//       width: 300,
//       height: 250
//     }
//   }
// }

import React, { Component } from 'react'
import merge from 'lodash/merge'
import { updateBodyClass, onResize, consoleLogInfo } from '../util/utils'

export default class AudioAdView extends Component {
  setup (nextProps) {
    const props = merge({}, nextProps || this.props)
    console.log('AudioAdView setup props - ', props)
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    const { props } = this
    const infoChanged = !(props.adTagUri === nextProps.adTagUri && props.audioData.mediaFile === nextProps.audioData.mediaFile)
    // if (infoChanged) this.resetPlayer()
    const willUpdate = infoChanged
    return willUpdate
  }

  resetPlayer () {
    const { props } = this
    props.setParentState({
      audioAd: {
        isAudio: false,
        mediaFile: '',
        vastResponse: null,
        height: 0
      }
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.audioRef.load()
  }

  componentWillUnmount () {
    this.resetPlayer()
    onResize(this.setFooterToBottom, true)
    updateBodyClass.remove('adTypeAudio')
  }

  setFooterToBottom (adData) {
    let resizeTimout = null

    const updatePos = (adData) => {
      let footerMarginTop = 0
      const browserHeight = window.innerHeight
      const adErrorHeight = 50
      const adHeight = parseInt(adData.height, 10) || adErrorHeight
      const adMarginTop = 131
      const footer = document.querySelector('.footer')
      const footerMinHeight = 140
      let footerHeight = footerMinHeight
      if (footer) footerHeight = footer.offsetHeight < footerMinHeight ? footerMinHeight : footer.offsetHeight
      const contentHeight = adMarginTop + adHeight + footerHeight
      if (contentHeight < browserHeight) {
        footerMarginTop = browserHeight - contentHeight
      }
      if (footer) footer.style.marginTop = footerMarginTop + 'px'
    }

    updatePos(adData)
    return () => {
      clearTimeout(resizeTimout)
      resizeTimout = setTimeout(() => updatePos(adData), 200)
    }
  }

  render () {
    const { props } = this
    const audioData = props.audioData

    consoleLogInfo('Audio Ad Log Data - ', 'info', audioData)
    updateBodyClass.add('adTypeAudio')
    this.setFooterToBottom(audioData)()
    onResize(this.setFooterToBottom(audioData))

    // this.setup()

    return (
      <div className='audioAdMain'>
        <div className='audioAdContainer'>
          <audio ref={input => { this.audioRef = input }} controls autoPlay controlsList='nodownload'>
            <source src={audioData.mediaFile} />
          </audio>
        </div>
      </div>
    )
  }
}

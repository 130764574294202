import React, { Component } from 'react'

export default class UnMissableDesktop extends Component {
  componentDidMount () {
    // testing
  }

  render () {
    return (
      <div>
        <div className='wireframe fake-companion-content' key={0} style={{ height: 20, width: '20%', marginTop: 20 }} />
        <div className='wireframe fake-companion-content' key={1} style={{ height: 85, width: '100%', marginTop: 10 }} />
        {new Array(50).fill('0').map((el, index) => {
          return (
            <div key={`${Date.now()}__${index}`} className={`native-ad${index}`}>
              <div key={`${Date.now()}_${index}`} className='wireframe' style={{ minHeight: '20px', width: '100%', marginTop: 9 }} />
            </div>
          )
        }
        )}
        <div className='native-ad50' id={this.props.id} ref={this.props.container} key={`${Date.now()}__50`} />
      </div>
    )
  }
}

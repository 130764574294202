import {
  aspectRatios,
  defaultPlayerOptions,
  desktop, deviceUrlTags, equivalentAspectRatio, equivalentDeviceUrlTags,
  findByDeviceId,
  mobilePhone,
  mobileTablet,
  streamsTypes
} from '../DeviceOptions'
import qs from 'qs'
import filterObject from './filter-object'
import removeDefaultsDeep from './remove-defaults-deep'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { compile } from 'path-to-regexp'

export default function (opts) {
  let { deviceChildId, deviceId, workspace, adTagUri, playerOptions, player } = opts
  player = player || 'aniviewplayer'
  const selectedDevice = findByDeviceId(deviceId)
  const selectedDeviceChild = selectedDevice.getChildById(deviceChildId)
  const search = qs.stringify({ adTagUri, playerOptions })
  return ['', selectedDevice.id, selectedDeviceChild.id, player, workspace].join('/') + '?' + search
}

export function getAspectRatioShortcut (deviceId, aspectRatio, currentAspectRatios) {
  const newAspectRatios = {}
  const deviceUrlTag = deviceUrlTags[deviceId]
  const equivalentDeviceUrlTag = equivalentDeviceUrlTags[deviceId]

  if (deviceUrlTag && equivalentDeviceUrlTag) {
    newAspectRatios[deviceUrlTag] = aspectRatios[aspectRatio].shortcut
    newAspectRatios[equivalentDeviceUrlTag] = currentAspectRatios[equivalentDeviceUrlTag] || 'def'
  }

  return newAspectRatios
}

export function getAspectRatioIdByShortcut (shortcut) {
  if (shortcut || shortcut !== 'def') {
    for (const prop in aspectRatios) {
      if (aspectRatios[prop].shortcut === shortcut) {
        return aspectRatios[prop].id
      }
    }
  }
  return null
}

export function getEquivalentAspectRatio (deviceArShortcut) {
  const deviceAspectRatioId = getAspectRatioIdByShortcut(deviceArShortcut)
  return equivalentAspectRatio[deviceAspectRatioId] || null
}

export function getDeviceChildByParams (deviceId, mob, tab) {
  const aspectRatioShortcuts = { mob: mob, tab: tab }

  const deviceAspectRatioShortcut = aspectRatioShortcuts[deviceUrlTags[deviceId]]
  let deviceChildId = getAspectRatioIdByShortcut(deviceAspectRatioShortcut)

  if (!deviceChildId) {
    const equivalentDeviceArShortcut = aspectRatioShortcuts[equivalentDeviceUrlTags[deviceId]]
    deviceChildId = getEquivalentAspectRatio(equivalentDeviceArShortcut)
  }

  return deviceChildId
}

export function getDeviceChildId (deviceId, selectedDevice, selectedDeviceChild, mob, tab) {
  const defaultDeviceChild = selectedDeviceChild != null ? selectedDeviceChild.id : selectedDevice.children[0].id
  let deviceChildId = null
  if (deviceId === mobilePhone.id || deviceId === mobileTablet.id) { deviceChildId = getDeviceChildByParams(deviceId, mob, tab) }
  return deviceChildId || defaultDeviceChild
}

export function normalizeDeviceCombinations ({ deviceId, deviceChildId, mob = null, tab = null }) {
  deviceId = deviceId || desktop.id
  const selectedDevice = findByDeviceId(deviceId)
  deviceId = selectedDevice.id
  const selectedDeviceChild = selectedDevice.getChildById(deviceChildId)
  deviceChildId = getDeviceChildId(deviceId, selectedDevice, selectedDeviceChild, mob, tab)
  return { deviceId, deviceChildId, streamType: selectedDevice.streamType }
}

/**
 * With routerPath '/:deviceId/:deviceChildId/aniviewplayer/:workspace'
 * ...and options {deviceId: 'hello'}
 *
 * You will get '/hello/undefined/aniviewplayer/undefined'
 *
 * @param  {string} routerPath  Valid router path such as '/user/:id' or '/:deviceId/:deviceChildId/aniviewplayer/:workspace'
 * @param  {object} params      To slot into the router's path
 * @return {string}             Actual path
 */
export function compilePath (routerPath, {
  deviceId,
  deviceChildId,
  player = 'aniviewplayer',
  workspace = 'basic',
  streamType
}) {
  let resPath = ''

  if (streamType === streamsTypes.InStream) {
    if (player === 'aniviewplayeroutstream') {
      player = 'aniviewplayer'
    }
    resPath = handleInstreamPath(routerPath, { deviceId, deviceChildId, player, workspace })
  } else if (streamType === streamsTypes.Outstream) {
    player = 'aniviewplayeroutstream'
    resPath = handleOutStreamPath(routerPath, { deviceId, deviceChildId, player, workspace })
  } else if (streamType === streamsTypes.Vidaa) {
    player = 'vidaa'
    resPath = handleVidaaPath(routerPath, { deviceId, deviceChildId, player, workspace })
  } else if (streamType === streamsTypes.tcl) {
    player = 'tcl'
    resPath = handleTCLPath(routerPath, { deviceId, deviceChildId, player, workspace })
  } else if (streamType === streamsTypes.samsung) {
    player = 'samsung'
    resPath = handleSamsungPath(routerPath, { deviceId, deviceChildId, player, workspace })
  }
  return resPath
}

const handleInstreamPath = (routerPath, { deviceId, deviceChildId, player = 'aniviewplayer', workspace = 'basic' }) => {
  if (deviceId === 'desktop' && deviceChildId === 'default' && player === 'aniviewplayer' && workspace === 'basic') return '/desktop'
  if (deviceId === 'ctv' && deviceChildId === 'default' && player === 'aniviewplayer' && workspace === 'basic') return '/ctv'
  if (deviceId === 'mobilephone' && deviceChildId === 'smartphone-16-9' && player === 'aniviewplayer' && workspace === 'basic') return '/mobilephone'
  if (deviceId === 'mobiletablet' && deviceChildId === 'tablet-4-3' && player === 'aniviewplayer' && workspace === 'basic') return '/mobiletablet'
  return compile(routerPath)({ deviceId, deviceChildId, player, workspace })
}

const handleOutStreamPath = (routerPath, {
  deviceId,
  deviceChildId = 'default',
  player = 'aniviewplayer',
  workspace = 'basic'
}) => {
  if (workspace === 'basic') {
    return `/${deviceId}`
  }

  return compile(routerPath)({ deviceId, deviceChildId, player, workspace })
}

const handleVidaaPath = (routerPath, {
  deviceId,
  deviceChildId = 'default',
  player = 'vidaa',
  workspace = 'basic'
}) => {
  if (workspace === 'basic') {
    return `/${deviceId}`
  }

  return compile(routerPath)({ deviceId, deviceChildId, player, workspace })
}

const handleTCLPath = (routerPath, {
  deviceId,
  deviceChildId = 'default',
  player = 'tcl',
  workspace = 'basic'
}) => {
  if (workspace === 'basic') {
    return `/${deviceId}`
  }

  return compile(routerPath)({ deviceId, deviceChildId, player, workspace })
}

const handleSamsungPath = (routerPath, {
  deviceId,
  deviceChildId = 'default',
  player = 'samsung',
  workspace = 'basic'
}) => {
  if (workspace === 'basic') {
    return `/${deviceId}`
  }

  return compile(routerPath)({ deviceId, deviceChildId, player, workspace })
}

export function compileSearch (search, nextSearch = {}) {
  const output = {}
  if (typeof search === 'string') {
    search = parse(search)
  }
  nextSearch.playerOptions = compilePlayerOptions(search.playerOptions, nextSearch.playerOptions)
  merge(output, search, nextSearch)
  return stringify(output)
}

const compilePlayerOptions = (playerOpts = {}, nextPlayerOpts = {}) => {
  const currentSearchPlayerOpts = filterObject(nextPlayerOpts, Object.keys(playerOpts))
  const nonDefaultPlayerOpts = removeDefaultsDeep(nextPlayerOpts, defaultPlayerOptions)
  return merge({}, currentSearchPlayerOpts, nonDefaultPlayerOpts)
}

/**
 * [stringify description]
 * @param  {[type]} incomingObj    [description]
 * @param  {[type]} srcObj source object to apply on
 * @return {[type]}        [description]
 */
export function stringify (incomingObj = {}, srcObj = {}) {
  let output = {}
  merge(output, cloneDeep(srcObj), cloneDeep(incomingObj))
  output = clean(output)
  return qs.stringify(output, {
    addQueryPrefix: true,
    allowDots: true
  })
}

/**
 * Clean out unnecessary parameters to make stringify shorter
 * @param  {[type]} os [description]
 * @return {[type]}    [description]
 */
export function clean (os = {}) {
  const output = Object.assign({}, { playerOptions: {} }, os)
  const { playerOptions } = output
  if (playerOptions) {
    playerOptions.aspectratio = playerOptions.id = playerOptions.file = undefined
    playerOptions.advertising = filterObject(playerOptions.advertising, ['client'])
  }
  return output
}

export function parse (searchStr) {
  const parsed = qs.parse(searchStr, {
    depth: 3,
    ignoreQueryPrefix: true,
    allowDots: true
  })
  if (parsed.playerOptions) {
    if (parsed.playerOptions.controls) {
      parsed.playerOptions.controls = parsed.playerOptions.controls !== 'false'
    }
    if (parsed.playerOptions.advertising) {
      const advertising = parsed.playerOptions.advertising
      Object.keys(advertising).forEach(k => {
        if (advertising[k] === 'true') {
          advertising[k] = true
        } else if (advertising[k] === 'false') {
          advertising[k] = false
        }
      })
    }
  }
  return parsed
}

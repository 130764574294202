import { References } from './references'
import { encodeUrL } from './tag-util'

export default class Config {
  constructor () {
    this.config = this.getConfig()
    return this.config
  }

  getConfig () {
    return {
      branding: {
        brand: References.branding.Tremor, // change default for Dev
        get () {
          return this.branding.brand
        },
        set (brand) {
          if (References.branding[brand]) {
            this.branding.brand = brand
          }
        }
      },
      env: {
        get (hostname) {
          let env = ''
          if (References.host.devUrl.includes(hostname)) {
            env = References.host.env.dev
          } else {
            env = References.env[hostname] || References.host.env.prod
          }
          return env
        }
      },
      host: {
        hostUrl: '',
        nodeUrl: '',
        env: {
          prod () { return `${References.host.protocol}${References.host.branding[this.branding.brand].prodUrl}` },
          release () { return `${References.host.protocol}${References.host.branding[this.branding.brand].releaseUrl}` },
          test () { return `${References.host.protocol}${References.host.branding[this.branding.brand].testUrl}` },
          dev () { return `${References.host.protocol}${window.location.hostname}` }
        },
        nodeEnv: {
          prod () { return `${References.host.protocol}${References.host.branding[this.branding.brand].nodeProdUrl}` },
          release () { return `${References.host.protocol}${References.host.branding[this.branding.brand].nodeProdUrl}` },
          test () { return `${References.host.protocol}${References.host.branding[this.branding.brand].nodeTestUrl}` },
          dev () { return `${References.host.protocol}${References.host.nodeDevUrl}` }
        },
        get () {
          return { hostUrl: this.host.hostUrl, nodeUrl: this.host.nodeUrl }
        },
        set (env) {
          if (References.host.env[env]) {
            this.host.hostUrl = this.host.env[env].call(this)
            this.host.nodeUrl = this.host.nodeEnv[env].call(this)
          }
        }
      },
      api: {
        getVastTemplate (mediaFileType, mediaFileUrl, clickThroughUrl) {
          if (clickThroughUrl != null) {
            return `${this.host.nodeUrl}/vast?mediaFileType=${mediaFileType}&mediaFileLink=${mediaFileUrl}&clickThroughUrl=${encodeUrL(clickThroughUrl)}`
          } else {
            return `${this.host.nodeUrl}/vast?mediaFileType=${mediaFileType}&mediaFileLink=${mediaFileUrl}`
          }
        },
        vastWrapper (uri, playerOptions) {
          const queryParams = Object.entries(playerOptions)
            .map(([key, value]) => `${key}=${value}`)
            .filter(Boolean) // Filter out empty entries
          const params = queryParams.length > 0 ? `&${queryParams.join('&')}` : ''
          return `${this.host.nodeUrl}/vastWrapper?uri=${uri}${params}`
        },
        addVastAdParameters (uri, param, value) {
          return `${References.endpoint.branding[this.branding.brand].addVastAdParameters}/${encodeUrL(uri)}?${param}=${value}`
        }
      }
    }
  }
}

import React from 'react'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { flagArrayItemsByKey, isEmpty } from './util/utils'

// check this variable
export const validScreenTypeRegex = /^(desktop|mobilephone|mobiletablet|mobileWeb|mobileApp|tabletWeb|tabletApp|ctv|u6hero|tcl|samsungExtraWide|samsungFirstScreen|easter|d|m|t|c|iad|iam|iat|ud|um|ut|vt|vm|u6)$/

export const validAspectRatioRegex = /^(v|h|v1|v2|h1|h2)$/

export const streamsTypes = {
  Outstream: 'Out-Stream',
  InStream: 'In-Stream',
  Vidaa: 'VIDAA',
  tcl: 'TCL',
  samsungExtraWide: 'Samsung Extra Wide',
  samsungFirstScreen: 'Samsung First Screen'

}

// TODO start - When AX-661 is complete an vPlatforms end
// (vPlatfrom to send new params in url to filter by screen type)
// mobileOld and tabletOld in group below can be removed
// as well as mobilephone|mobiletablet in validScreenTypeRegex above
// TODO end
const group = {
  desktop: 'desktop',
  mobileOld: 'mobilephone',
  tabletOld: 'mobiletablet',
  mobileWeb: 'mobileWeb',
  mobileApp: 'mobileApp',
  tabletWeb: 'tabletWeb',
  tabletApp: 'tabletApp',
  ctv: 'ctv',
  ott: 'ott'
}

export const defaultPlayerOptions = {
  autostart: 'true',
  aspectratio: '16:9',
  preload: 'auto',
  controls: true,
  advertising: {
    client: 'vast',
    // client: 'googima',
    admessage: 'Ad — xxs left'
  },
  file: 'https://s.tremorvideodsp.com/v/2024/09/S1Lv4eyTC/1080p.mp4'
}
export const desktop = {
  /**
   * Display name
   * @type {String}
   */
  name: 'In-Stream Desktop',
  // change it to dropdownName
  dropDownDisplay: 'Desktop',
  /**
   * id must be in lowercase, no space, no underscore, no dash
   * @type {String}
   */
  id: 'desktop',
  group: [group.desktop],
  // clientUrlId
  clientId: 'd',
  // change to clientLinkOrderInLine
  clientLinkOrder: 1,
  streamType: streamsTypes.InStream,
  children: [
    {
      id: 'default',
      name: 'Default',
      jwPlayerOptions: {
        aspectratio: '16:9'
      },
      avPlayerOptions: {
        aspectRatio: '16:9'
      }
    }
  ]
}
export const mobilePhone = {
  name: `${streamsTypes.InStream} Mobile`,
  dropDownDisplay: 'Mobile',
  id: 'mobilephone',
  group: [group.mobileOld, group.mobileApp, group.mobileWeb],
  clientId: 'm',
  urlTag: 'mob',
  clientLinkOrder: 2,
  streamType: streamsTypes.InStream,
  children: [
    {
      id: 'smartphone-16-9',
      name: 'Smartphone 16:9',
      jwPlayerOptions: {
        aspectratio: '16:9'
      },
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      shortcut: 'h'
    },
    {
      id: 'smartphone-9-16',
      name: 'Smartphone 9:16',
      jwPlayerOptions: {
        aspectratio: '9:16'
      },
      avPlayerOptions: {
        aspectRatio: '9:16'
      },
      shortcut: 'v'
    }
  ]
}
export const mobileTablet = {
  name: `${streamsTypes.InStream} Tablet`,
  dropDownDisplay: 'Tablet',
  id: 'mobiletablet',
  group: [group.tabletOld, group.tabletApp, group.tabletWeb],
  clientId: 't',
  urlTag: 'tab',
  clientLinkOrder: 3,
  streamType: streamsTypes.InStream,
  children: [
    {
      id: 'tablet-4-3',
      name: 'Tablet 4:3',
      jwPlayerOptions: {
        aspectratio: '4:3'
      },
      avPlayerOptions: {
        aspectRatio: '4:3'
      },
      shortcut: 'h1'
    },
    {
      id: 'tablet-16-10',
      name: 'Tablet 16:10',
      jwPlayerOptions: {
        aspectratio: '16:10'
      },
      avPlayerOptions: {
        aspectRatio: '16:10'
      },
      shortcut: 'h2'
    },
    {
      id: 'tablet-3-4',
      name: 'Tablet 3:4',
      jwPlayerOptions: {
        aspectratio: '3:4'
      },
      avPlayerOptions: {
        aspectRatio: '3:4'
      },
      shortcut: 'v1'
    },
    {
      id: 'tablet-10-16',
      name: 'Tablet 10:16',
      jwPlayerOptions: {
        aspectratio: '10:16'
      },
      avPlayerOptions: {
        aspectRatio: '10:16'
      },
      shortcut: 'v2'
    }
  ]
}
export const ctv = {
  name: `${streamsTypes.InStream} CTV`,
  dropDownDisplay: 'CTV',
  id: 'ctv',
  group: [group.ctv],
  clientId: 'c',
  clientLinkOrder: 4,
  streamType: streamsTypes.InStream,
  children: [
    {
      id: 'default',
      name: 'Default',
      jwPlayerOptions: {
        aspectratio: '16:9'
      },
      avPlayerOptions: {
        aspectRatio: '16:9'
      }
    }
  ]
}
export const inArticleDesktop = {
  name: 'In-Article Desktop',
  dropDownDisplay: 'In-Article Desktop',
  id: 'inarticledesktop',
  group: [group.desktop],
  clientId: 'iad',
  clientLinkOrder: 1,
  streamType: streamsTypes.Outstream,
  children: [
    {
      id: 'default',
      name: 'default',
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: false,
        pinned: false,
        removeCloseButton: true
      }
    }
  ]
}
export const inArticleMobilePhone = {
  name: 'In-Article Mobile',
  dropDownDisplay: 'In-Article Mobile',
  id: 'inarticlemobilephone',
  group: [group.mobileWeb],
  clientId: 'iam',
  clientLinkOrder: 2,
  streamType: streamsTypes.Outstream,
  children: [
    {
      id: 'smartphone-9-16',
      name: 'default',
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: false,
        pinned: false,
        removeCloseButton: true
      }
    }
  ]
}
export const inArticleMobileTablet = {
  name: 'In-Article Tablet',
  dropDownDisplay: 'In-Article Tablet',
  id: 'inarticlemobiletablet',
  group: [group.tabletWeb],
  clientId: 'iat',
  clientLinkOrder: 3,
  streamType: streamsTypes.Outstream,
  children: [
    {
      id: 'tablet',
      name: 'default',
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: false,
        pinned: false,
        removeCloseButton: true
      }
    }
  ]
}
export const unMissableDesktop = {
  name: 'UNmissable Desktop',
  dropDownDisplay: 'UNmissable Desktop',
  id: 'unmissabledesktop',
  group: [group.desktop],
  clientId: 'ud',
  clientLinkOrder: 4,
  streamType: streamsTypes.Outstream,
  children: [
    {
      id: 'Desktop',
      name: 'default',
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: false,
        pinned: true,
        removeCloseButton: false
      }
    }
  ]
}
export const unMissablePhone = {
  name: 'UNmissable Mobile',
  dropDownDisplay: 'UNmissable Mobile',
  id: 'unmissablephone',
  group: [group.mobileWeb],
  clientId: 'um',
  clientLinkOrder: 1,
  streamType: streamsTypes.Outstream,
  children: [
    {
      id: 'smartphone-9-16',
      name: 'default',
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: false,
        pinned: true,
        removeCloseButton: false
      }
    }
  ]
}
export const unMissableMobileTablet = {
  name: 'UNmissable Tablet',
  dropDownDisplay: 'UNmissable Tablet',
  id: 'unmissablemobiletablet',
  group: [group.tabletWeb],
  clientId: 'ut',
  clientLinkOrder: 2,
  streamType: streamsTypes.Outstream,
  children: [
    {
      id: 'tablet',
      name: 'default',
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: false,
        pinned: true,
        removeCloseButton: false
      }
    }
  ]
}
export const verticalMobilePhone = {
  name: 'Vertical Video Mobile',
  dropDownDisplay: 'Vertical Video Mobile',
  id: 'verticalmobilephone',
  group: [group.mobileWeb],
  clientId: 'vm',
  clientLinkOrder: 3,
  streamType: streamsTypes.Outstream,
  children: [
    {
      id: 'smartphone-9-16',
      name: 'default',
      avPlayerOptions: {
        aspectRatio: '9:16'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: true,
        pinned: false,
        removeCloseButton: true
      }
    }
  ]
}
export const verticalMobileTablet = {
  name: 'Vertical Video Tablet',
  dropDownDisplay: 'Vertical Video Tablet',
  id: 'verticalmobiletablet',
  clientId: 'vt',
  clientLinkOrder: 3,
  streamType: streamsTypes.Outstream,
  children: [
    {
      id: 'tablet',
      name: 'default',
      avPlayerOptions: {
        aspectRatio: '9:16'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: true,
        pinned: false,
        removeCloseButton: true
      }
    }
  ]
}

export const u6Hero = {
  name: `${streamsTypes.Vidaa} U6 HERO`,
  dropDownDisplay: 'U6 HERO',
  id: 'u6hero',
  group: [group.ott],
  clientId: 'u6',
  clientLinkOrder: -1,
  streamType: streamsTypes.Vidaa,
  children: [
    {
      id: 'default',
      name: 'Default',
      jwPlayerOptions: {
        aspectratio: '16:9'
      },
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: false,
        pinned: false,
        removeCloseButton: true
      }
    }
  ]
}

export const tcl = {
  name: streamsTypes.tcl, /* Client Link Generator button name comes from here */
  dropDownDisplay: streamsTypes.tcl, /* <option> name comes from here */
  id: 'tcl',
  group: [group.ott],
  clientId: 'tcl',
  clientLinkOrder: -1,
  streamType: streamsTypes.tcl, /* <optgroup> label comes from here */
  children: [
    {
      id: 'default',
      name: 'Default',
      jwPlayerOptions: {
        aspectratio: '16:9'
      },
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: false,
        pinned: false,
        removeCloseButton: true
      }
    }
  ]
}

export const samsungExtraWide = {
  name: streamsTypes.samsungExtraWide, /* Client Link Generator button name comes from here */
  dropDownDisplay: streamsTypes.samsungExtraWide, /* <option> name comes from here */
  id: 'samsungExtraWide',
  group: [group.ott],
  clientId: 'samsungExtraWide',
  clientLinkOrder: -1,
  streamType: streamsTypes.samsungExtraWide, /* <optgroup> label comes from here */
  children: [
    {
      id: 'default',
      name: 'Default',
      jwPlayerOptions: {
        aspectratio: '16:9'
      },
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: false,
        pinned: false,
        removeCloseButton: true
      }
    }
  ]
}

export const samsungFirstScreen = {
  name: streamsTypes.samsungFirstScreen, /* Client Link Generator button name comes from here */
  dropDownDisplay: streamsTypes.samsungFirstScreen, /* <option> name comes from here */
  id: 'samsungFirstScreen',
  group: [group.samsung],
  clientId: 'samsungFirstScreen',
  clientLinkOrder: -1,
  streamType: streamsTypes.samsungFirstScreen, /* <optgroup> label comes from here */
  children: [
    {
      id: 'default',
      name: 'Default',
      jwPlayerOptions: {
        aspectratio: '16:9'
      },
      avPlayerOptions: {
        aspectRatio: '16:9'
      },
      vastProperties: {
        playThrough: true,
        verticalVideo: false,
        pinned: false,
        removeCloseButton: true
      }
    }
  ]
}

export const aspectRatios = {}
mobilePhone.children.forEach(child => { aspectRatios[child.id] = child; return true })
mobileTablet.children.forEach(child => { aspectRatios[child.id] = child; return true })

export const deviceUrlTags = { mobilephone: mobilePhone.urlTag, mobiletablet: mobileTablet.urlTag }
export const equivalentDeviceUrlTags = { mobilephone: mobileTablet.urlTag, mobiletablet: mobilePhone.urlTag }

export const equivalentAspectRatio = {
  'smartphone-9-16': 'tablet-3-4',
  'tablet-3-4': 'smartphone-9-16',
  'tablet-10-16': 'smartphone-9-16'
}

export const groupDevice = {
  desktop: desktop.id,
  mobileOld: mobilePhone.id,
  tabletOld: mobileTablet.id,
  mobileWeb: mobilePhone.id,
  mobileApp: mobilePhone.id,
  tabletWeb: mobileTablet.id,
  tabletApp: mobileTablet.id,
  ctv: ctv.id,
  vidaa: u6Hero.id,
  tcl: tcl.id,
  samsungExtraWide: samsungExtraWide.id,
  samsungFirstScreen: samsungFirstScreen.id
}

export const groups = Object.keys(groupDevice)

export const easter = {
  name: 'Easter',
  id: 'easter',
  show: false,
  children: [
    {
      id: 'louvre',
      name: 'The Louvre',
      jwPlayerOptions: {
        aspectratio: '16:9'
      }
    }
  ]
}

export const siuBanerSizes = {
  sizeGroup1: [
    {
      id: '300x250',
      name: '300x250'
    },
    {
      id: '728x90',
      name: '728x90'
    },
    {
      id: '360x150',
      name: '360x150'
    },
    {
      id: '970x90',
      name: '970x90'
    },
    {
      id: '970x250',
      name: '970x250'
    }
  ],
  sizeGroup2: [
    {
      id: '300x250',
      name: '300x250'
    },
    {
      id: '360x232',
      name: '360x232'
    },
    {
      id: '412x212',
      name: '412x212'
    },
    {
      id: '500x160',
      name: '500x160'
    },
    {
      id: '728x90',
      name: '728x90'
    },
    {
      id: '860x90',
      name: '860x90'
    },
    {
      id: '1400x200',
      name: '1400x200'
    }
  ]
}
// this is a hack for having the empty buttons , this should be revised and refactored
export const emptyScreen = { id: '', clientLinkOrder: -1, children: [] }

export const deviceOptions = [
  desktop,
  mobilePhone,
  mobileTablet,
  ctv,
  // easter,
  inArticleDesktop,
  inArticleMobilePhone,
  inArticleMobileTablet,
  // emptyScreen,
  unMissableDesktop,
  unMissablePhone,
  unMissableMobileTablet,
  // emptyScreen,
  // emptyScreen,
  verticalMobilePhone,
  // emptyScreen,
  // emptyScreen
  u6Hero,
  tcl,
  samsungExtraWide,
  samsungFirstScreen
]

deviceOptions.forEach(device => {
  device.children.forEach(deviceChild => {
    deviceChild.jwPlayerOptions = merge(cloneDeep(defaultPlayerOptions), deviceChild.jwPlayerOptions)
  })
})

const getAllStreamsTypes = function () {
  const res = []
  for (const key in deviceOptions) {
    const item = deviceOptions[key]
    if (item.streamType && !res.includes(item.streamType)) {
      res.push(item.streamType)
    }
  }

  return res
}
const getAllDevicesByStreams = function () {
  const streamsTypes = getAllStreamsTypes()
  const res = {}

  for (const type in streamsTypes) {
    const currStream = streamsTypes[type]
    res[currStream] = []

    for (const item in deviceOptions) {
      const currItem = deviceOptions[item]
      if (currItem.streamType === currStream) {
        res[currStream].push(currItem)
      }
    }
  }

  return res
}

export const devicesByStreams = getAllDevicesByStreams()

export function filterScreenTypesByQuery (query) {
  let filterScreenTypes = []

  if (query && (query.ft || query.filterScreenTypes)) {
    filterScreenTypes = (query.ft || query.filterScreenTypes)
      .split(/\s*,\s*/g)
      .filter(type => validScreenTypeRegex.test(type))
  }

  return filterScreenTypes
}

export function getOptGroupByStreamType (filterScreenTypes = []) {
  const optionsHtml = []
  let currStreamDevices = []
  let availableDeviceOptions = []

  const devicesRequested = (item) => {
    if (filterScreenTypes.includes(item.clientId)) return true
    if (!isEmpty(item.group)) {
      for (let i = 0; i < item.group.length; i++) {
        if (filterScreenTypes.includes(item.group[i])) return true
      }
    }
  }

  for (const type in devicesByStreams) {
    currStreamDevices = devicesByStreams[type]
    if (!isEmpty(filterScreenTypes)) {
      currStreamDevices = currStreamDevices.filter(devicesRequested)
    }
    availableDeviceOptions = [...availableDeviceOptions, ...currStreamDevices]
    const children = currStreamDevices.map(child => <option key={child.id} value={child.id}>{child.dropDownDisplay}</option>)
    if (!isEmpty(children)) {
      optionsHtml.push(<optgroup key={type} label={type}>{children}</optgroup>)
    }
  }

  const flaggedDeviceOptions = flagArrayItemsByKey('id', 'isDisabled', availableDeviceOptions, deviceOptions)

  return { optionsHtml, flaggedDeviceOptions }
}
/**
 * Given the deviceId, return the related options.
 * @param  {string} deviceId   Could be 'desktop' or 'mobile'
 * @return {object}            Selected device's object
 */
export function findByDeviceId (deviceId) {
  const selectedDevices = deviceOptions
    .filter((device) => device.id === deviceId || device.clientId === deviceId || (device.group && device.group.length && device.group.includes(deviceId)))
  // By default, always return 'desktop' if anything is invalid
  const selectedDevice = selectedDevices[0] || desktop
  selectedDevice.getChildById = (childId) => {
    const selectedChildren = selectedDevice.children
      .filter((child) => child.id === childId)
    // By default, always return the first child if anything is invalid
    const selectedChild = selectedChildren[0] || selectedDevice.children[0]
    return selectedChild
  }
  return selectedDevice
}

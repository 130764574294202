import React, { Component } from 'react'

export default class GenericPlayer extends Component {
  constructor (props) {
    super(props)

    this.player = React.createRef()
  }

  componentDidMount () {
    const player = this.player.current
    if (this.props.video) {
      player.addEventListener('loadeddata', () => {
        if (this.props.callbacks.onVideoLoaded) this.props.callbacks.onVideoLoaded()
        player.play()
      })

      player.addEventListener('playing', () => {
        if (this.props.callbacks.onVideoPlaying) this.props.callbacks.onVideoPlaying()
      })

      player.addEventListener('ended', () => {
        if (this.props.callbacks.onVideoEnded) this.props.callbacks.onVideoEnded()
      })

      player.setAttribute('src', this.props.video)
    }
  }

  restart () {
    this.player.current.currentTime = 0
    this.player.current.play()
  }

  render () {
    return (
      <div className='player'>
        <div className='player-overlay' />
        <video onContextMenu={e => e.preventDefault()} ref={this.player} />
        <div className='player-under' />
      </div>
    )
  }
}

import { streamsTypes } from '../../DeviceOptions.js'
import { References } from '../../util/references'

export default class AniviewPlayer {
  constructor ({ mediaType, streamType }) {
    this.id = References.player.aniview.inStream.id
    this.name = References.player.aniview.inStream.name
    this.mediaType = mediaType || 'mp4'
    this.streamType = streamType || streamsTypes.InStream
  }
}

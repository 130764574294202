import React, { Component } from 'react'
import Collapse from 'react-bootstrap/Collapse'
import Dropdown from './Dropdown'
import { ReactComponent as CaretDownSVG } from '../images/caret-down-solid.svg'
import { ReactComponent as PlusSVG } from '../images/plus-solid.svg'
import { ReactComponent as MinusSVG } from '../images/minus-solid.svg'
import classnames from 'classnames'
import { removeReplacerWrapper, parseCustomizerQuery } from '../util/tag-util'
import { dcoOptions } from '../DcoOptions.js'

const headerClasses = 'col-10 col-xs-10 col-sm-10 col-md-9 col-lg-9 col-xl-9'
const spanClasses = 'col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2'
const inputClasses = 'col-10 col-sm-7 col-md-7 col-lg-7 col-xl-7'
const selectClasses = 'col-10 col-sm-7 col-md-7 col-lg-7 col-xl-7'
const buttonClasses = 'col-2 col-xs-2 col-sm-2 col-md-1 col-lg-1 col-xl-1'

export default class DCOPanel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      parameters: this.getParams(),
      show: false
    }
    this.handleHideShow = this.handleHideShow.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
  }

  componentDidMount () {
    dcoOptions
      .forEach((opt) => {
        if (!this.customizerParams[opt.dco]) return
        this.refs[`input-${opt.id}`].value = this.customizerParams[opt.dco] // eslint-disable-line react/no-string-refs
      })
  }

  shouldComponentUpdate (nextProps, nextState) {
    const { state } = this
    return nextState.show !== state.show || nextState.parameters.length !== state.parameters.length
  }

  getParams () {
    const fullTag = this.props.adTagUri
    const adTag = removeReplacerWrapper(fullTag)
    const customizerParams = this.customizerParams = parseCustomizerQuery(adTag)
    const paramKeys = dcoOptions
      .filter((opt) => !!customizerParams[opt.dco])
      .map((opt) => opt.id)
    return paramKeys
  }

  handleAdd (ev) {
    ev.preventDefault()
    const { refs } = this
    const addValue = refs.parameters.refs.dropdown.value
    if (!this.state.parameters.includes(addValue)) {
      this.setState((prevState) => {
        return { parameters: [...prevState.parameters, addValue] }
      })
    }
  }

  handleRemove (ev) {
    ev.preventDefault()
    /*
     * ev.target is browser dependent: it can either be the button or the svg feather icon,
     * so the attribute name has been added to both
     */
    const parameter = ev.target.getAttribute('name')
    parameter && this.setState((prevState) => {
      return { parameters: prevState.parameters.filter(p => p !== parameter) }
    })
  }

  handleHideShow () {
    this.setState((prevState) => {
      return { show: !prevState.show }
    })
  }

  render () {
    const { state } = this
    const icon = <CaretDownSVG className={classnames('svg-icon', { flip: state.show })} width='26px' height='26px' />
    return (
      /* eslint-disable react/no-string-refs */
      <div className='card JWAdvancePanel-card DCOPanel'>
        <div className='container-fluid card-body'>
          <form onSubmit={(ev) => ev.preventDefault()} action='#'>
            <div className='row DCOHeader'>
              <span className={'DCOTitle' + headerClasses}>
                Dynamic Creative Optimization Parameters
              </span>
              <div className={buttonClasses}>
                <button className='btn btn-info roundedBorder' ref='hideShow' onClick={this.handleHideShow}>{icon}</button>
              </div>
            </div>
            <Collapse
              timeout={200}
              in={state.show}
            >
              <div>
                {dcoOptions
                  .filter(({ id }) => state.parameters.includes(id))
                  .map(({ id, name }) => (
                    /* eslint-disable react/no-string-refs */
                    <div key={`input_${id}`} className='row parameter form-group'>
                      <span className={spanClasses}>{name}</span>
                      <div className={inputClasses}>
                        <input className='form-control roundedBorder default-input-styles' type='text' name={id} ref={`input-${id}`} />
                      </div>
                      <div className={buttonClasses}>
                        <button className='btn btn-info roundedBorder' type='button' name={id} onClick={this.handleRemove} ref='hideShow'>
                          <MinusSVG name={id} className='svg-icon' width='26px' height='26px' />
                        </button>
                      </div>
                    </div>
                    /* eslint-disable react/no-string-refs */
                  ))}
                {state.parameters.length < dcoOptions.length && (
                  /* eslint-disable react/no-string-refs */
                  <div className='row parameter'>
                    <span className={spanClasses}>Parameter</span>
                    <div className={selectClasses}>
                      <Dropdown ref='parameters' label='Parameters'>
                        {dcoOptions
                          .filter(({ id }) => !state.parameters.includes(id))
                          .map(({ id, select }) => <option key={id} value={id}>{select}</option>)}
                      </Dropdown>
                    </div>
                    <div className={buttonClasses}>
                      <button onClick={this.handleAdd} className='btn btn-info roundedBorder'>
                        <PlusSVG className='svg-icon' width='26px' height='26px' />
                      </button>
                    </div>
                  </div>
                  /* eslint-disable react/no-string-refs */
                )}
              </div>
            </Collapse>
          </form>
        </div>
      </div>
      /* eslint-disable react/no-string-refs */
    )
  }
}


export default Object.freeze({
  AD_LOADED: 'AdLoaded',
  AD_STARTED: 'AdStarted',
  AD_STOPPED: 'AdStopped',
  AD_SKIPPED: 'AdSkipped',
  AD_SKIPPABLE_STATE_CHANGE: 'AdSkippableStateChange', // VPAID 2.0 new event
  AD_SIZE_CHANGE: 'AdSizeChange', // VPAID 2.0 new event
  AD_LINEAR_CHANGE: 'AdLinearChange',
  AD_DURATION_CHANGE: 'AdDurationChange', // VPAID 2.0 new event
  AD_EXPANDED_CHANGE: 'AdExpandedChange',
  AD_REMAINING_TIME_CHANGE: 'AdRemainingTimeChange', // [Deprecated in 2.0] but will be still fired for backwards compatibility
  AD_VOLUME_CHANGE: 'AdVolumeChange',
  AD_IMPRESSION: 'AdImpression',
  AD_VIDEO_START: 'AdVideoStart',
  AD_VIDEO_FIRST_QUARTILE: 'AdVideoFirstQuartile',
  AD_VIDEO_MIDPOINT: 'AdVideoMidpoint',
  AD_VIDEO_THIRD_QUARTILE: 'AdVideoThirdQuartile',
  AD_VIDEO_COMPLETE: 'AdVideoComplete',
  AD_CLICK_THRU: 'AdClickThru',
  AD_INTERACTION: 'AdInteraction', // VPAID 2.0 new event
  AD_USER_ACCEPT_INVITATION: 'AdUserAcceptInvitation',
  AD_USER_MINIMIZE: 'AdUserMinimize',
  AD_USER_CLOSE: 'AdUserClose',
  AD_PAUSED: 'AdPaused',
  AD_PLAYING: 'AdPlaying',
  AD_LOG: 'AdLog',
  AD_ERROR: 'AdError'
})

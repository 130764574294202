import React, { Component } from 'react'
import { consoleLogInfo, checkUrlStatusResult, createImage } from '../util/utils'
import { References } from '../util/references'
import ErrorBg from '../images/empty-banner-1707x440.png'
import clickToBrowseImg from '../images/screen-deepLink.png'
import cliclToAppImg from '../images/screen-app.png'
import clickToWatchYoutubeImg from '../images/screen-youtube.png'
import clickToPlayStoreImg from '../images/screen-playStore.png'
import { Resources } from '../util/resources'

export default class TCLView extends Component {
  constructor (props) {
    super(props)
    this.setupEvents()
    this.state = {
      showError: false,
      errorMessage: '',
      bannerSrc: ErrorBg,
      clickScreen: null,
      isShowingPromptImg: false
    }
    this.immediateUse = { showError: false }
    this.bannerImg = React.createRef()
    this.loader = React.createRef()
    this.isBannersValid = false
    this.keyPressed = false
    this.remote = React.createRef()
    this.bannerData = null
    this.updateBanners = false
  }

  componentDidMount () {
    const waitForRender = callback => setTimeout(() => { callback() }, 0)
    waitForRender(() => { this.addBtnEvents(true) })
    this.setup()
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    const { props } = this
    const willUpdate = !(props.adTagUri === nextProps.adTagUri &&
      this.immediateUse.showError === this.state.showError &&
      this.state.bannerSrc === nextState.bannerSrc &&
      this.state.errorMessage === nextState.errorMessage &&
      this.state.clickScreen === nextState.clickScreen &&
      this.state.isShowingPromptImg === nextState.isShowingPromptImg)
    this.updateBanners = !this.bannerData || props.adTagUri !== nextProps.adTagUri || this.immediateUse.showError !== this.state.showError
    return willUpdate
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.addBtnEvents(true)
    this.setup()
    if (this.props.adTagUri !== prevProps.adTagUri) {
      this.setState(() => {
        return { clickScreen: null }
      })
    }
  }

  componentWillUnmount () {
    this.addBtnEvents(false)
  }

  setErrorState (isError, message) {
    this.immediateUse.showError = isError
    this.setState(() => {
      return {
        showError: isError,
        errorMessage: message,
        clickScreen: null
      }
    })
  }

  setupEvents () {
    this.elRef = {
      remoteOk: React.createRef(),
      remoteBack: React.createRef()
    }
    this.btnEvents = {
      remoteOk: { ref: this.elRef.remoteOk, event: 'click', action: this.onOkay.bind(this) },
      remoteOkHoverIn: { ref: this.elRef.remoteOk, event: 'mouseenter', action: this.handleMouseEnter.bind(this, 'remoteOkHover') },
      remoteOkHoverOut: { ref: this.elRef.remoteOk, event: 'mouseleave', action: this.handleMouseLeave.bind(this, 'remoteOkHover') },
      remoteOkPressed: { ref: this.elRef.remoteOk, event: 'mousedown', action: this.handleKeyPressed.bind(this, 'remoteOkPressed') },
      remoteBack: { ref: this.elRef.remoteBack, event: 'click', action: this.onBack.bind(this) },
      remoteBackHoverIn: { ref: this.elRef.remoteBack, event: 'mouseenter', action: this.handleMouseEnter.bind(this, 'remoteBackHover') },
      remoteBackHoverOut: { ref: this.elRef.remoteBack, event: 'mouseleave', action: this.handleMouseLeave.bind(this, 'remoteBackHover') },
      remoteBackPressed: { ref: this.elRef.remoteBack, event: 'mousedown', action: this.handleKeyPressed.bind(this, 'remoteBackPressed') }
    }
  }

  addBtnEvents (isAddEvent) {
    for (const prop in this.btnEvents) {
      const { ref, event, action } = this.btnEvents[prop]
      if (ref.current) {
        ref.current.removeEventListener(event, action)
        if (isAddEvent) ref.current.addEventListener(event, action)
      }
    }
  }

  handleMouseEnter (hoverClass) {
    this.remote.current.classList.add(hoverClass)
  }

  handleMouseLeave (hoverClass) {
    this.remote.current.classList.remove(hoverClass)
  }

  handleKeyPressed (clickClass) {
    if (this.keyPressed === false) {
      this.keyPressed = true
      this.remote.current.classList.add(clickClass)
      setTimeout(() => {
        this.remote.current.classList.remove(clickClass)
        this.keyPressed = false
      }, 200)
    }
  }

  onOkay () {
    if (!this.state.showError && this.props.adTagUri) {
      const data = this.bannerData
      if (data && data.banner && data.clickAction && !this.state.isShowingPromptImg) {
        const clickAction = data.clickAction
        const actions = References.paramName.tclAd.clickAction
        if (clickAction === actions.openApp) {
          this.setState(() => {
            return { clickScreen: cliclToAppImg, isShowingPromptImg: true }
          })
        } else if (clickAction === actions.deepLink) {
          this.setState(() => {
            return { clickScreen: clickToBrowseImg, isShowingPromptImg: true }
          })
        } else if (clickAction === actions.store) {
          this.setState(() => {
            return { clickScreen: clickToPlayStoreImg, isShowingPromptImg: true }
          })
        } else if (clickAction === actions.displayVideo) {
          this.setState(() => {
            return { clickScreen: clickToWatchYoutubeImg, isShowingPromptImg: true }
          })
        } else {
          consoleLogInfo(Resources.error.message.tclAd.invalidClickAction, 'error')
        }
      } else {
        this.setState(() => { return { isShowingPromptImg: false } })
      }
    }
  }

  onBack () {
    if (this.state.isShowingPromptImg) {
      this.setState(() => { return { isShowingPromptImg: false } })
    }
  }

  setup () {
    const { props } = this

    if (props.adTagUri) {
      if (!this.updateBanners && this.bannerData && this.isBannersValid) {
        this.getBannerImg()
        return
      }
      this.isBannersValid = false
      checkUrlStatusResult('TCL Ad Log URL Request', props.adTagUri, async (isError, json) => {
        let message = Resources.error.message.tclAd.genericErrorMessage
        if (!isError) {
          await this.validateBannerData(json)
            .then(() => {
              this.isBannersValid = true
              message = ''
              this.getBannerImg()
            })
            .catch((msg) => {
              message = msg
              isError = true
              consoleLogInfo(message, 'error')
            })
        }
        this.setErrorState(isError, message)
      })
    } else {
      this.setErrorState(true, '')
    }
  }

  validateBannerData (json) {
    let message = Resources.error.message.tclAd.genericErrorMessage
    return new Promise((resolve, reject) => {
      if (json && json.banners[0]) {
        this.bannerData = json.banners[0]
        if (this.bannerData.banners && this.bannerData.clickAction) {
          message = Resources.error.message.tclAd.incorrectBanner
        } else {
          resolve()
        }
      }
      reject(message)
    })
  }

  checkImgSize (img) {
    const expectedWidth = 1280
    const expectedHeight = 720
    const realWidth = img.naturalWidth
    const realHeight = img.naturalHeight
    if (realWidth !== expectedWidth || realHeight !== expectedHeight) {
      console.log(`Banner size: ${realWidth} x ${realHeight}`)
      consoleLogInfo(`Banner is not expected size of  ${expectedWidth} x ${expectedHeight}`, 'warning')
    }
  }

  getBannerImg () {
    if (this.bannerData) {
      if (this.bannerData.banner && this.bannerData.clickAction) {
        if (!(this.bannerImg.current.classList.contains('loaded'))) this.showLoading(true)

        let bannerSrc = ''
        let resultText = ''
        let messageType = 'error'
        const src = this.bannerData.banner
        const id = `banner-${this.bannerData.displayOrder}`

        createImage(src, id)
          .then((img) => {
            bannerSrc = img.src
            resultText = 'Successfully loaded image banner.'
            messageType = 'success'
            this.checkImgSize(img)
          }).catch(error => {
            bannerSrc = ErrorBg
            resultText = `Error loading image banner. ${error}`
          }).finally(() => {
            consoleLogInfo(resultText, messageType)
            this.showLoading(false)
            this.setState(() => {
              return { bannerSrc: bannerSrc }
            })
          })
      }
    }
  }

  showLoading (isShow) {
    if (this.loader.current && this.bannerImg.current) {
      if (isShow) {
        this.bannerImg.current.classList.remove('loaded', 'tcl')
        this.bannerImg.current.classList.add('removing')
        this.loader.current.classList.add('active', 'adjust')
      } else {
        this.bannerImg.current.classList.remove('removing')
        this.bannerImg.current.classList.add('loaded', 'tcl')
        this.loader.current.classList.remove('active')
      }
    }
  }

  render () {
    const htmlErrorMessage = (
      <div className='vidaaAdError tcl-error'>
        <span className='vidaaAdErrorBg'>
          <img className='vidaaAdErrorImg' src={ErrorBg} />
        </span>
        <span className='vidaaAdErrorTxt'>
          {this.state.errorMessage}
        </span>
      </div>
    )

    const tclAdContainerClasses = this.immediateUse.showError ? 'vidaaAdContainer' : 'vidaaAdContainer tcl-banner'

    return (
      <>
        <div className='vidaaRemote show' ref={this.remote}>
          <div className='remoteRow tcl-ok'>
            <div className='remoteBtnOkay' ref={this.elRef.remoteOk} />
            <div className='remoteBtnBack' ref={this.elRef.remoteBack} />
          </div>
        </div>

        {
          this.state.isShowingPromptImg && this.state.clickScreen &&
            <div className='screenPopup'>
              <img src={this.state.clickScreen} />
            </div>
        }
        <div className='vidaaAdMain'>
          <div className={tclAdContainerClasses}>
            {this.immediateUse.showError
              ? htmlErrorMessage
              : (
                <div className='vidaaAd'>
                  <img className='vidaaAdBannerImg removing' ref={this.bannerImg} src={this.state.bannerSrc} />
                  <div className='loader large' ref={this.loader} />
                </div>
                )}
          </div>
        </div>
        <div className='remote-text'>
          <span>Use the remote to navigate and interact with the native CTV ad above.</span>
        </div>
      </>
    )
  }
}

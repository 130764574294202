import qs from 'qs'
import { References } from './references'

const rmlReplacerPattern = /^https?:\/\/(raptor|pterodactylus)(-stage|-test)?\.(tremorvideodsp|unruly)\.(com|co)\/ws\/rml\/replacer/
const customizerPattern = /^https:\/\/(raptor|pterodactylus)(-stage|-test)?\.(tremorvideodsp|unruly)\.(com|co)\/ws\/customizer\//
const adserverPattern = /adserver.videohub.tv\/preview/
const tremorTagPattern = /^https?:\/\/s\.tremorvideodsp\.com\/m\//

const vastMediaTypes = {
  mp4: { name: 'mp4', ext: '.mp4' }// ,
  // mp3: { name: 'mp3', ext: '.mp3' } // example
}

function isValidHttpUrl (stringUrl) {
  let url
  try {
    url = new URL(stringUrl)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}
/**
 * Extract the wrapped vast tag if the RML replacer is used
 * @param tag
 * @returns {*}
 */
export function removeReplacerWrapper (tag) {
  if (!rmlReplacerPattern.test(tag)) return tag
  const vastUri = tag.replace(/^.*(\?|&)vastUri=([^&]*).*$/, '$2')
  return tag === vastUri ? tag : decodeURIComponent(vastUri)
}

export function addReplacerWrapper (uri, config) {
  const brand = config.branding.get.call(config)
  const rmlReplacerUrl = References.endpoint.branding[brand].rmlReplacer
  const encodedTag = encodeURIComponent(uri)
  return `${rmlReplacerUrl}?addTVEvents=true&vastUri=${encodedTag}`
}

/**
 * Extract the wrapped vast tag if the customizer is used
 * @param tag
 * @returns {*}
 */
export function removeCustomizerWrapper (tag) {
  if (!customizerPattern.test(tag)) return tag
  const vastUri = tag.replace(/^.*\/customizer\/([^?]*).*$/, '$1')
  return tag === vastUri ? tag : decodeURIComponent(vastUri)
}

export function parseCustomizerQuery (tag) {
  if (!customizerPattern.test(tag)) return {}
  const queryStr = tag.replace(/^[^?]*(.*)$/, '$1')
  return qs.parse(queryStr, {
    ignoreQueryPrefix: true,
    allowDots: false
  })
}

export function isRmlWrappable (tag) {
  return !!tag && !adserverPattern.test(tag)
}

export function isRmlWrapped (tag) {
  return !!tag && rmlReplacerPattern.test(tag)
}

export function mediaFileExt (url) {
  if (url) {
    for (const mediaType in vastMediaTypes) {
      if (url.includes(vastMediaTypes[mediaType].ext)) return vastMediaTypes[mediaType].name
    }
  }
  return false
}
export function searchClickThroughUrl (props) {
  if (props && props.query && props.query.url && isValidHttpUrl(props.query.url)) {
    return props.query.url
  }
  return null
}

export function saveAdTagUri (uri) {
  sessionStorage.setItem('adTagUri', uri)
}

export function getAdTagUri (props) {
  if (props && props.location && props.location.query && props.location.query.adTagUri) {
    sessionStorage.setItem('adTagUri', props.location.query.adTagUri)
  }
  return sessionStorage.getItem('adTagUri') || ''
}

export function isCustomizerWrappable (tag) {
  return !!tag && tremorTagPattern.test(tag)
}

export function isBaseCustomizerWrappable (tag) {
  if (!tag) return false
  tag = removeReplacerWrapper(tag)
  tag = removeCustomizerWrapper(tag)
  return isCustomizerWrappable(tag)
}

function mediaFilesToVast (mediaType, mediaUrl) {
  let mediaTag = ''
  switch (mediaType) {
    // TODO - check which width="640" height="360" and if needed
    case 'mp4':
      mediaTag = `<MediaFile delivery="progressive" type="video/mp4" width="640" height="360">${mediaUrl}</MediaFile>`
      break
    default:
      mediaTag = ''
  }
  return { mediaTag }
}

export function encodeUrL (url) {
  url = decodeURIComponent(url)
  return encodeURIComponent(url)
}

export function urlIncludesTremorVideoDsp (urlParam) {
  return urlParam.includes(References.url.tremorvideodsp)
}

export function isTremorVideoFullUrl (uri) {
  return uri.startsWith(References.url.tremorvideodspFull)
}

export function isAdServerPreviewUrl (uri) {
  return uri.includes(References.url.adserverPreview)
}

export function addVastAdParameters (uri, param, value, config) {
  return config.api.addVastAdParameters.call(config, uri, param, value)
}

function addParamToRmlNestedUri (uri, param, value, config) {
  let uriRmlRemoved = removeReplacerWrapper(uri)
  if (urlIncludesTremorVideoDsp(uriRmlRemoved)) {
    uriRmlRemoved = addVastAdParameters(uriRmlRemoved, param, value, config)
  }
  return addReplacerWrapper(uriRmlRemoved, config)
}

export function updateParamByUrlType (uri, param, value, config) {
  let updatedUri = uri

  if (isAdServerPreviewUrl(uri)) {
    if (param === 'videoPlacementType') param = 'dspVideoPlacementType'
    updatedUri = updateAddParam(uri, param, value)
  } else if (isRmlWrapped(uri)) {
    updatedUri = addParamToRmlNestedUri(uri, param, value, config)
  } else if (isTremorVideoFullUrl(uri)) {
    updatedUri = addVastAdParameters(uri, param, value, config)
  }

  console.log('Info Log - updateParamByUrlType new value - ' + updatedUri)
  return updatedUri
}

function addParam (urlString, param, value) {
  const uriSplitParams = urlString.split('?')
  return uriSplitParams[0] + `?${param}=${value}` + (uriSplitParams[1] ? '&' + uriSplitParams[1] : '')
}

function removeParam (urlString, param) {
  const uriSplitParams = urlString.split(`${param}=`)
  let removedVal = ''
  if (uriSplitParams[1].indexOf('&') !== -1) {
    removedVal = uriSplitParams[1].slice(uriSplitParams[1].indexOf('&') + 1, uriSplitParams[1].length)
  }
  removedVal = removedVal.slice(-1) === '&' ? removedVal.slice(0, removedVal.length - 1) : removedVal
  const connect = uriSplitParams[0] + removedVal

  urlString = (connect.slice(-1) === '&' || connect.slice(-1) === '?') ? connect.slice(0, connect.length - 1) : connect
  return urlString
}

export function updateAddParam (uri, param, value) {
  if (uri.includes(param)) {
    uri = removeParam(uri, param)
  }
  uri = addParam(uri, param, value)

  return uri
}

export function vastTemplate (mediaType, mediaUrl, clickThroughUrl) {
  const clickUrlXml = clickThroughUrl !== null
    ? `<VideoClicks>
                    <ClickThrough>${clickThroughUrl}</ClickThrough>
                  </VideoClicks>`
    : ''

  const mediaItems = mediaFilesToVast(mediaType, mediaUrl)
  return `<?xml version="1.0" encoding="UTF-8"?>
      <VAST version="3.0">
        <Ad id="linear ad">
          <InLine>
            <AdSystem version="1.0">UnrulyX</AdSystem>
            <AdTitle>Media to Vast Previewer</AdTitle>
            <Creatives>
              <Creative>
                <Linear>
                  <AdParameters xmlEncoded="true">
                    <TrackingEvents>
                      <Tracking event="start"><![CDATA[https://preview.tremorvideodsp.com?event=pp_play]]></Tracking>
                      <Tracking event="firstQuartile"><![CDATA[https://preview.tremorvideodsp.com?event=firstQuartile]]></Tracking>
                      <Tracking event="midpoint"><![CDATA[https://preview.tremorvideodsp.com?event=midpoint]]></Tracking>
                      <Tracking event="thirdQuartile"><![CDATA[https://preview.tremorvideodsp.com?event=thirdQuartile]]></Tracking>
                      <Tracking event="complete"><![CDATA[https://preview.tremorvideodsp.com?event=complete]]></Tracking>
                      <Tracking event="progress" offset="00:00:10"><![CDATA[https://preview.tremorvideodsp.com?event=progress]]></Tracking>
                      <Tracking event="pause"><![CDATA[https://preview.tremorvideodsp.com?event=pause]]></Tracking>
                    </TrackingEvents>
                  </AdParameters>
                  ${clickUrlXml}
                  <MediaFiles>
                    ${mediaItems.mediaTag}
                  </MediaFiles>
                </Linear>
              </Creative>
            </Creatives>
          </InLine>
        </Ad>
      </VAST>`
}

/**
 * Deconstruct and rebuild tag to include DCO parameters
 * @param originalTag
 * @param dcoParamStr
 * @returns {*}
 */
export function rebuildTagWithDCO (originalTag, dcoParamStr) {
  const adTag = removeReplacerWrapper(originalTag)
  const wasRmlWrapped = originalTag !== adTag
  const baseTag = removeCustomizerWrapper(adTag)
  const wasCustomizerWrapped = adTag !== baseTag
  const canBeCustomizerWrapped = isCustomizerWrappable(baseTag)
  let newTag = baseTag
  if (wasCustomizerWrapped && canBeCustomizerWrapped) {
    const adTagWithoutDCO = adTag.replace(/&geoInfo\..*?=[^&]*/g, '').replace(/\?geoInfo\..*?=[^&]*&?/, '?')
    const hasNonDcoParams = /\?.+/.test(adTagWithoutDCO)
    if (hasNonDcoParams && dcoParamStr) {
      newTag = `${adTagWithoutDCO}&${dcoParamStr}`
    } else if (hasNonDcoParams) {
      newTag = adTagWithoutDCO
    } else if (dcoParamStr) {
      newTag = adTagWithoutDCO.replace(/^([^?]*)\??/, `$1?${dcoParamStr}`)
    } else {
      newTag = baseTag
    }
  } else if (dcoParamStr && canBeCustomizerWrapped) {
    const customizerBaseUrl = 'https://raptor.tremorvideodsp.com/ws/customizer/'
    newTag = `${customizerBaseUrl}${encodeURIComponent(baseTag)}?${dcoParamStr}`
  }
  // If was RML wrapped, add RML replacer wrapper with previous params
  const canBeRmlWrapped = isRmlWrappable(newTag)
  if (wasRmlWrapped && canBeRmlWrapped) {
    newTag = originalTag.replace(/(\?|&)vastUri=[^&]*/, `$1vastUri=${encodeURIComponent(newTag)}`)
  }
  return newTag
}

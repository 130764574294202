import React, { Component } from 'react'
import classnames from 'classnames'
import { isRmlWrappable, isRmlWrapped, removeReplacerWrapper, mediaFileExt, saveAdTagUri } from '../util/tag-util'
import { References } from '../util/references'

export default class AdTagUri extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rmlWrapped: false,
      showRmlButton: false
    }
    this.rmlReplacerUrl = References.endpoint.branding[props.branding].rmlReplacer
    /* Commenting below event binding for AX-319 */
    // this.handleClick = this.handleClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    /* AX-319- Commenting to Prevent RML button state update on mounting */
    // this.setState({
    // rmlWrapped: this.isRmlWrapped(),
    // showRmlButton: this.props.match.params.workspace !== 'client' && this.isRmlWrappable() && !this.mediaFileExt()
    // })
  }

  shouldComponentUpdate (nextProps, nextState) {
    const { state } = this
    if (state.rmlWrapped !== nextState.rmlWrapped) return true
    if (state.showRmlButton !== nextState.showRmlButton) return true
    /* AX-319- Preventing state update check for Hidden RML Feature */
    // if (/^client$/.test(props.match.params.workspace) !== /^client$/.test(nextProps.match.params.workspace)) {
    // this.setState({
    // showRmlButton: nextProps.match.params.workspace !== 'client' && this.isRmlWrappable() && !this.mediaFileExt()
    // })
    // }
    return false
  }

  isRmlWrappable () {
    return isRmlWrappable(this.refs.inputUrl.value) // eslint-disable-line react/no-string-refs
  }

  isRmlWrapped () {
    return isRmlWrapped(this.refs.inputUrl.value) // eslint-disable-line react/no-string-refs
  }

  mediaFileExt () {
    return mediaFileExt(this.refs.inputUrl.value) // eslint-disable-line react/no-string-refs
  }

  handleClick (ev) {
    ev.preventDefault()
    if (this.isRmlWrapped()) {
      this.refs.inputUrl.value = removeReplacerWrapper(this.refs.inputUrl.value) // eslint-disable-line react/no-string-refs
    } else if (!this.mediaFileExt()) {
      const encodedTag = encodeURIComponent(this.refs.inputUrl.value) // eslint-disable-line react/no-string-refs
      this.refs.inputUrl.value = `${this.rmlReplacerUrl}?addTVEvents=true&vastUri=${encodedTag}` // eslint-disable-line react/no-string-refs
    }
    this.setState({
      rmlWrapped: this.isRmlWrapped()
    })
    saveAdTagUri(this.refs.inputUrl.value) // eslint-disable-line react/no-string-refs
  }

  handleChange () {
    /* AX-319 Preventing RML button state update on Uri input change */
    // const rmlWrapped = this.isRmlWrapped()
    // const showRmlButton = this.props.match.params.workspace !== 'client' && !this.mediaFileExt() && this.isRmlWrappable()
    // if (rmlWrapped !== this.state.rmlWrapped || showRmlButton !== this.state.showRmlButton) {
    // this.setState({ rmlWrapped, showRmlButton })
    // }
    this.props.onHandleDcoPanelShow(this.refs.inputUrl.value) // eslint-disable-line react/no-string-refs
    saveAdTagUri(this.refs.inputUrl.value) // eslint-disable-line react/no-string-refs
  }

  render () {
    const { props, state } = this
    let inputFieldClassNames
    if (state.showRmlButton) {
      inputFieldClassNames = 'col-xs-12 col-sm-10 col-md-10 col-lg-10 col-xl-10'
    } else {
      inputFieldClassNames = 'col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'
    }

    return (
      <div className='row'>
        <div className={inputFieldClassNames}>
          <div className='input-group'>
            <input
              className='form-control roundedBorder default-input-styles'
              id='ad-tag-uri'
              type='url'
              aria-label='URI'
              placeholder='VAST ad tag URI'
              defaultValue={props.value}
              ref='inputUrl' // eslint-disable-line react/no-string-refs
              onChange={this.handleChange}
            />
          </div>
        </div>
        {state.showRmlButton &&
          <div className='col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center'>
            <button
              className={classnames('btn btn-info btn-rml roundedBorder', { selected: state.rmlWrapped })}
              onClick={this.handleClick}
            >
              RML
            </button>
          </div>}
      </div>
    )
  }
}

import React from 'react'

export default function CompanionPanel () {
  return (
    <div className='companion-panel'>
      <div id='companion-300-250' style={{ width: 300, height: 250 }} />
      <div
        className='wireframe fake-companion-content'
        style={{ height: 2, width: 300, margin: '0 auto', marginTop: 35, marginBottom: 35 }}
      />
      {new Array(3).fill('0').map((el, index) =>
        <div key={`${Date.now()}_${index}`}>
          <div className='wireframe fake-companion-content' style={{ height: 194, width: '100%', marginTop: 35 }} />
          <div className='wireframe fake-companion-content' style={{ height: 20, width: 200, marginTop: 12 }} />
        </div>
      )}
    </div>
  )
}

import React, { Component } from 'react'
import merge from 'lodash/merge'
import classnames from 'classnames'
import { compilePath } from '../util/pathmaker'
import { ReactComponent as ListSVG } from '../images/list-ul-solid.svg'
export default class DebugButton extends Component {
  /**
   * Detects whether in debug mode, or not.
   * @return {Boolean} [description]
   */
  isDebug () {
    return this.props.match.params.workspace === 'debug'
  }

  handleClick () {
    const { history, location } = this.props
    const match = merge({}, this.props.match)
    const isDebug = this.isDebug()
    console.log('## DEBUG BUTTON - isDebug', isDebug)
    match.params.workspace = isDebug ? 'basic' : 'debug'
    history.push(compilePath(match.path, match.params) + location.search)
  }

  render () {
    return (
      <button
        type='button'
        ref='btn' // eslint-disable-line react/no-string-refs
        className={classnames('btn btn-info tmr-nav-btn roundedBorder', { selected: this.isDebug(), disabled: this.props.disabled })}
        onClick={this.handleClick.bind(this)}
        disabled={this.props.disabled}
      >
        <ListSVG className='svg-icon' width='25px' height='25px' />
        <div className='tmr-nav-btn-text'>
          Show Events Panel
        </div>
      </button>
    )
  }
}

import React, { Component } from 'react'
import { ReactComponent as CaretDownSVG } from '../images/caret-down-solid.svg'

export default class Dropdown extends Component {
  render () {
    const { props } = this
    const height = props.height || '56px'
    const selectClasses = `form-control default-dropdown-styles default-dropdown-styles--${height}`
    const devicesByStreams = props.devicesByStreams
    const dropdownOptions = props.optGroupByStreamType?.filter(obj => obj.key === 'In-Stream' || obj.key === 'Out-Stream')
    return (
      /* eslint-disable react/no-string-refs */
      <div className='input-group dropdown-wrapper roundedBorder'>
        <select className={selectClasses} ref='dropdown' onChange={props.onChange} value={props.value} defaultValue={props.defaultVal} disabled={props.disabled}>
          {devicesByStreams && dropdownOptions}
          {!devicesByStreams &&
            <optgroup label={props.label}>
              {props.children}
            </optgroup>}
        </select>
        <div className='dropdown-icon-container'>
          <CaretDownSVG className='svg-icon' width='23px' height='23px' />
        </div>
      </div>
      /* eslint-disable react/no-string-refs */
    )
  }
}

import React, { PureComponent } from 'react'
import qr from 'qr-image'
import { isRmlWrapped } from '../util/tag-util'
const adServerRegex = /(&|\?)screenType=/

export default class QRCode extends PureComponent {
  render () {
    let adTagUri = this.props.adTagUri
    if (isRmlWrapped(adTagUri)) {
      adTagUri = adTagUri
        .replace(/&app=[^&]*/g, '')
        .replace(/\?app=[^&]*&/g, '?')
      adTagUri += '&app=true'
    } else if (adServerRegex.test(adTagUri)) {
      adTagUri = adTagUri
        .replace(/&(ssRmlApp|isApp)=[^&]*/g, '')
        .replace(/\?(ssRmlApp|isApp)=[^&]*&/g, '?')
      adTagUri += '&ssRmlApp=1&isApp=y'
    }
    console.log('QR Code:', adTagUri)
    const buffer = qr.imageSync(adTagUri)
    const imgSrc = 'data:image/png;base64,' + buffer.toString('base64')
    return <img className='qrcode' alt='qr-code' src={imgSrc} />
  }
}

/*
 * Parameters are displayed in the same order as in the dcoOptions array
 * (Postal Code is used most often)
 *
 * id = key in state.parameters, id of input elements, and button.name
 * name = displayed name
 * select = option in select box
 * dco = key in DCO query
 */
export const dcoOptions = [
  {
    id: 'postal-code',
    name: 'Zip Code',
    select: 'Zip Code',
    dco: 'geoInfo.postalCode'
  },
  {
    id: 'city',
    name: 'City',
    select: 'City',
    dco: 'geoInfo.cty'
  },
  {
    id: 'state',
    name: 'State',
    select: 'State',
    dco: 'geoInfo.st'
  },
  {
    id: 'country',
    name: 'Country',
    select: 'Country',
    dco: 'geoInfo.ctry'
  },
  {
    id: 'dma',
    name: 'DMA',
    select: 'Designated Market Areas',
    dco: 'geoInfo.dma'
  },
  {
    id: 'long',
    name: 'Longitude',
    select: 'Longitude',
    dco: 'geoInfo.longitude'
  },
  {
    id: 'lat',
    name: 'Latitude',
    select: 'Latitude',
    dco: 'geoInfo.latitude'
  },
  {
    id: 'continent',
    name: 'Continent',
    select: 'Continent',
    dco: 'geoInfo.ctnt'
  },
  {
    id: 'acd',
    name: 'Area Code',
    select: 'Area Code',
    dco: 'geoInfo.acd'
  }
]

export const getDcoQueryKeys = () => {
  return dcoOptions.map(option => option.dco)
}

import React, { Component } from 'react'

export default class InArticleDesktop extends Component {
  // constructor (props) { super(props) }
  componentDidMount () {
    // testing
  }

  render () {
    return (
      <div>
        <div className='wireframe fake-companion-content' key={Math.random()} style={{ height: 20, width: '20%', marginTop: 20 }} />
        <div
          className='wireframe fake-companion-content' key={Math.random()}
          style={{ height: 300, width: '100%', marginTop: 10, marginBottom: 10 }}
        >
          <h1 className='text-center text-format inarticle-title'>SCROLL DOWN TO VIEW UNIT</h1>
        </div>
        <div className='row'>
          <div className='col-md-9'>
            <div>
              <div
                className='wireframe fake-companion-content' key={2}
                style={{ height: 20, width: '100%', marginBottom: 10 }}
              />
              {new Array(25).fill('0').map((el, index) => {
                return (
                  <div key={`${Date.now()}__${index}`} className={`native-ad${index}`}>
                    <div key={`${Date.now()}_${index}`} className='wireframe' style={{ minHeight: '20px', width: '100%', marginTop: 9 }} />
                  </div>
                )
              }
              )}
              <div className='native-ad25 outstream-insert-position' id={this.props.id} ref={this.props.container} key={`${Date.now()}__25`} />
              {new Array(25).fill('0').map((el, index) => {
                const adjustedIndex = index + 26
                return (
                  <div key={`${Date.now()}__${adjustedIndex}`} className={`native-ad${adjustedIndex}`}>
                    <div key={`${Date.now()}_${adjustedIndex}`} className='wireframe' style={{ minHeight: '20px', width: '100%', marginTop: 9 }} />
                  </div>
                )
              }
              )}
            </div>
          </div>
          <div className='col-md-3'>
            {new Array(4).fill('0').map((el, index) => {
              return (
                <div key={`${Date.now()}+${index}`}><div className='wireframe fake-companion-content' key={`${Date.now()}_${index + 1}`} style={{ height: 250, width: '100%' }} />
                  <div className='wireframe fake-companion-content' key={`${Date.now()}__${index + 2}`} style={{ height: 20, width: '60%', marginTop: 10 }} />
                  <div className='wireframe fake-companion-content' key={`${Date.now()}___${index + 3}`} style={{ height: 20, width: '100%', marginTop: 10, marginBottom: 20 }} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'

export default class SiuAdvancedPanel extends Component {
  handleSetVideoUrl (ev) {
    ev.preventDefault()
    this.props.handleSetVideoUrl(this.refs.inputUrl.value) // eslint-disable-line react/no-string-refs
  }

  render () {
    const { props } = this
    return (
      <div className='JWAdvancePanel-collapse' id='collapseExample'>
        <div className=''>
          <div className='card JWAdvancePanel-card AdvancePanel'>
            <div className='container-fluid card-body'>
              <form onSubmit={this.handleSetVideoUrl.bind(this)}>
                <div className='row AdvancePanel-label'>
                  <div className='col-12'>Target Video</div>
                </div>
                <div className='row'>
                  <div className='col-xs-12 col-sm-10 col-md-10 col-lg-10 col-xl-10'>
                    <div className='input-group'>
                      <input
                        className='form-control roundedBorder default-input-styles'
                        id='siu-video-url-input'
                        type='url'
                        aria-label='URI'
                        placeholder='Video URL'
                        defaultValue={props.value}
                        ref='inputUrl' // eslint-disable-line react/no-string-refs
                      />
                    </div>
                  </div>
                  <div className='col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-center'>
                    <button
                      className='btn btn-info btn-siu-video-apply roundedBorder' type='submit'
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

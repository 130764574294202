import React, { Component } from 'react'

export default class UnMissableMobileTablet extends Component {
  componentDidMount () {
    // testing
  }

  render () {
    return (
      <div className='inarticle-unmissable-container'>
        <div className='inarticle-tablet-container'>
          <div className='inarticle-tablet-inner inarticle-unmissable-inner'>
            <div className='wireframe fake-companion-content' key={Math.random()} style={{ height: '20px', width: '40%', margin: '20px auto' }} />
            <div
              className='wireframe fake-companion-content' key={Math.random()}
              style={{ height: 85, width: '100%', marginTop: 10, marginBottom: 10 }}
            />
            <div className='row'>
              <div className='col-md-12'>
                {new Array(30).fill('0').map((el, index) => {
                  return (
                    <div key={`${Date.now()}__${index}`} className={`native-ad${index}`}>
                      <div key={`${Date.now()}_${index}`} className='wireframe' style={{ minHeight: '20px', width: '100%', marginTop: 9 }} />
                    </div>
                  )
                }
                )}
                <div className='native-ad30' id={this.props.id} ref={this.props.container} key={`${Date.now()}__30`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'
import classnames from 'classnames'
import { Resources } from '../util/resources'
import debounce from 'lodash/debounce'

export default class ClientLinkGenerator extends Component {
  constructor (props) {
    super(props)
    this.selectScreenType = this.selectScreenType.bind(this)
    this.copyToClipboard = this.copyToClipboard.bind(this)
    this.handleUrlChange = debounce(this.handleUrlChange.bind(this), 500)
    this.updateClickThroughUrl = this.updateClickThroughUrl.bind(this)
    const filteredParam = window.location.href.split('?').filter(param => param.includes('adTagUri'))
    const urlParams = filteredParam && new URLSearchParams(filteredParam[0])
    const adTagUri = urlParams?.get('adTagUri')
    const showCustomUrlInput = (adTagUri && adTagUri.endsWith('.mp4'))
    this.MAX_BUTTONS_IN_COLUMNS = 4
    this.counter = 0
    this.state = {
      deviceOptions: props.deviceOptions,
      clientLinkFilterArray: [],
      link: '',
      clickThroughUrl: '',
      showCustomUrlInput: showCustomUrlInput || false
    }
    this.isScreenTypeSelected = this.isScreenTypeSelected.bind(this)
  }

  isScreenTypeSelected (screenTypeID) {
    return this.state.clientLinkFilterArray.indexOf(screenTypeID) > -1
  }

  updateClickThroughUrl (url) {
    const sign = window.location.href.indexOf('?') > -1 ? '&' : '?'
    this.setState({
      clickThroughUrl: url,
      link: this.state.clientLinkFilterArray.length > 0 ? `${window.location.href}${sign}ft=${this.state.clientLinkFilterArray.join(',')}${url ? `&url=${encodeURIComponent(url)}` : ''}` : ''
    }, () => {
      this.refs.clickThroughUrl.value = this.state.clickThroughUrl // eslint-disable-line react/no-string-refs
    })
  }

  copyToClipboard () {
    const copyUrl = this.refs.clientUrl // eslint-disable-line react/no-string-refs
    copyUrl.select()
    copyUrl.setSelectionRange(0, this.state.link.length) /* For mobile devices */
    document.execCommand('copy')
  }

  selectScreenType (id) {
    const tempArr = [...this.state.clientLinkFilterArray]
    if (this.state.clientLinkFilterArray.indexOf(id) > -1) {
      tempArr.splice(tempArr.indexOf(id), 1)
    } else {
      tempArr.push(id)
    }

    const sign = window.location.href.indexOf('?') > -1 ? '&' : '?'
    this.setState({
      clientLinkFilterArray: tempArr,
      link: tempArr.length > 0 ? `${window.location.href}${sign}ft=${tempArr.join(',')}${this.state.clickThroughUrl ? `&url=${encodeURIComponent(this.state.clickThroughUrl)}` : ''}` : ''
    }, () => {

    })
  }

  handleUrlChange (event) {
    this.updateClickThroughUrl(event.target.value)
  }

  render () {
    const deviceOptions = this.props.filteredOptions.filter((device) => device.clientLinkOrder !== -1)
    this.counter = 0
    return (
      /* eslint-disable react/jsx-handler-names */
      <div className='client-link-container'>
        <h2 className='title'>{Resources.components.clientLink.header}</h2>
        <label>{Resources.components.clientLink.headerInfo}</label>
        <ul className='grids'>
          {deviceOptions.map((option, index) => {
            if (this.counter >= this.MAX_BUTTONS_IN_COLUMNS) {
              this.counter = 1
            } else {
              this.counter++
            }
            return (option.clientLinkOrder === this.counter
              ? (
                <li key={option.id} className='client-option-screen'>
                  <button
                    onClick={() => option.isDisabled ? '' : this.selectScreenType(option.clientId)}
                    className={classnames('btn btn-info tmr-client-link-btn', { selected: this.isScreenTypeSelected(option.clientId) })}
                    type='button'
                    disabled={option.isDisabled}
                  >
                    {option.name}
                  </button>
                </li>)
              : <li key={index} className='client-option-screen'>&nbsp;</li>)
          })}
        </ul>
        {this.state.showCustomUrlInput &&
          <div>
            <label htmlFor='customUrlInput'>Enter a URL(optional):</label>
            <input
              type='url'
              id='customUrlInput'
              onChange={this.handleUrlChange}
              ref='clickThroughUrl' // eslint-disable-line react/no-string-refs
              className='form-control col-sm-10 col-md-10 col-lg-10 col-xl-10'
              placeholder='https://www.example.com'
            />
          </div>}
        <div className='client-link-url-container'>
          <div className='input-group'>
            <input
              className='form-control'
              id='siu-video-url-input'
              type='url'
              aria-label='URI'
              ref='clientUrl' // eslint-disable-line react/no-string-refs
              readOnly
              value={`${this.state.link}`}
            />
          </div>
          <div className='input-group'>
            <button onClick={this.copyToClipboard} className='btn btn-info btn-siu-video-apply' disabled={this.state.link.length === 0}>
              Copy Link
            </button>
          </div>
        </div>
      </div>
      /* eslint-disable react/jsx-handler-names */
    )
  }
}

const cloneDeep = require('lodash/cloneDeep')
const isPlainObject = require('lodash/isPlainObject')
const merge = require('lodash/merge')

/**
 * Removes all properties from object with matching default values
 * @param  {Object} obj      [Original Object]
 * @param  {Object} defaults [Object containing default key/value pairs.  Multiple allowed, increasing priority left to right]
 * @return {Object}          [New Object (Original not modified in place)]
 */
export default function removeDefaultsDeep (obj = {}, ...defaults) {
  if (defaults.concat(obj).some((arg) => !isPlainObject(arg))) {
    throw new Error('All arguments to removeDefaultsDeep must be plain objects')
  }
  defaults = merge({}, ...defaults)
  return Object.keys(obj).reduce((res, key) => {
    if (isPlainObject(obj[key]) && isPlainObject(defaults[key])) {
      res[key] = removeDefaultsDeep(obj[key], defaults[key])
    } else if (obj[key] !== defaults[key]) {
      res[key] = cloneDeep(obj[key])
    }
    return res
  }, {})
}
